import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionsComponent } from './subscriptions.component';
import {SharedModule} from '../../modules/shared.module';
import { SubscriptionsListComponent } from './components/subscriptions-list/subscriptions-list.component';

@NgModule({
  declarations: [SubscriptionsComponent, SubscriptionsListComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class SubscriptionsModule { }
