import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {BILL_TYPE, IBill, IBillsTableConfig, IListFilterItem, IMediaTimerange, ITableColumn, ITimerange, TEMPLATE_ID} from 'ui-elements';
import {BillsService} from '../../../../services/bills/bills.service';
import {ACCOUNT_PAGE_TYPE} from '../../../../services/user/user.service';
import {IBillConfig} from '../../../../models/types';
import moment from 'moment';
import {tap} from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable()
export class BillsConfig implements IBillConfig{
  private publisherId: string;
  private baseColumns: ITableColumn[] = [
    {
      name: 'bills.field.number',
      dataField: 'billNumber',
      class: 'left'
    },
    {
      name: 'bills.field.date',
      sortField: 'DATE',
      tmplId: TEMPLATE_ID.DATE,
      tmpl: null,
      class: 'left'
    },
    {
      name: 'bills.field.amount',
      tmplId: TEMPLATE_ID.PRICE,
      tmpl: null,
      class: 'left'
    },
    {
      name: 'bills.field.status',
      tmplId: TEMPLATE_ID.STATUS,
      tmpl: null,
      class: 'left'
    },
    {
      name: 'bills.field.actions',
      tmplId: TEMPLATE_ID.ACTIONS,
      tmpl: null,
      class: 'right',
    }
  ];
  public publisherConfigs: IBillsTableConfig<IBill>[] = [
    {
      title: 'bills.title.bandwidthMonthly',
      id: BILL_TYPE.BANDWIDTH,
      withTimeRange: true,
      tableConfig: {
        defaultFilter: [BANDWIDTH_BILL],
        dataField: 'data',
        searchWithFilters: true,
        matPaginator: true,
        filtersWidth100: true,
        highlight: (data) => {
          if (!data.active || !data.prolongationActive) {
            return {deactivated: true};
          }
        },
        searchWithFilters$: (searchTerm, filters, sortParams, pagingParams, timeRange) => {
          return this.billsService.getBills(this.publisherId, searchTerm, filters, pagingParams, sortParams, timeRange);
        },
        columns: [
          {
            name: 'bills.field.bandwidth',
            // dataField: 'dataSize',
            tmplId: TEMPLATE_ID.VALUE_UNIT,
            tmpl: null,
            class: 'left'
          },
          {
            name: 'bills.field.validity',
            tmplId: TEMPLATE_ID.DATE_RANGE,
            tmpl: null,
            class: 'left'
          },
          ...this.baseColumns
        ]

      }
    },
    {
      id: BILL_TYPE.STORAGE_SPACE,
      withTimeRange: true,
      title: 'bills.title.storageMonthly',
      tableConfig: {
        defaultFilter: [STORAGE_SPACE_BILL],
        searchWithFilters: true,
        dataField: 'data',
        matPaginator: true,
        filtersWidth100: true,
        highlight: (data) => {
          if (!data.active || !data.prolongationActive) {
            return {deactivated: true};
          }
        },
        searchWithFilters$: (searchTerm, filters, sortParams, pagingParams, timeRange) => {
          return this.billsService.getBills(this.publisherId, searchTerm, filters, pagingParams, sortParams, timeRange);
        },
        columns: [
          {
            name: 'bills.field.storage',
            tmplId: TEMPLATE_ID.VALUE_UNIT,
            tmpl: null,
            class: 'left',
          },
          {
            name: 'bills.field.validity',
            tmplId: TEMPLATE_ID.DATE_RANGE,
            tmpl: null,
            class: 'left'
          },
          ...this.baseColumns
        ]
      }
    },
    {
      id: BILL_TYPE.MULTIBITRATE,
      withTimeRange: true,
      title: 'biils.title.multibitrate',
      tableConfig: {
        defaultFilter: [MULTIBITRATE_BILL],
        searchWithFilters: true,
        dataField: 'data',
        matPaginator: true,
        searchWithFilters$: (searchTerm, filters, sortParams, pagingParams, timeRange) => {
          return this.billsService.getBills(this.publisherId, searchTerm, filters, pagingParams, sortParams, timeRange)
            .pipe(tap((res) => {
              res.results.data.items.forEach((item) => {
                item.mediaId = _.cloneDeep(item.productId);
                item.productId = item.purchaseId.toString();
              });
            }));
        },
        columns: [
          {
            name: 'Media Id',
            tmplId: TEMPLATE_ID.MEDIA_ID,
            class: 'left'
          },
          ...this.baseColumns.filter((col) => col.tmplId !== TEMPLATE_ID.ACTIONS),
          {
            name: 'bills.field.actions',
            tmplId: TEMPLATE_ID.ACTION_DOWNLOAD,
            class: 'right',
            tmpl: null
          }
        ]

      }
    },
  ];
  public viewerConfigs: IBillsTableConfig<IBill>[] = [
    {
      groupTitle: 'bills.title.bills',
      id: BILL_TYPE.MEDIA,
      withFilters: true,
      withTimeRange: true,
      tableConfig: {
        defaultFilter: [MEDIA_BILL],
        searchWithFilters: true,
        filtersWidth100: true,
        matPaginator: true,
        searchWithFilters$: (searchTerm, filters, sortParams, pagingParams, timeRange) => {
          return this.billsService.getBills(this.publisherId, searchTerm, filters, pagingParams, sortParams, timeRange);
        },
        dataField: 'data',
        columns: [
          {
            name: 'bills.field.title',
            dataField: 'title',
            class: 'left',
          },
          {
            name: 'bills.field.type',
            dataField: 'mediaType',
            class: 'left',
          },
          ...this.baseColumns
        ]

      }
    }
  ];

  constructor(
    private billsService: BillsService
  ) {
  }

  public getConfig(id: string, userType: ACCOUNT_PAGE_TYPE): Observable<IBillsTableConfig<IBill>[]> {
    this.publisherId = id;
    switch (userType) {
      case ACCOUNT_PAGE_TYPE.PUBLISHER:
      case ACCOUNT_PAGE_TYPE.SUBSCRIPTIONS:
      case ACCOUNT_PAGE_TYPE.VIEWS:
        return of(this.publisherConfigs);
      case ACCOUNT_PAGE_TYPE.VIEWER:
        return of(this.viewerConfigs);
    }
  }

  public getTimeRanges(): Observable<IMediaTimerange[]> {
    const firstDateTimeRange = {
      year: 2020,
      month: 9,
      timeRange: {
        start: 1601499600000,
        end: 1604181599999
      }
    };
    const currentDate = new Date();
    const diffYears = currentDate.getFullYear() - firstDateTimeRange.year;
    let ranges: IMediaTimerange[] = [];
    for (let i = 0; i <= diffYears; i++) {
      const year = firstDateTimeRange.year + i;
      if (year === firstDateTimeRange.year) {
        ranges = ranges.concat(this.fillMonths(firstDateTimeRange.month, 11, year));
        // ranges.push(this.getYearRange(year));
      }
      if (year === currentDate.getFullYear()) {
        ranges = ranges.concat(this.fillMonths(firstDateTimeRange.year === year  ? firstDateTimeRange.month : 0, currentDate.getMonth(), year));
        // ranges.push(this.getYearRange(year));
      }
      if (year !== firstDateTimeRange.year && year !== currentDate.getFullYear()) {
        ranges = ranges.concat(this.fillMonths(0, 11, year));
        // ranges.push(this.getYearRange(year));
      }
    }
    return of(ranges.reverse());
  }

  private fillMonths(from: number = 0, to: number = 11, year: number): IMediaTimerange[] {
    const res = [];
    for (let i = from; i <= to; i++) {
      const startDate = moment([year, i]);
      const endDate = moment(startDate).endOf('month');

      const timeRange: IMediaTimerange = {
        year,
        month: moment(new Date(year, i, 1)).locale('DE').format('MMMM'),
        timeRange: {
          start: startDate.toDate().getTime(),
          end: endDate.toDate().getTime()
        }
      };
      res.push(timeRange);
    }
    return res;
  }
}

const MEDIA_BILL = {
  field: 'TYPE',
  value: BILL_TYPE.MEDIA
} as IListFilterItem;

const SUBSCRIPTION_BILL = {
  field: 'TYPE',
  value: BILL_TYPE.SUBSCRIPTION
} as IListFilterItem;

const BANDWIDTH_BILL = {
  field: 'TYPE',
  value: BILL_TYPE.BANDWIDTH
} as IListFilterItem;

const STORAGE_SPACE_BILL = {
  field: 'TYPE',
  value: BILL_TYPE.STORAGE_SPACE
} as IListFilterItem;

const MULTIBITRATE_BILL = {
  field: 'TYPE',
  value: BILL_TYPE.MULTIBITRATE
} as IListFilterItem;
