import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IPublisher} from '../../../../models/publisher.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CURRENCY_SYMBOLS, IBill, IFormFieldsConfig, INPUT_TYPES} from 'ui-elements';
import {DateHelper} from 'ui-elements';
import {cutString} from 'ui-elements';

@Component({
  selector: 'app-bill-details',
  templateUrl: './bill-details.component.html',
  styleUrls: ['./bill-details.component.sass']
})
export class BillDetailsComponent implements OnInit {
  @Input() public publisher: IPublisher;
  @Input() public bill: IBill;
  @Output() public back$: EventEmitter<any> = new EventEmitter<any>();
  public form: FormGroup = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    companyName: ['', [Validators.required]],
    accountId: ['', [Validators.required]],
    type: ['', [Validators.required]],
    billNumber: ['', [Validators.required]],
    date: ['', [Validators.required]],
    amount: ['', [Validators.required]],
  });
  public activateForm = this.fb.group({
    active: [false]
  });
  public leftConfig: IFormFieldsConfig[] = [
    {
      name: 'firstName',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Name',
        placeholder: '',
      }
    },
    {
      name: 'lastName',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Vorname',
        placeholder: '',
      }
    },
    {
      name: 'companyName',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Firma',
        placeholder: '',
      }
    },
    {
      name: 'accountId',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Kunden ID',
        placeholder: '',
      }
    },
    {
      name: 'type',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Abo Art',
        placeholder: '',
      }
    },
    {
      name: 'billNumber',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Rechnungsnummer',
        placeholder: '',
      }
    },
  ];

  public rightConfig: IFormFieldsConfig[] = [
    {
      name: 'date',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Datum',
      }
    },
    {
      name: 'amount',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Summe',
      }
    }
  ];
  constructor(
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.form.get('firstName').setValue(this.publisher.user.firstName);
    this.form.get('lastName').setValue(this.publisher.user.lastName);
    this.form.get('companyName').setValue(this.publisher.user.companyName);
    this.form.get('accountId').setValue(cutString(this.publisher.user.accountId, 0, 8));
    this.form.get('type').setValue(this.bill.type);
    this.form.get('billNumber').setValue(this.bill.billNumber);
    this.form.get('date').setValue(DateHelper.formatDate(this.bill.date));
    this.form.get('amount').setValue(CURRENCY_SYMBOLS[this.bill.amount.currency] + this.bill.amount.amount);
  }

  public back(): void {
    this.back$.emit();
  }

}
