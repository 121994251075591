import {Component, Input, OnInit} from '@angular/core';
import {IUnitKeyValue} from 'ui-elements';

@Component({
  selector: 'app-action-dropdown',
  templateUrl: './action-dropdown.component.html',
  styleUrls: ['./action-dropdown.component.sass']
})
export class ActionDropdownComponent implements OnInit {
  @Input() values: IUnitKeyValue[];
  @Input() placeholder: string;

  constructor() { }

  ngOnInit(): void {
  }

  public selectValue(value: IUnitKeyValue): void {
    if (value.callback) {
      value.callback(value.args);
    }
  }

}
