export enum API_URLS_ADMIN {
  ROOT_URL = 'rest/v1/',
  LOGIN = 'auth/login',
  LOGOUT = 'auth/logout',
  PUBLISHER_LIST = 'publisher/list',
  VIEWERS_LIST = 'viewers',
  COUNTRIES = 'api/v1/cms/countries',
  GET_LOCALIZATION_MESSAGES = 'api/v1/localization/messages',
  STREAMS = 'media/streams',
  VODS = 'media/vods',
  GET_INVOICE = 'invoice',
  INVOICE_FILTERS = 'invoice/filters',
  DOWNLOAD_INVOICE_XLSX = 'invoice/xlsx/{timeRange}/{itemNum}',
  GENRES = 'api/v1/cms/stream/genres',
  TOGGLE_PUBLISHER_STATE = 'publisher/deactivate/{id}',
  TOGGLE_PUBLISHER_BANDWIDTH_LIMIT = 'subscriptions/bandwidth-limit/{id}',
  TOGGLE_PUBLISHER_HIDE_BANDWIDTH_LIMIT = 'user/hide-data-limits/{id}',
  TOGGLE_VIEWER_STATE = 'viewers/deactivate/{id}',
  ACTIVATE_MEDIA = 'media/activate/{mediaId}',
  DEACTIVATE_MEDIA = 'media/deactivate/{mediaId}',
  ACCOUNT_STATUSES = 'account/statuses',
  PUBLISHER = 'publisher/{id}',
  CHANGE_PASSWORD = 'change/password',
  VIEWER = 'viewers/{id}',
  SAVE_USER = 'user/edit',
  REJECT_ACCOUNT = 'approving/accounts/reject/{id}',
  APPROVE_ACCOUNT = 'approving/accounts/approve/{id}',
  LOGIN_AS_TOKEN = 'auth/login/as/{accountId}',
  PUBLISHER_BILLS = 'billing/list/{id}',
  BILLS = 'billing/list',
  GET_MEDIA_LIST_TIMELINE = 'open/api/video/options/timerange',
  PUBLISHER_FILTERS = 'publisher/filters',
  VIEWS_FILTERS = 'views/filters',
  DASHBOARD = 'dashboard/publisher/data',
  SUBSCRIPTIONS = 'subscriptions/publishers',
  SUBSCRIPTION_DETAILS = 'subscriptions/details/{id}',
  SUBSCRIPTIONS_PERIODS = 'subscriptions/manual/periods',
  SUBSCRIPTIONS_TARIFFS = 'subscriptions/manual/tariffs/{id}',
  BANDWIDTH_TARIFFS = 'subscriptions/manual/bandwidth-tariffs/{id}',
  SUBSCRIPTION_CREATE = 'subscriptions/manual/create',
  SUBSCRIPTION_UPDATE = 'subscriptions/manual/update/{id}',
  SUBSCRIPTION_DELETE = 'subscriptions/manual/delete/{id}',
  DOWNLOAD_SUBSCRIPTION_PDF = 'subscriptions/pdf/{id}',
  SEND_PDF = 'purchase/send/invoice/{id}',
  SEND_PDF_CHARGEBACK = 'purchase/send/chargeback/{id}',
  CANCEL_SUBSCRIPTION = 'subscriptions/cancel/prolongation/{id}',
  CHARGEBACK = 'subscriptions/chargeback/{id}',
  GET_VIEWS = 'views',
  DOWNLOAD_PURCHASE_PDF = 'purchase/pdf/{id}',
  PAYOUTS_LIST = 'payouts/publisher',
  PUBLISHER_PAYOUTS = 'payouts/publisher/{publisherId}/monthly',
  PUBLISHER_PAYOUT = 'payouts/publisher/monthly/{id}',
  SEND_PAYOUT = 'payouts/payout/monthly/{id}',
  PAYOUTS_TIMERANGE = 'payouts/publisher/{publisherId}/monthly/timerange',
  PAYOUT_PDF = 'payouts/payout/monthly/{purchaseId}/pdf',
  SEND_PAYOUT_PDF = 'payouts/payout/monthly/{id}/send/creditNotes',
  PUBLISHER_CURRENT_SUBSCRIPTION = 'subscriptions/publisher/{id}/list',
  PUBLISHER_PURCHASE = 'purchase/media/publisher/{id}',
  MEDIA_PURCHASE = 'purchase/media/{id}',
  MEDIA = 'media/{id}',
  MULTIBITRATE_PRICE = 'multibitrate/price',
  TOGGLE_PUBLISHER_PAYOUTS = 'payouts/payout/all/{publisherId}'
}

export class UrlGenerator {
  /**
   * @url: url should be in 'someString/{someOption}/anotherString{anotherOption}' format
   * key in {} with braces will be replaced with value from object which stored for this key
   **/
  public static generate(url: string, options: { [key: string]: any }, queryParams: { [key: string]: any } = {}): string {
    let modifiedUrl = url;

    Object.entries(options).forEach(([key, value]: any[]) => {
      const regexp = new RegExp(`{${key}}`, 'g');

      let stringValue: string;

      try {
        stringValue = value.toString();
      } catch {
        throw new Error(`Cannot stringify value ${value}`);
      }

      modifiedUrl = modifiedUrl.replace(regexp, stringValue);
    });

    if (Object.keys(queryParams).length) {
      modifiedUrl += '?';

      Object.keys(queryParams).forEach((key, index) => {
        if (index > 0) {
          modifiedUrl += '&';
        }
        if (queryParams[key] && queryParams[key] !== 'any') {
          modifiedUrl += `${key}=${queryParams[key]}`
        }
      });
    }

    return modifiedUrl;
  }
}
