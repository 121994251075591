import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import {
  AutoDestroyService, CURRENCY_SYMBOLS,
  IAdminDashboard,
  IWidgetConfig, LocalizationProvider,
  WIDGET_TYPE,
} from 'ui-elements';
import { ITimerange } from 'ui-elements/lib/types/types';
import { ChartAndSummaryComponent } from './components/chart-and-summary/chart-and-summary.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass'],
  providers: [AutoDestroyService],
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('publishersChart', { static: false })
  public publishersChart: ChartAndSummaryComponent;
  @ViewChild('viewersChart', { static: false })
  public viewersChart: ChartAndSummaryComponent;
  public data: IAdminDashboard;
  public widgetsConfigs: IWidgetConfig[];
  public readonly viewerColorScheme: any = {
    PAY_PER_VIEW: '#0e54a9',
    LEAD: '#0c1d3f',
    VOUCHER: '#007e8c',
    FREE: '#ffbe00',
    total: '#fc4349',
  };
  public readonly colorScheme: any = {
    line1: '#0e54a9',
    line2: '#0c1d3f',
    line3: '#007e8c',
    line4: '#ffbe00',
    line5: '#bad668',
    total: '#fc4349',
  };

  public loading: boolean;

  constructor(
    private dashboardService: DashboardService,
    private localizationProvider: LocalizationProvider) {}

  ngOnInit(): void {
    this.initData(null);
  }

  ngAfterViewInit(): void {
    document.querySelector('.admin-content').classList.add('transparent');
  }

  private initData(event: ITimerange): void {
    this.loading = true;
    this.dashboardService
    .dashboard(event?.start, event?.end)
    .pipe(take(1))
    .subscribe((res) => {
      this.loading = false;
      this.setKeysToLegends(res);
      this.data = res;
      this.initWidgetConfigs();
    }, () => this.loading = false);
  }

  private setKeysToLegends(data: IAdminDashboard): void {
    data.publisherLegend.forEach((item) => {
      item.title = 'dashboard.publishersChart.' + item.title;
    });
    data.viewAccessLegend.forEach((item) => {
      item.title = 'dashboard.pieChart.legend.' + item.title;
    });
    data.publisherSummary.category.forEach((item) => {
      item.title = this.localizationProvider.getByKey('dashboard.publishersChart.' + item.title);
    });
    data.viewAccessSummary.category.forEach((item) => {
      item.title = this.localizationProvider.getByKey('dashboard.pieChart.legend.' + item.title);
    });
  }

  private initWidgetConfigs(): void {
    this.widgetsConfigs = [
      {
        type: WIDGET_TYPE.PRIMARY,
        title: 'Publishers income without fees',
        amount: this.data.widget.publisherIncomeEUR?.total,
        currency: CURRENCY_SYMBOLS.EUR,
        icon: 'coins',
        toFixed: 2,
        size: 2,
        infoTable: this.resolvePublisherInfoTable(this.data.widget.publisherIncomeEUR)
      },
      {
        type: WIDGET_TYPE.PRIMARY,
        title: 'Publishers income without fees',
        amount: this.data.widget.publisherIncomeUSD?.total,
        currency: CURRENCY_SYMBOLS.USD,
        icon: 'coins',
        toFixed: 2,
        size: 2,
        infoTable: this.resolvePublisherInfoTable(this.data.widget.publisherIncomeUSD)
      },
      {
        type: WIDGET_TYPE.SUCCESS,
        title: 'Streamdust income',
        amount: this.data.widget.streamdustIncomeEUR?.total,
        currency: CURRENCY_SYMBOLS.EUR,
        icon: 'coins',
        toFixed: 2,
        size: 2,
        infoTable: this.resolveStreamdustInfoTable(this.data.widget.streamdustIncomeEUR)
      },
      {
        type: WIDGET_TYPE.SUCCESS,
        title: 'Streamdust income',
        amount: this.data.widget.streamdustIncomeUSD?.total,
        currency: CURRENCY_SYMBOLS.USD,
        icon: 'coins',
        toFixed: 2,
        size: 2,
        infoTable: this.resolveStreamdustInfoTable(this.data.widget.streamdustIncomeUSD)
      },
      {
        type: WIDGET_TYPE.WARNING,
        title: 'Gesamtzuschauer',
        amount: this.data.widget.viewerCount,
        icon: 'users',
        size: 2
      },
    ];
  }

  private resolvePublisherInfoTable(income: any): {title: string, amount: number}[] {
    return [
      {
        title: 'Donation',
        amount: income?.donation || 0.00
      },
      {
        title: 'Full video',
        amount: income?.fullVideo || 0.00
      },
      {
        title: 'Group',
        amount: income?.group || 0.00
      },
      {
        title: 'Slot',
        amount: income?.slot || 0.00
      },
    ];
  }

  private resolveStreamdustInfoTable(income: any): {title: string, amount: number}[] {
    return [
      {
        title: 'Bandwidth',
        amount: income?.bandwidth || 0.00
      },
      {
        title: 'Fee',
        amount: income?.fee || 0.00
      },
      {
        title: 'Multibitrate',
        amount: income?.multibitrate || 0.00
      },
      {
        title: 'Storage',
        amount: income?.storage || 0.00
      },
      {
        title: 'Subscription',
        amount: income?.subscription || 0.00
      },
      {
        title: 'Chargeback',
        amount: income?.chargeback || 0.00
      },
    ];
  }

  public timeRangeFilter(event: ITimerange): void {
    this.resetData();
    this.getData(event);
  }

  private getData(e: ITimerange): void {
    this.initData(e);
  }

  private resetData(): void {
    this.data = null;
  }

  ngOnDestroy(): void {
    document.querySelector('.admin-content').classList.remove('transparent');
  }
}
