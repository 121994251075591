import {Pipe, PipeTransform} from '@angular/core';
import {DateHelper} from 'ui-elements';

@Pipe({
    name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {

    transform(value: unknown, format: string = 'DD MMM YYYY, HH:mm', ...args: unknown[]): unknown {
        if (!value) {
            return;
        }

        return DateHelper.formatDateTime(value, format);
    }

}
