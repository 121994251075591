<div class="card">
  <div class="card-body bg-white">
    <!--<div class="table-wrapper-border">-->
    <ano-table #tableComponent [tableConfig]="tableConfig"
    [withSearch]="true"
    (search$)="search($event)">
    </ano-table>
    <!--</div>-->
    <ano-loader [isOpen]="loading"></ano-loader>
  </div>
</div>


<ng-template #idTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.media.accountId.internalId | stringCut}}
        </span>
  </div>
</ng-template>
<ng-template #streamIdTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.media.id | stringCut}}
        </span>
  </div>
</ng-template>

<ng-template #startDateTmpl let-data="rowData">
  <div class="text-left">
    {{(data.media.startTimestamp || data.media.created) | dateFormat: 'DD MMM YYYY, HH:mm':true}}
  </div>
</ng-template>
<ng-template #endDateTmpl let-data="rowData">
  <div class="text-left">
    {{data.media.endTimestamp | dateFormat: 'DD MMM YYYY, HH:mm':true}}
  </div>
</ng-template>

<ng-template #activeTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.media.deactivated ? 'Deactivated' : 'Active'}}
        </span>
  </div>
</ng-template>

<ng-template #titleTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.media.title}}
        </span>
  </div>
</ng-template>

<ng-template #firstNameTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.user.firstName}}
        </span>
  </div>
</ng-template>

<ng-template #lastNameTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.user.lastName}}
        </span>
  </div>
</ng-template>
<ng-template #liveTmpl let-data="rowData">
  <div class="flex justify-content-center">
    <div class="status-circle" [class.success-circle]="data.media.online" [class.danger-circle]="!data.media.online"></div>
  </div>
</ng-template>

<ng-template #statusTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{('player.status.' + data.media.status.stream.toLowerCase() | localize) || '-'}}
        </span>
  </div>
</ng-template>

<ng-template #recordedTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.media.status.record === 'RECORDED' ? 'Ja' : 'Nein'}}
        </span>
  </div>
</ng-template>

<ng-template #companyTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.user.companyName || '-'}}
        </span>
  </div>
</ng-template>

<ng-template #countryTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{(data.user.countryCode | country) || '-'}}
        </span>
  </div>
</ng-template>

<ng-template #actionsTmpl let-data="rowData">
  <div class="table-actions-list">
    <app-action-dropdown *ngIf="actions" [values]="actions[data.media.id]" [placeholder]="'Select action'"></app-action-dropdown>
  </div>
</ng-template>

<ng-template #confirm let-dialogRef="dialogRef">
  <ano-modal [dialogRef]="dialogRef" [title]="confirmTitle | localize" [isConfirmation]="true">
    {{confirmDescription | localize}}
  </ano-modal>
</ng-template>
