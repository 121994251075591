import { Injectable } from '@angular/core';
import {ApiService} from 'ui-elements';
import {IPagedResponse, IResponse, PagingParams, SortParam} from 'ui-elements';
import {Observable} from 'rxjs';
import {API_URLS_ADMIN, UrlGenerator} from '../../const/api.urls';
import {IStream, IVOD} from '../../models/video.model';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(private apiService: ApiService) { }

  public getStreams(searchTerm: string = '', sort: SortParam[] = DEFAULT_SORT, paging: PagingParams = DEFAULT_PAGING): Observable<IPagedResponse<IStream>> {
    return this.apiService.post(API_URLS_ADMIN.STREAMS, {
      searchTerm,
      sort,
      paging
    });
  }

  public getVODS(searchTerm: string = '', sort: SortParam[] = DEFAULT_SORT, paging: PagingParams = DEFAULT_PAGING): Observable<IPagedResponse<IVOD>> {
    return this.apiService.post(API_URLS_ADMIN.VODS, {
      searchTerm,
      sort,
      paging
    });
  }

  public activateMedia(mediaId: string): Observable<IResponse> {
    return this.apiService.put(UrlGenerator.generate(API_URLS_ADMIN.ACTIVATE_MEDIA, {mediaId}), {});
  }

  public deactivateMedia(mediaId: string): Observable<IResponse> {
    return this.apiService.put(UrlGenerator.generate(API_URLS_ADMIN.DEACTIVATE_MEDIA, {mediaId}), {});
  }

  public getMedia(id: string): Observable<IResponse<any>> {
    return this.apiService.get(UrlGenerator.generate(API_URLS_ADMIN.MEDIA, {id}));
  }
}

const DEFAULT_PAGING = {
  page: 0,
  itemsOnPage: 10
} as PagingParams;

const DEFAULT_SORT = [{
  field: 'STREAM_START',
  order: 'DESC'
}] as SortParam[];
