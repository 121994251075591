<div class="card" *ngIf="chartData">
  <div class="card-header bg-white pb-0">
    <h2 class="title-level-3">{{title}} </h2>
  </div>
  <div class="card-body bg-white">
    <div class="row row-padding-10">
      <div class="col-lg">
        <ano-scatter-chart [id]="id" #chartComponent *ngIf="chartData.length" [data]="chartData" [categories]="data?.category"
                           [legend]="legend" [colorScheme]="colorScheme" [width]="805"></ano-scatter-chart>
        <div class="table-wrapper-border">
          <div class="table-responsive">
            <table class="table table-sm table-border">
              <thead *ngIf="tableHeaderItems">
                <tr>
                  <th *ngFor="let item of tableHeaderItems"><span>{{item}}</span></th>
                </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of summary?.category">
                <td width="45%">{{item.title}}</td>
                <td class="dashboard-table-number" width="55%"><ano-number [data]="item.count"></ano-number></td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td width="45%">INSGESAMT</td>
                <td class="dashboard-table-number" width="55%"><ano-number [data]="summary?.total"></ano-number></td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <ano-legend-box [legend]="legend" [colorScheme]="colorScheme"
                        (change)="chartComponent.rerenderChart($event)"></ano-legend-box>
      </div>
    </div>
  </div>
</div>
