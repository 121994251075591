<div class="card">
  <div class="card-header bg-white pb-0">
    <h2 class="title-level-3">Multibitrate Detail</h2>
    <ano-button (click)="back()" [config]="{
    type: 'button',
    classList: 'cursor-pointer width110px',
    styleType: 'primary'}"><i class="icon icon-back-arrow text-white cursor-pointer"></i> Zurück
    </ano-button>
  </div>
  <div class="card-body bg-white">
    <div class="row">
      <div class="col-5">
        <ano-form *ngIf="leftForm"
                  [fieldsConfig]="leftConfig"
                  [forcedViewMode]="true"
                  [form]="leftForm"
                  [isLightForm]="true" [materialForm]="true" [withoutSave]="true"
        >
        </ano-form>
      </div>
      <div class="col-5">
        <ano-form *ngIf="rightForm"
                  [fieldWidth]="7"
                  [fieldsConfig]="rightConfig"
                  [forcedViewMode]="true"
                  [form]="rightForm"
                  [isLightForm]="true"
                  [labelWidth]="5" [materialForm]="true" [withoutSave]="true"
        >
        </ano-form>

        <div class="row">
          <div class="col">
            <div class="details-actions">
              <ano-button (click)="viewPdf()" [config]="{
                type: 'button',
                classList: 'cursor-pointer full-width',
                styleType: 'primary'}">
                <i *ngIf="viewDone" class="icon icon-check text-success cursor-pointer"></i>
                <i class="icon icon-admin-eye text-white cursor-pointer"></i>
                <ano-loader [isOpen]="viewLoading"></ano-loader>
                Show PDF
              </ano-button>
              <ano-button (click)="downloadPdf()" [config]="{
                type: 'button',
                classList: 'cursor-pointer full-width',
                styleType: 'primary'}">
                <i *ngIf="downloadDone" class="icon icon-check text-success cursor-pointer"></i>
                <i class="icon icon-download text-white cursor-pointer">
                </i>
                <ano-loader [isOpen]="download"></ano-loader>
                Download PDF
              </ano-button>
              <ano-button (click)="sendPdf()" [config]="{
                type: 'button',
                classList: 'cursor-pointer full-width',
                styleType: 'success'}">
                <i *ngIf="sendDone" class="icon icon-check text-white cursor-pointer"></i>
                <i class="icon icon-telegram-round text-white cursor-pointer"></i>
                <ano-loader [isOpen]="sendLoading"></ano-loader>
                Send PDF
              </ano-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ano-loader [isOpen]="!data"></ano-loader>
</div>
