<ano-table #subscriptionsTable [tableConfig]="tableConfig"></ano-table>

<ng-template #actionsTmpl let-data="rowData">
  <span (click)="goToBillDetails(data)"><i class="icon icon-login text-primary cursor-pointer"></i></span>
</ng-template>

<ng-template #subscriptionPaymentTmpl let-rowData="rowData">
  {{rowData?.price?.amount}} {{rowData?.price?.currency}}/{{rowData?.period}}
</ng-template>

<ng-template #dateTmpl let-rowData="rowData">
  {{rowData.expireDate | dateFormat: 'DD MMM YYYY'}}
</ng-template>

<ng-template #tariffPlanTmpl let-rowData="rowData">
  <ng-container *ngIf="rowData.type === 'BANDWIDTH'">
    {{ rowData.tariffPlan }} {{rowData?.bandwidth ? '(' + rowData.bandwidth?.dataSize?.value + ' ' + rowData.bandwidth?.dataSize?.unit + ')' : ''}}
  </ng-container>
  <ng-container *ngIf="rowData.type === 'STORAGE_SPACE'">
    {{ rowData.tariffPlan }} {{rowData?.storageSpace ? '(' + rowData.storageSpace?.dataSize?.value + ' ' + rowData.storageSpace?.dataSize?.unit + ')' : ''}}
  </ng-container>
  <ng-container *ngIf="rowData.type === 'SUBSCRIPTION'">
    {{ rowData.tariffPlan }}
  </ng-container>
</ng-template>

<ng-template #nextPaymentTmpl let-rowData="rowData">
    <span *ngIf="rowData.cancelable">
        {{rowData.nextPaymentDate | dateFormat: 'DD MMM YYYY'}}
    </span>
  <span *ngIf="!rowData.cancelable">
         -
    </span>
</ng-template>

<ng-template #manualTmpl let-rowData="rowData">
  <span>{{(rowData.manual ? 'subscription-manage.table.subscription-type.manual' :
    'subscription-manage.table.subscription-type.paid') | localize}}</span>
</ng-template>

