import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ITableConfig} from 'ui-elements';
import {MediaService} from '../../services/media/media.service';
import {IStream} from '../../models/video.model';
import {AutoDestroyService} from 'ui-elements';
import {take, takeUntil, tap} from 'rxjs/operators';
import {TableComponent} from 'ui-elements';
import {IPublisher} from '../../models/publisher.model';
import {environment} from '../../../environments/environment';
import {IDropdownConfig} from '../../models/types';
import {AuthService} from '../../services/auth/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
  selector: 'app-streams',
  templateUrl: './streams.component.html',
  styleUrls: ['./streams.component.sass'],
  providers: [AutoDestroyService]
})
export class StreamsComponent implements OnInit {
  @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;
  @ViewChild('tableComponent', {static: false}) public table: TableComponent;
  @ViewChild('idTmpl', {static: true}) public idTmpl: TemplateRef<any>;
  @ViewChild('streamIdTmpl', {static: true}) public streamIdTmpl: TemplateRef<any>;
  @ViewChild('liveTmpl', {static: true}) public liveTmpl: TemplateRef<any>;
  @ViewChild('titleTmpl', {static: true}) public titleTmpl: TemplateRef<any>;
  @ViewChild('firstNameTmpl', {static: true}) public firstNameTmpl: TemplateRef<any>;
  @ViewChild('lastNameTmpl', {static: true}) public lastNameTmpl: TemplateRef<any>;
  @ViewChild('companyTmpl', {static: true}) public companyTmpl: TemplateRef<any>;
  @ViewChild('countryTmpl', {static: true}) public countryTmpl: TemplateRef<any>;
  @ViewChild('activeTmpl', {static: true}) public activeTmpl: TemplateRef<any>;
  @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
  @ViewChild('recordedTmpl', {static: true}) public recordedTmpl: TemplateRef<any>;
  @ViewChild('startDateTmpl', {static: true}) public startDateTmpl: TemplateRef<any>;
  @ViewChild('endDateTmpl', {static: true}) public endDateTmpl: TemplateRef<any>;
  @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
  public tableConfig: ITableConfig<IStream>;
  public searchTerm: string;
  public actions: IDropdownConfig;
  public loading: boolean;
  public confirmTitle: CONFIRM_TITLE;
  public confirmDescription: CONFIRM_DESCRIPTION;

  constructor(
    private mediaService: MediaService,
    private destroy$: AutoDestroyService,
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.tableConfig = {
      dataField: 'data',
      matPaginator: true,
      fullHeight: true,
      highlight: (data) => {
        if (data.media.deactivated) {
          return {deactivated: true};
        }
      },
      searchFn: (sortParams, pagingParams) => {
        return this.mediaService.getStreams(this.searchTerm, sortParams, pagingParams)
          .pipe(tap((res) => this.mapToActions(res.results.data.items)));
      },
      columns: [
        {
          name: 'actions',
          tmpl: this.actionsTmpl,
          class: 'dropdown-actions'
        },
        {
          name: 'Livestream Start',
          sortField: 'STREAM_START',
          tmpl: this.startDateTmpl,
          class: 'left'
        },
        {
          name: 'Livestream Finish',
          sortField: 'STREAM_END',
          tmpl: this.endDateTmpl,
          class: 'left'
        },
        {
          name: 'Active',
          tmpl: this.activeTmpl,
          sortField: 'DEACTIVATED',
          class: 'left'
        },
        {
          name: 'Stream Id',
          tmpl: this.streamIdTmpl,
          sortField: 'MEDIA_ID',
          class: 'left no-white-space'
        },
        {
          name: 'Live',
          tmpl: this.liveTmpl,
          sortField: 'STREAM_LIVE',
          class: 'left'
        },
        {
          name: 'STATUS',
          tmpl: this.statusTmpl,
          sortField: 'STREAM_STATUS',
          class: 'left'
        },
        {
          name: 'Recorded',
          tmpl: this.recordedTmpl,
          sortField: 'STREAM_HAS_RECORD',
          class: 'left'
        },
        {
          name: 'Title',
          tmpl: this.titleTmpl,
          class: 'left',
          sortField: 'TITLE'
        },
        {
          name: 'Kunden Id',
          tmpl: this.idTmpl,
          sortField: 'PUBLISHER_ID',
          class: 'left no-white-space'
        },
        {
          name: 'firstName',
          tmpl: this.firstNameTmpl,
          sortField: 'PUBLISHER_FIRST_NAME',
          class: 'left'
        },
        {
          name: 'lastName',
          tmpl: this.lastNameTmpl,
          sortField: 'PUBLISHER_LAST_NAME',
          class: 'left'
        },
        {
          name: 'firm',
          tmpl: this.companyTmpl,
          sortField: 'PUBLISHER_COMPANY',
          class: 'left'
        },
        {
          name: 'land',
          tmpl: this.countryTmpl,
          sortField: 'PUBLISHER_COUNTRY',
          class: 'left'
        }
      ]

    };
  }

  public activateMedia(data: IStream): void {
    this.loading = true;
    this.mediaService.activateMedia(data.media.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.loading = false;
        if (!res?.success) {
          return;
        }
        this.table.refreshPage();
      }, () => this.loading = false);
  }

  public deactivateMedia(data: IStream): void {
    this.loading = true;
    this.mediaService.deactivateMedia(data.media.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.loading = false;
        if (!res?.success) {
          return;
        }
        this.table.refreshPage();
      }, () => this.loading = false);
  }

  private mapToActions(res: IStream[]): void {
    this.actions = {};
    res.forEach((stream) => {
      this.actions[stream.media.id] = [
        {
          key: 'Login to account',
          value: 'Login to account',
          callback: () => {
            this.authService.loginToAccount(stream.user.accountId, 'streams/' + stream.media.id + '/info');
          }
        },
        {
          key: stream.media.deactivated ? 'Livestream Activieren' : 'Livestream Deactivieren',
          value: stream.media.deactivated ? 'Livestream Activieren' : 'Livestream Deactivieren',
          callback: () => {
            this.resolveConfirmText(stream.media.deactivated);
            const dialogRef = this.dialog.open(this.confirm);
            dialogRef.afterClosed()
              .pipe(take(1))
              .subscribe((confirm: boolean) => {
                if (confirm) {
                  stream.media.deactivated ? this.activateMedia(stream) : this.deactivateMedia(stream);
                }
              });
          }
        },
        {
          key: 'Incoming Transactions',
          value: 'Incoming Transactions',
          callback: () => {
            this.router.navigate(['media', stream.media.id, 'transactions']);
          }
        },
      ];
    });
  }

  public resolveConfirmText(deactivated: boolean): void {
    if (deactivated) {
      this.confirmTitle = CONFIRM_TITLE.ACTIVATE;
      this.confirmDescription = CONFIRM_DESCRIPTION.DEACTIVATE;
      return;
    }
    this.confirmTitle = CONFIRM_TITLE.DEACTIVATE;
    this.confirmDescription = CONFIRM_DESCRIPTION.DEACTIVATE;
  }

  public search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.table.resetTableData();
    this.table.refreshData({});
  }

}

enum CONFIRM_TITLE {
  ACTIVATE= 'admin.stream.confirm.activate.title',
  DEACTIVATE = 'admin.stream.confirm.deactivate.title'
}

enum CONFIRM_DESCRIPTION {
  ACTIVATE= 'admin.stream.confirm.activate.description',
  DEACTIVATE = 'admin.stream.confirm.deactivate.description'
}
