import { Injectable } from '@angular/core';
import { API_URLS, ApiService, IAdminDashboard } from 'ui-elements';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { API_URLS_ADMIN } from '../../const/api.urls';
import _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { ElementSchemaRegistry } from '@angular/compiler';

const moment = _rollupMoment || _moment;

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private apiService: ApiService) {}

  public dashboard(
    from = +moment().utc().startOf('month').format('x'),
    to = +moment().utc().endOf('month').format('x'),
    period = 'DAY'
  ): Observable<IAdminDashboard> {
    // return this.apiService.get('assets/mock/dashboard.json', {isWithoutRootUrl: true})
    return this.apiService
      .post(API_URLS_ADMIN.DASHBOARD, { from, to, period })
      .pipe(pluck('results', 'data'));
  }
}
