<div class="card">
  <div class="card-body bg-white">
    <ano-table #tableComponent
               [tableConfig]="tableConfig"
               [timeRangeConfig]="timeRangeConfig"
               (timeRangeFilter$)="timeRangeFilter($event)"
               [showDatepicker]="true"
               [withSearch]="true"
               (search$)="search($event)"
               [filters]="filters"
               (filter$)="onFilter($event)"
               [downloadButton]="true"
               [downloadButtonText]="'download .xlsx'"
               [isDownloadLoading]="isXlsxLoading"
               (download$)="downloadXLSX()">
    </ano-table>
  </div>
</div>

<ng-template #idTmpl let-data="rowData">
  <div class="long-text">
    <span>{{ data.userId | stringCut }}</span>
  </div>
</ng-template>

<ng-template #invoiceDateTmpl let-data="rowData">
  <div class="long-text">
    <span>{{ data.invoiceDate }}</span>
  </div>
</ng-template>

<ng-template #invoiceNumberTmpl let-data="rowData">
  <div class="long-text">
    <span>{{ data.invoiceNumber }}</span>
  </div>
</ng-template>

<ng-template #productTypeTmpl let-data="rowData">
  <div class="long-text">
    <span>{{ data.productType }}</span>
  </div>
</ng-template>

<ng-template #firstNameTmpl let-data="rowData">
  <div class="long-text">
    <span>{{ data.firstName }}</span>
  </div>
</ng-template>

<ng-template #lastNameTmpl let-data="rowData">
  <div class="long-text">
    <span>{{ data.lastName }}</span>
  </div>
</ng-template>

<ng-template #companyTmpl let-data="rowData">
  <div class="long-text">
    <span>{{ data.company || '-' }}</span>
  </div>
</ng-template>

<ng-template #companyVatIdTmpl let-data="rowData">
  <div class="long-text">
    <span>{{ data.companyVatId || '-' }}</span>
  </div>
</ng-template>

<ng-template #publisherCountryTmpl let-data="rowData">
  <div class="long-text">
    <span>{{ (data.publisherCountry | country) || '-' }}</span>
  </div>
</ng-template>

<ng-template #areaTypeTmpl let-data="rowData">
  <div class="long-text">
    <span>{{ data.areaType }}</span>
  </div>
</ng-template>

<ng-template #priceNetTmpl let-data="rowData">
  <div class="long-text">
    <span>{{ data.priceNet }}</span>
  </div>
</ng-template>

<ng-template #priceGrossTmpl let-data="rowData">
  <div class="long-text">
    <span>{{ data.priceGross }}</span>
  </div>
</ng-template>

<ng-template #priceVatTmpl let-data="rowData">
  <div class="long-text">
    <span>{{ data.priceVat }}</span>
  </div>
</ng-template>

<ng-template #vatPercentTmpl let-data="rowData">
  <div class="long-text">
    <span>{{ data.vatPercent }}</span>
  </div>
</ng-template>

<ng-template #confirm let-dialogRef="dialogRef">
  <ano-modal [dialogRef]="dialogRef" [title]="confirmTitle | localize" [isConfirmation]="true">
    {{ confirmDescription | localize }}
  </ano-modal>
</ng-template>

<ng-template #actionsTmpl let-data="rowData">
  <div class="table-actions-list">
    <app-action-dropdown *ngIf="actions" [values]="actions[data._purchaseId]" [placeholder]="'Select action'"></app-action-dropdown>
    <ano-loader [isOpen]="actionLoaders[data.purchaseId]"></ano-loader>
  </div>
</ng-template>
