import { Injectable } from '@angular/core';
import {ApiService, PUBLISHER_FILTERS} from 'ui-elements';
import {
  SortParam,
  PagingParams,
  IPagedResponse,
  IResponse,
  IMediaTimerange,
  IUnitKeyValue, IListFilterItem, ITableFilters, IFilterListItem, SUBSCRIPTION_TYPE
} from 'ui-elements';
import {Observable} from 'rxjs';
import {
  ACCOUNT_STATUS,
  IPublisher,
  IPublisherFilters,
  IPublisherSubscriptionSummary
} from '../../models/publisher.model';
import {API_URLS_ADMIN, UrlGenerator} from '../../const/api.urls';
import {map, pluck} from 'rxjs/operators';
import {LocalizationProvider} from 'ui-elements';

@Injectable({
  providedIn: 'root'
})
export class PublisherService {
  private publisherFiltersNamespace = 'admin.publisher.filter.';

  constructor(
    private apiService: ApiService,
    private localizationProvider: LocalizationProvider
  ) { }

  public getPublishers(
    searchTerm: string = '',
    sort: SortParam[] = DEFAULT_SORT,
    paging: PagingParams = DEFAULT_PAGING,
    filters: IListFilterItem[] = []
    ): Observable<IPagedResponse<IPublisher>> {
    return this.apiService.post(API_URLS_ADMIN.PUBLISHER_LIST, {
      searchTerm,
      sort,
      paging,
      filters
    });
  }

  public getPublisher(id: string): Observable<IPublisher> {
    return this.apiService.get(UrlGenerator.generate(API_URLS_ADMIN.PUBLISHER, {id}))
      .pipe(pluck('results', 'data'));
  }

  public togglePublisherState(id: string, deactivated: boolean): Observable<IResponse> {
    return this.apiService.put(UrlGenerator.generate(API_URLS_ADMIN.TOGGLE_PUBLISHER_STATE, {id}), {deactivate: deactivated});
  }

  public togglePublisherBandwidthLimit(id: string, limitOff: boolean): Observable<IResponse> {
    return this.apiService.put(UrlGenerator.generate(API_URLS_ADMIN.TOGGLE_PUBLISHER_BANDWIDTH_LIMIT, {id}), {limitOff});
  }

  public togglePublisherHideBandwidthLimit(id: string, hiddenDataLimits: boolean): Observable<IResponse> {
    return this.apiService.post(UrlGenerator.generate(API_URLS_ADMIN.TOGGLE_PUBLISHER_HIDE_BANDWIDTH_LIMIT, {id}), {hiddenDataLimits});
  }

  public getAccountStatuses(): Observable<ACCOUNT_STATUS[]> {
    return this.apiService.get(API_URLS_ADMIN.ACCOUNT_STATUSES).pipe(pluck('results', 'statuses'));
  }

  public savePublisher(id: string, email: string, accountStatuses: ACCOUNT_STATUS[]): Observable<IResponse> {
    return this.apiService.post(API_URLS_ADMIN.SAVE_USER, {
      accountId: id,
      email,
      accountStatuses
    });
  }

  public rejectPublisher(id: string): Observable<IResponse> {
    return this.apiService.post(UrlGenerator.generate(API_URLS_ADMIN.REJECT_ACCOUNT, {id}), {});
  }

  public approvePublisher(id: string): Observable<IResponse> {
    return this.apiService.post(UrlGenerator.generate(API_URLS_ADMIN.APPROVE_ACCOUNT, {id}), {});
  }

  public getMediaListTimeRanges(filters: IListFilterItem[]): Observable<IMediaTimerange[]> {
    return this.apiService.post(API_URLS_ADMIN.GET_MEDIA_LIST_TIMELINE, {filters}, {
      openApi: true,
      isWithoutRootUrl: true
    }).pipe(pluck('results', 'data'));
  }

  public getFilters(filterTypes: IPublisherFilters): Observable<ITableFilters> {
    return this.apiService.post(API_URLS_ADMIN.PUBLISHER_FILTERS, filterTypes)
      .pipe(pluck('results', 'data'), map((res) => {
        const filterItems: IFilterListItem[] = [];
        Object.keys(res).forEach((filterOptionKey) => {
          const selectOptions = this.resolveFilterSelectOptions(res[filterOptionKey], filterOptionKey as PUBLISHER_FILTERS);
          if (filterOptionKey !== PUBLISHER_FILTERS.SORT) {
            selectOptions.unshift({
              key: '',
              value: this.publisherFiltersNamespace + 'all'
            });
          }

          filterItems.push({
            name: filterOptionKey,
            placeholder: filterOptionKey === PUBLISHER_FILTERS.SUBSCRIPTION_TYPE ? 'Subscription Type' : 'Abonnement Art',
            selectOptions,
          });
        });
        return {
          filterListItems: filterItems,
          filters: Object.keys(res) as PUBLISHER_FILTERS[]
        };
      }));
  }

  public getSubscriptions(
    searchTerm: string = '',
    sort: SortParam[] = DEFAULT_SORT,
    paging: PagingParams = DEFAULT_PAGING,
    filters: IListFilterItem[] = [
      {field: PUBLISHER_FILTERS.ACCOUNT_EVOLUTION, value: ['BEGINNER', 'ADVANCED', 'PROFESSIONAL']}]
  ): Observable<IPagedResponse<IPublisherSubscriptionSummary>> {
    return this.apiService.post(API_URLS_ADMIN.SUBSCRIPTIONS, {
      searchTerm,
      sort,
      paging,
      filters
    });
  }

  public getSubscriptionDetails(id: string): Observable<any> {
    return this.apiService.get(UrlGenerator.generate(API_URLS_ADMIN.SUBSCRIPTION_DETAILS, {id}))
      .pipe(pluck('results', 'data'));
  }

  public downloadPdf(id: string): Observable<any> {
    return this.apiService.get(UrlGenerator.generate(API_URLS_ADMIN.DOWNLOAD_SUBSCRIPTION_PDF, {id}),
      {responseType: 'blob', observe: 'response'});
  }

  public sendPdf(id: string): Observable<IResponse> {
    return this.apiService.post(UrlGenerator.generate(API_URLS_ADMIN.SEND_PDF, {id}), {});
  }

  public sendChargebackPdf(id: string): Observable<IResponse> {
    return this.apiService.post(UrlGenerator.generate(API_URLS_ADMIN.SEND_PDF_CHARGEBACK, {id}), {});
  }

  public cancelSubscription(id: number): Observable<IResponse<any>> {
    return this.apiService.post(UrlGenerator.generate(API_URLS_ADMIN.CANCEL_SUBSCRIPTION, {id}), {});
  }

  public deleteSubscription(id: number): Observable<IResponse<any>> {
    return this.apiService.delete(UrlGenerator.generate(API_URLS_ADMIN.SUBSCRIPTION_DELETE, {id}), {});
  }

  public chargeback(id: number): Observable<IResponse<any>> {
    return this.apiService.post(UrlGenerator.generate(API_URLS_ADMIN.CHARGEBACK, {id}), {});
  }

  public getChargebackPdf(id: string): Observable<any> {
    return this.apiService.get(UrlGenerator.generate(API_URLS_ADMIN.CHARGEBACK, {id}),
      {responseType: 'blob', observe: 'response'});
  }

  private resolveFilterSelectOptions(filtersOptionsList, filterType: PUBLISHER_FILTERS): IUnitKeyValue[] {
    switch (filterType) {
      case PUBLISHER_FILTERS.SUBSCRIPTION_TYPE: {
        return filtersOptionsList.filter((item) => item !== 'SUBSCRIPTION').map((filterOptionData) => ({
          key: filterOptionData,
          value: this.localizationProvider.getByKey(this.publisherFiltersNamespace + filterOptionData.toLowerCase())
        }));
      }
      default: {
        return filtersOptionsList.map((filterOptionData) => ({
          key: filterOptionData,
          value: this.localizationProvider.getByKey(this.publisherFiltersNamespace + filterOptionData.toLowerCase())
        }));
      }
    }

  }

}

const DEFAULT_PAGING = {
  page: 0,
  itemsOnPage: 24
} as PagingParams;

const DEFAULT_SORT = [{
  field: 'REGISTRATION_DATE',
  order: 'DESC'
}] as SortParam[];
