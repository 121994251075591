import { Injectable } from '@angular/core';
import {API_URLS, ApiService, CURRENCY_SYMBOLS} from 'ui-elements';
import {Observable} from 'rxjs/internal/Observable';
import {API_URLS_ADMIN, UrlGenerator} from '../../const/api.urls';
import {pluck} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MultibitrateService {

  constructor(
    private apiService: ApiService
  ) { }

  public getPurchase(id: string): Observable<IMediaTransactionInfo> {
    return this.apiService.get(UrlGenerator.generate(API_URLS_ADMIN.PUBLISHER_PURCHASE, {id}))
      .pipe(pluck('results', 'data'));
  }

  public getMedia(id: string): Observable<any> {
    return this.apiService.get(UrlGenerator.generate(API_URLS_ADMIN.MEDIA_PURCHASE, {id}))
      .pipe(pluck('results', 'data'));
  }
}

export interface IMediaTransactionInfo {
  accountId: string;
  anonymous: boolean;
  billNumber: string;
  created: boolean;
  currency: CURRENCY_SYMBOLS;
  email: string;
  feesVat: string;
  firstName: string;
  hasPayout: boolean;
  id: number;
  lastName: string;
  ownedId: string;
  payoutAmount: number;
  priceVat: number;
  productId: string;
  productInfo: any;
  purchaseType: string;
  status: string;
  streamdustFee: number;
  totalCost: number;
  transactionFee: number;
  transactionId: string;
  vatPercent: number;
  withdrawalAmount: number;
}
