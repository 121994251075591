import { Component, OnInit } from '@angular/core';
import {ApiService} from 'ui-elements';
import {API_URLS_ADMIN} from '../../const/api.urls';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../utils/custom-validators.util';
import {INPUT_TYPES} from 'ui-elements';
import {AuthService} from '../../services/auth/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
  public INPUT_TYPES = INPUT_TYPES;
  public loginForm: FormGroup = this.fb.group({
    login: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public login(): void {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.invalid) {
      return;
    }
    this.authService.login(this.loginForm.value)
      .subscribe((res) => {
        this.router.navigate(['/publisher']);
      });
  }

}
