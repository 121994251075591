import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewsComponent } from './views.component';
import {SharedModule} from '../../modules/shared.module';

@NgModule({
  declarations: [ViewsComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class ViewsModule { }
