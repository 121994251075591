import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InvoicesComponent} from './invoices.component';
import {SharedModule} from '../../modules/shared.module';

@NgModule({
  declarations: [InvoicesComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class InvoicesModule {
}
