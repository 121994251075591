import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {IListFilterItem, ITableConfig} from 'ui-elements';
import {AuthService} from '../../services/auth/auth.service';
import {AutoDestroyService} from 'ui-elements';
import {IDropdownConfig} from '../../models/types';
import {tap} from 'rxjs/operators';
import {PayoutsService} from '../../services/payouts/payouts.service';
import {TableComponent} from 'ui-elements';
import {Router} from '@angular/router';

@Component({
  selector: 'app-payouts',
  templateUrl: './payouts.component.html',
  styleUrls: ['./payouts.component.sass'],
  providers: [AutoDestroyService, PayoutsService]
})
export class PayoutsComponent implements OnInit {
  @ViewChild('tableComponent', {static: false}) public table: TableComponent;
  @ViewChild('idTmpl', {static: true}) public idTmpl: TemplateRef<any>;
  @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
  @ViewChild('totalTmpl', {static: true}) public totalTmpl: TemplateRef<any>;
  @ViewChild('actualSumTmpl', {static: true}) public actualSumTmpl: TemplateRef<any>;
  @ViewChild('dateTmpl', {static: true}) public dateTmpl: TemplateRef<any>;
  @ViewChild('firstNameTmpl', {static: true}) public firstNameTmpl: TemplateRef<any>;
  @ViewChild('lastNameTmpl', {static: true}) public lastNameTmpl: TemplateRef<any>;
  @ViewChild('companyTmpl', {static: true}) public companyTmpl: TemplateRef<any>;
  @ViewChild('countryTmpl', {static: true}) public countryTmpl: TemplateRef<any>;
  @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
  public tableConfig: ITableConfig<any>;
  public searchTerm: string;
  public actions: IDropdownConfig;

  constructor(
    private authService: AuthService,
    private destroy$: AutoDestroyService,
    private payoutsService: PayoutsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.tableConfig = {
      dataField: 'data',
      matPaginator: true,
      filtersWidth100: true,
      fullHeight: true,
      searchFn: (sortParams, pagingParams) => {
        return this.payoutsService.getPayouts(this.searchTerm, sortParams, pagingParams)
          .pipe(tap((res) => {
            const currentMonth = new Date().getMonth();
            res.results.data.items.forEach((item) => {
              if (new Date(item.unpaidPayoutDate).getMonth() === currentMonth) {
                item.unpaidPayoutDate = null;
              }
            });
            this.mapToActions(res.results.data.items);
          }));
      },
      columns: [
        {
          name: 'Aktion',
          tmpl: this.actionsTmpl,
          class: 'dropdown-actions'
        },
        {
          name: 'Kunden ID',
          tmpl: this.idTmpl,
          sortField: 'PUBLISHER_ID',
          class: 'left no-white-space'
        },
        {
          name: 'Status',
          tmpl: this.statusTmpl,
          class: 'center',
          sortField: 'PAYOUT_STATUS'
        },
        {
          name: 'Summe Insgesamt',
          tmpl: this.totalTmpl,
          class: 'right',
          sortField: 'INCOME_AMOUNT'
        },
        {
          name: 'Aktuelle Pay Out Summe',
          tmpl: this.actualSumTmpl,
          class: 'right',
          sortField: 'PAYED_OUT_LEFT'
        },
        {
          name: 'Last unpaid payout',
          tmpl: this.dateTmpl,
          class: 'left no-white-space',
          sortField: 'UNPAID_PAYOUT_DATE'
        },
        {
          name: 'Name',
          tmpl: this.firstNameTmpl,
          sortField: 'FIRST_NAME',
          class: 'left'
        },
        {
          name: 'Vorname',
          tmpl: this.lastNameTmpl,
          sortField: 'LAST_NAME',
          class: 'left'
        },
        {
          name: 'Firm',
          tmpl: this.companyTmpl,
          sortField: 'COMPANY',
          class: 'left'
        },
        {
          name: 'Land',
          tmpl: this.countryTmpl,
          sortField: 'COUNTRY',
          class: 'left'
        }
      ]

    };
  }

  private mapToActions(data: any): void {
    this.actions = {};
    data.forEach((payout) => {
      this.actions[payout.publisherId] = [
        {
          key: 'Login to account',
          value: 'Login to account',
          callback: () => {
            this.authService.loginToAccount(payout.publisherId);
          }
        },
        {
          key: 'Pay Out details',
          value: 'Pay Out details',
          callback: () => {
            this.router.navigate(['payouts/' + payout.publisherId]);
          }
        }
      ];
    });
  }

  public search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.table.resetTableData();
    this.table.refreshData({});
  }

}
