<div class="card">
  <div class="card-header bg-white pb-0">
    <h2 class="title-level-3">{{publisher?.user?.firstName}}  {{publisher?.user?.lastName}}</h2>
    <ano-button (click)="back()" [config]="{
    type: 'button',
    classList: 'cursor-pointer width110px',
    styleType: 'primary'}"><i class="icon icon-back-arrow text-white cursor-pointer"></i> Zurück</ano-button>
  </div>
  <div class="card-body bg-white">
    <div class="row">
      <div class="col-5">
        <ano-form *ngIf="form"
                  [forcedViewMode]="true"
                  [form]="form"
                  [fieldsConfig]="leftConfig"
                  [withoutSave]="true" [materialForm]="true" [isLightForm]="true"
        >
        </ano-form>
      </div>
      <div class="col-5">
        <ano-form *ngIf="form"
                  [forcedViewMode]="true"
                  [form]="form"
                  [fieldsConfig]="rightConfig"
                  [withoutSave]="true" [materialForm]="true" [isLightForm]="true"
        >
        </ano-form>
        <div class="row">
          <div class="col-5">
            <label class="form-control-label"><b>Rechnung wurde erhalten</b></label>
          </div>
          <div class="col-2">
            <form [formGroup]="activateForm">
              <mat-slide-toggle [formControlName]="'active'" class="video-activation-toggle"></mat-slide-toggle>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="details-actions">
              <ano-button (click)="back()" [config]="{
                type: 'button',
                classList: 'cursor-pointer full-width',
                styleType: 'primary'}"><i class="icon icon-admin-eye text-white cursor-pointer"></i> Show PDF</ano-button>
              <ano-button (click)="back()" [config]="{
                type: 'button',
                classList: 'cursor-pointer full-width',
                styleType: 'primary'}"><i class="icon icon-download text-white cursor-pointer"></i> Download PDF</ano-button>
              <ano-button (click)="back()" [config]="{
                type: 'button',
                classList: 'cursor-pointer full-width',
                styleType: 'success'}"><i class="icon icon-telegram-round text-white cursor-pointer"></i> Send PDF</ano-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
