import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, tap, timeout} from 'rxjs/operators';
import {Router} from '@angular/router';
import {SnackBarService} from 'ui-elements';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private snackBarService: SnackBarService,
        private router: Router
    ) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const selectOneUrl = (element) => req.url.includes(element);
        return next.handle(req).pipe(
            tap(
                (response) => {
                  if (response instanceof HttpResponse && response.body) {
                    if (response.body.success === false && response.status !== 200) {
                      this.snackBarService.openError('Failure');
                    }
                  }
                  return;
                }
            ),
            catchError((err) => {
                if (err.status === 500) {
                    this.snackBarService.openError('Failure');
                }
                return throwError(err);
            })
        );
    }
}
