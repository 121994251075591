import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {ApiService, IMediaTimerange, IPagedResponse, IResponse, PagingParams, SortParam} from 'ui-elements';
import {IPayout, IPayoutAggregation} from '../../models/payouts.model';
import {API_URLS_ADMIN, UrlGenerator} from '../../const/api.urls';
import {map, pluck} from 'rxjs/operators';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PayoutsService {

  constructor(
    private apiService: ApiService
  ) { }

  public getPayouts(
    searchTerm: string = '',
    sort: SortParam[] = DEFAULT_SORT,
    paging: PagingParams = DEFAULT_PAGING,
    timeRange = null
  ): Observable<IPagedResponse<IPayout>> {
    return this.apiService.post(API_URLS_ADMIN.PAYOUTS_LIST, {
      searchTerm,
      paging,
      sort,
      timeRange
    });
  }

  public getPayoutHistory(
    publisherId: string,
    searchTerm: string = '',
    sort: SortParam[] = DEFAULT_SORT_PUBLISHER,
    paging: PagingParams = DEFAULT_PAGING,
    timeRange = null
  ): Observable<IPagedResponse<IPayoutAggregation>> {
    return this.apiService.post(UrlGenerator.generate(API_URLS_ADMIN.PUBLISHER_PAYOUTS, {publisherId}), {
      searchTerm,
      sort,
      paging,
      timeRange
    });
  }

  public getPayout(id: string): Observable<IPayoutAggregation> {
    return this.apiService.get(UrlGenerator.generate(API_URLS_ADMIN.PUBLISHER_PAYOUT, {id}))
      .pipe(pluck('results', 'data'));
  }

  public downloadPayoutPDF(purchaseId: string): Observable<any> {
    return this.apiService.get(UrlGenerator.generate(API_URLS_ADMIN.PAYOUT_PDF, {purchaseId}),
      {responseType: 'blob', observe: 'response'});
  }

  public sendPdf(id: string): Observable<IResponse> {
    return this.apiService.post(UrlGenerator.generate(API_URLS_ADMIN.SEND_PAYOUT_PDF, {id}), {});
  }

  public getTimerange(publisherId: string): Observable<IMediaTimerange[]> {
    const firstDateTimeRange = {
      year: 2020,
      month: 8,
      timeRange: {
        start: 1601499600000,
        end: 1604181599999
      }
    };
    const currentDate = new Date();
    const diffYears = currentDate.getFullYear() - firstDateTimeRange.year;
    let ranges: IMediaTimerange[] = [];
    for (let i = 0; i <= diffYears; i++) {
      const year = firstDateTimeRange.year + i;
      if (year === firstDateTimeRange.year) {
        ranges = ranges.concat(this.fillMonths(firstDateTimeRange.month, 11, year));
        // ranges.push(this.getYearRange(year));
      }
      if (year === currentDate.getFullYear()) {
        ranges = ranges.concat(this.fillMonths(firstDateTimeRange.year === year  ? firstDateTimeRange.month : 0, currentDate.getMonth(), year));
        // ranges.push(this.getYearRange(year));
      }
      if (year !== firstDateTimeRange.year && year !== currentDate.getFullYear()) {
        ranges = ranges.concat(this.fillMonths(0, 11, year));
        // ranges.push(this.getYearRange(year));
      }
    }
    return of(ranges.reverse());

  }

  public fillMonths(from: number = 0, to: number = 11, year: number): IMediaTimerange[] {
    const res = [];
    for (let i = from; i <= to; i++) {
      const startDate = moment([year, i]);
      const endDate = moment(startDate).endOf('month');

      const timeRange: IMediaTimerange = {
        year,
        month: moment(new Date(year, i, 1)).locale('DE').format('MMMM'),
        timeRange: {
          start: startDate.toDate().getTime(),
          end: endDate.toDate().getTime()
        }
      };
      res.push(timeRange);
    }
    return res;
  }

  public sendPayout(id: number, doPayout: boolean): Observable<IResponse> {
    return this.apiService.post(UrlGenerator.generate(API_URLS_ADMIN.SEND_PAYOUT, {id}), {doPayout});
  }

  public togglePayouts(publisherId: string, doPayout: boolean): Observable<IResponse> {
    return this.apiService.post(UrlGenerator.generate(API_URLS_ADMIN.TOGGLE_PUBLISHER_PAYOUTS, {publisherId}), {doPayout})
  }
 }

const DEFAULT_PAGING = {
  page: 0,
  itemsOnPage: 24
} as PagingParams;

const DEFAULT_SORT = [{
  field: 'PUBLISHER_ID',
  order: 'ASC'
}] as SortParam[];

const DEFAULT_SORT_PUBLISHER = [{
  field: 'PRODUCT_ID',
  order: 'DESC'
}] as SortParam[];

