<div class="card">
  <div class="card-body bg-white">
    <ano-table #tableComponent
               [withSearch]="true"
               [tableConfig]="tableConfig"
               (search$)="search($event)">
      <!--               [filters]="filters"-->
      <!--               (search$)="search($event)"-->
      <!--               (filter$)="onFilter($event)"-->
      <!--    >-->
    </ano-table>
  </div>
</div>

<ng-template #idTmpl let-data="rowData">
  <span>{{data?.publisherId | stringCut}}</span>
</ng-template>

<ng-template #statusTmpl let-data="rowData">
  <i class="icon-{{ data?.payoutDone ? 'check' : 'cross'}} text-primary"></i>
</ng-template>
<ng-template #totalTmpl let-data="rowData">
  <div class="long-text">
    <span>{{{amount: data?.income, currency: 'EUR'} | currency}}</span>
  </div>
</ng-template>

<ng-template #actualSumTmpl let-data="rowData">
  <div class="long-text">
  <span>{{{amount: data?.payoutLeft, currency: 'EUR'} | currency}}</span>
  </div>
</ng-template>

<!--<ng-template #dateTmpl let-data="rowData">-->
<!--</ng-template>-->

<ng-template #dateTmpl let-data="rowData">
  <span>{{data?.unpaidPayoutDate ? (data?.unpaidPayoutDate | dateFormat: 'MMM YYYY') : '-'}}</span>
</ng-template>


<ng-template #firstNameTmpl let-data="rowData">
  <span>{{data?.user?.firstName}}</span>
</ng-template>

<ng-template #lastNameTmpl let-data="rowData">
  <span>{{data?.user?.lastName}}</span>
</ng-template>

<ng-template #companyTmpl let-data="rowData">
  <div class="long-text">
    <span>{{data?.user?.companyName}}</span>
  </div>
</ng-template>

<ng-template #countryTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{(data.user.countryCode | country) || '-'}}
        </span>
  </div>
</ng-template>

<ng-template #actionsTmpl let-data="rowData">
  <div class="table-actions-list">
    <app-action-dropdown *ngIf="actions" [values]="actions[data.publisherId]" [placeholder]="'Select action'"></app-action-dropdown>
  </div>
</ng-template>

