<div class="card">
  <div class="card-body bg-white">
    <div class="login-container">
      <h1 class="title">Login</h1>
      <form [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="form-inputs">
          <div class="form-group">
            <ano-input
              formControlName="login"
              [invalid]="loginForm.get('login').invalid"
              [errors]="loginForm.get('login').errors"
              [isTouched]="loginForm.get('login').touched"
              [isEditMode]="true"
              [config]="{ inputType: INPUT_TYPES.INPUT, type: 'text', placeholder: 'Login', isRequired: true }"></ano-input>
          </div>
          <div class="form-group">
            <ano-input
              formControlName="password"
              [invalid]="loginForm.get('password').invalid"
              [errors]="loginForm.get('password').errors"
              [isTouched]="loginForm.get('password').touched"
              [isEditMode]="true"
              [config]="{ inputType: INPUT_TYPES.PASSWORD, type: 'password', placeholder: 'Password', isRequired: true }"></ano-input>
          </div>
        </div>
        <div class="action-buttons">
          <button type="submit" class="btn btn-primary btn-sm btn-block">
            {{'Login'}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
