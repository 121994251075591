import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {PublisherComponent} from './pages/publisher/publisher.component';
import {ShouldNotBeAuthorizedGuard} from './guards/should-not-be-authorized/should-not-be-authorized.guard';
import {AuthGuard} from './guards/auth/auth.guard';
import {StreamsComponent} from './pages/streams/streams.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {VideosComponent} from './pages/videos/videos.component';
import {InvoicesComponent} from './pages/invoices/invoices.component';
import {PublisherDetailsComponent} from './pages/publisher/components/publisher-details/publisher-details.component';
import {SubscriptionsComponent} from './pages/subscriptions/subscriptions.component';
import {SubscriptionsListComponent} from './pages/subscriptions/components/subscriptions-list/subscriptions-list.component';
import {SubscriptionDetailsComponent} from './pages/subscriptions/components/subscription-details/subscription-details.component';
import {PAGE_TYPE} from './models/publisher.model';
import {ViewsComponent} from './pages/views/views.component';
import {PayoutsComponent} from './pages/payouts/payouts.component';
import {PayoutDetailsComponent} from './pages/payouts/components/payout-details/payout-details.component';
import {ACCOUNT_PAGE_TYPE} from './services/user/user.service';
import {PayoutHistoryComponent} from './pages/payouts/components/payout-history/payout-history.component';
import {MultibitrateDetailsComponent} from './components/multibitrate-details/multibitrate-details.component';
import {MediaTransactionsComponent} from './pages/transactions/media-transactions/media-transactions.component';
import {PublisherTransactionsComponent} from './pages/transactions/publisher-transactions/publisher-transactions.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [ShouldNotBeAuthorizedGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Master Dashboard'
    }
  },
  {
    path: 'media/:id/transactions',
    component: MediaTransactionsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Media Transactions'
    }
  },
  {
    path: 'publisher',
    canActivate: [AuthGuard],
    data: {
      title: 'Publisher'
    },
    children: [
      {
        path: '',
        component: PublisherComponent,
        data: {
          userType: ACCOUNT_PAGE_TYPE.PUBLISHER
        }
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: PublisherDetailsComponent,
            data: {
              userType: ACCOUNT_PAGE_TYPE.PUBLISHER
            }
          },
          {
            path: 'details/:subscriptionId',
            component: SubscriptionDetailsComponent,
            data: {pageType: PAGE_TYPE.PUBLISHER}
          },
          {
            path: 'multibitrate/details/:mbId',
            component: MultibitrateDetailsComponent,
            data: {pageType: PAGE_TYPE.PUBLISHER}
          },
          // {
          //   path: 'transactions',
          //   component: PublisherTransactionsComponent,
          //   data: {
          //     title: 'Publisher Transactions'
          //   }
          // }
        ]
      }
    ]
  },

  {
    path: 'publisher/:id/transactions',
    component: PublisherTransactionsComponent,
    data: {
      title: 'Publisher Transactions'
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'viewer',
    canActivate: [AuthGuard],
    data: {
      title: 'Viewer'
    },
    children: [
      {
        path: '',
        component: PublisherComponent,
        data: {
          userType: ACCOUNT_PAGE_TYPE.VIEWER
        }
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: PublisherDetailsComponent,
            data: {
              userType: ACCOUNT_PAGE_TYPE.VIEWER
            }
          },
          {
            path: 'details/:subscriptionId',
            component: SubscriptionDetailsComponent,
            data: {pageType: PAGE_TYPE.PUBLISHER}
          }
        ]
      }
    ]
  },
  {
    path: 'livestreams',
    component: StreamsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Livestreams'
    },
  },
  {
    path: 'videos',
    component: VideosComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Videos'
    },
  },
  {
    path: 'invoices',
    component: InvoicesComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Invoices'
    },
  },
  {
    path: 'subscriptions',
    canActivate: [AuthGuard],
    data: {
      title: 'Abonnenten'
    },
    children: [
      {
        path: '',
        component: SubscriptionsComponent,
      },
      {
        path: 'publisher-details/:id',
        component: PublisherDetailsComponent,
        data: {
          userType: ACCOUNT_PAGE_TYPE.SUBSCRIPTIONS
        }
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: SubscriptionsListComponent
          },
          {
            path: 'details/:subscriptionId',
            component: SubscriptionDetailsComponent,
            data: {pageType: PAGE_TYPE.SUBSCRIPTION}
          },
          {
            path: 'multibitrate/details/:mbId',
            component: MultibitrateDetailsComponent,
            data: {pageType: PAGE_TYPE.SUBSCRIPTION}
          }
        ]
      }
    ]
  },
  {
    path: 'views',
    canActivate: [AuthGuard],
    data: {
      title: 'Zuschauer',
      userType: ACCOUNT_PAGE_TYPE.VIEWS
    },
    children: [
      {
        path: '',
        component: ViewsComponent
      },
      {
        path: 'publisher/:id',
        component: PublisherDetailsComponent
      }
    ]
  },
  {
    path: 'payouts',
    canActivate: [AuthGuard],
    data: {
      title: 'Pay Outs'
    },
    children: [
      {
        path: '',
        component: PayoutsComponent
      },
      {
        path: ':id',
        component: PayoutHistoryComponent,
      },
      {
        path: ':id/payout/:payoutId',
        component: PayoutDetailsComponent
      },
    ]
  },
  {
    path: '**',
    redirectTo:
      '/dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
