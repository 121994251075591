import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublisherComponent } from './publisher.component';
import {SharedModule} from '../../modules/shared.module';
import { PublisherDetailsComponent } from './components/publisher-details/publisher-details.component';
import { BillDetailsComponent } from './components/bill-details/bill-details.component';

@NgModule({
  declarations: [PublisherComponent, PublisherDetailsComponent, BillDetailsComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
  ]
})
export class PublisherModule { }
