import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AutoDestroyService, getFileNameFromResponseContentDisposition, IBill, ITableConfig, TableComponent} from 'ui-elements';
import {take, takeUntil, tap} from 'rxjs/operators';
import {BillsService} from '../../../services/bills/bills.service';
import {IDropdownConfig} from '../../../models/types';
import {saveAs} from 'file-saver/FileSaver';
import {MediaService} from '../../../services/media/media.service';
import {PublisherService} from '../../../services/publisher/publisher.service';
import {ACCOUNT_STATUS, IPublisher} from '../../../models/publisher.model';
import {AuthService} from '../../../services/auth/auth.service';

@Component({
  selector: 'app-media-transactions',
  templateUrl: './media-transactions.component.html',
  styleUrls: ['./media-transactions.component.sass'],
  providers: [AutoDestroyService, BillsService]
})
export class MediaTransactionsComponent implements OnInit {
  ACCOUNT_STATUS = ACCOUNT_STATUS;
  @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
  @ViewChild('dateTmpl', {static: true}) public dateTmpl: TemplateRef<any>;
  @ViewChild('billNumberTmpl', {static: true}) public billNumberTmpl: TemplateRef<any>;
  @ViewChild('amountTmpl', {static: true}) public amountTmpl: TemplateRef<any>;
  @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
  @ViewChild('typeTmpl', {static: true}) public typeTmpl: TemplateRef<any>;
  @ViewChild('tableComponent', {static: false}) public table: TableComponent;
  public mediaId: string;
  public publisher: IPublisher;
  public searchTerm: string;
  public tableConfig: ITableConfig<IBill>;
  public actions: IDropdownConfig;
  public actionLoaders: any;
  public media: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private destroy$: AutoDestroyService,
    private billsService: BillsService,
    private mediaService: MediaService,
    private router: Router,
    private publisherService: PublisherService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(takeUntil(this.destroy$))
      .subscribe(({id}) => {
        this.mediaId = id;
        this.mediaService.getMedia(this.mediaId).pipe(take(1))
          .subscribe((res) => {
            this.media = res.results.data;
            this.publisherService.getPublisher(this.media.owner).pipe(take(1))
              .subscribe((publisher) => {
                this.publisher = publisher;
              });
          });
        this.tableConfig = {
          dataField: 'data',
          matPaginator: true,
          fullHeight: true,
          searchFn: (sortParams, pagingParams) => {
            return this.billsService.getTransactions(this.searchTerm, [{field: 'MEDIA_ID', value: this.mediaId}], pagingParams, sortParams)
              .pipe(tap((res) => this.mapToActions(res.results.data.items)));
          },
          columns: [
            {
              name: 'actions',
              tmpl: this.actionsTmpl,
              // class: 'dropdown-actions'
            },
            {
              name: 'Type',
              tmpl: this.typeTmpl,
              class: 'left no-white-space'
            },
            {
              name: 'Status',
              tmpl: this.statusTmpl,
              class: 'left'
            },
            {
              name: 'Date',
              tmpl: this.dateTmpl,
              class: 'left'
            },
            {
              name: 'Transaction number',
              tmpl: this.billNumberTmpl,
              sortField: 'ID',
              class: 'left no-white-space'
            },
            {
              name: 'Amount',
              tmpl: this.amountTmpl,
              class: 'left',
            }
          ]

        };

      });
  }

  public search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.table.resetTableData();
    this.table.refreshData({});
  }

  public goBack(): void {
    const path = this.media.productType === 'STREAM' ? 'livestreams' : 'videos';
    this.router.navigate([path]);
  }

  public loginAs(): void {
    this.authService.loginToAccount(this.publisher.profile.ownerId, (this.media.productType === 'STREAM' ? 'streams/' : 'vod/') + this.mediaId + '/info');
  }

  private mapToActions(bills: IBill[]): void {
    this.actions = {};
    this.actionLoaders = {};
    bills.forEach((bill) => {
      this.actions[bill.purchaseId] = [
        {
          key: 'Download',
          value: 'Download',
          callback: () => {
            this.actionLoaders[bill?.purchaseId] = true;
            this.billsService.downloadPdf(bill?.purchaseId.toString())
              .pipe(takeUntil(this.destroy$))
              .subscribe((res) => {
                this.actionLoaders[bill?.purchaseId] = false;
                saveAs(
                  res.body,
                  getFileNameFromResponseContentDisposition(res)
                );
              }, () => this.actionLoaders[bill?.purchaseId] = false);
          }
        },
        {
          key: 'Zeige PDF',
          value: 'Zeige PDF',
          callback: () => {
            this.actionLoaders[bill?.purchaseId] = true;
            this.billsService.downloadPdf(bill?.purchaseId.toString())
              .pipe(takeUntil(this.destroy$))
              .subscribe((res) => {
                this.actionLoaders[bill?.purchaseId] = false;
                const fileURL = URL.createObjectURL(new Blob([res.body], {type: 'application/pdf'}));
                window.open(fileURL);
              }, () => this.actionLoaders[bill?.purchaseId] = false);
          }
        },
        {
          key: 'An Zuschauer PDF',
          value: 'An Zuschauer PDF',
          callback: () => {
            this.actionLoaders[bill?.purchaseId] = true;
            this.billsService.sendPdf(bill?.purchaseId.toString())
              .pipe(takeUntil(this.destroy$))
              .subscribe((res) => {
                this.actionLoaders[bill?.purchaseId] = false;
              }, () => this.actionLoaders[bill?.purchaseId] = false);
          }
        }
      ];
    });
  }

}
