import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {IListFilterItem, PagingParams, SortParam, IPagedResponse, IBill, ITimerange, IResponse} from 'ui-elements';
import { ApiService } from 'ui-elements';
import {API_URLS_ADMIN, UrlGenerator} from '../../const/api.urls';

@Injectable()
export class BillsService {

  constructor(private apiService: ApiService) {}

  public getBills(
    id: string,
    searchTerm: string = '',
    filters: IListFilterItem[],
    paging: PagingParams = DEFAULT_PAGING,
    sort: SortParam[] = DEFAULT_BILLS_SORT,
    timeRange: ITimerange = null): Observable<IPagedResponse<IBill>> {
      return this.apiService.post(UrlGenerator.generate(API_URLS_ADMIN.PUBLISHER_BILLS, {id}), {
          filters,
          paging,
          sort,
          searchTerm,
        timeRange
      });
  }

    // public downloadPDFPublisher(id: number): Observable<any> {
    //     return this.apiService.get(UrlGenerator.generate(API_URLS_ADMIN.BILLS_PDF_PUBLISER, {id}),
    //         {responseType: 'blob', observe: 'response'});
    // }

  public getTransactions(
    searchTerm: string = '',
    filters: IListFilterItem[],
    paging: PagingParams = DEFAULT_PAGING,
    sort: SortParam[] = DEFAULT_BILLS_SORT,
    timeRange: ITimerange = null): Observable<IPagedResponse<IBill>> {
    return this.apiService.post(API_URLS_ADMIN.BILLS, {
      filters,
      paging,
      sort,
      searchTerm,
      timeRange
    });
  }

  public downloadPdf(id: string): Observable<any> {
    return this.apiService.get(UrlGenerator.generate(API_URLS_ADMIN.DOWNLOAD_PURCHASE_PDF, {id}),
      {responseType: 'blob', observe: 'response'});
  }

  public sendPdf(id: string): Observable<IResponse> {
    return this.apiService.post(UrlGenerator.generate(API_URLS_ADMIN.SEND_PDF, {id}), {});
  }
}

const DEFAULT_BILLS_SORT = [{
    field: 'DATE',
    order: 'DESC'
}] as SortParam[];

const DEFAULT_PAGING = {
    page: 0,
    itemsOnPage: 10
} as PagingParams;
