<div class="card">
  <div class="card-header bg-white pb-0">
    <div class="row">
      <div class="col">
        <h2 class="title-level-3">Pay Out Detail</h2>
        <ano-button (click)="loginAs()" [config]="{
                    type: 'button',
                    classList: 'cursor-pointer padding-bottom-10',
                    styleType: 'link'}" [isDisabled]="publisher?.accountStatuses.includes(ACCOUNT_STATUS.DEACTIVATED)"><i class="icon icon-user"></i> Login to account
        </ano-button>
      </div>
    </div>
    <ano-button (click)="back()" [config]="{
    type: 'button',
    classList: 'cursor-pointer width110px',
    styleType: 'primary'}"><i class="icon icon-back-arrow text-white cursor-pointer"></i> Zurück
    </ano-button>
  </div>
  <div class="card-body bg-white">
    <div class="row">
      <div class="col-5">
        <ano-form *ngIf="form"
                  [forcedViewMode]="true"
                  [form]="form"
                  [fieldsConfig]="leftConfig"
                  [withoutSave]="true" [materialForm]="true" [isLightForm]="true"
        >
        </ano-form>
      </div>
      <div class="col-5">
        <ano-form id="right-form" *ngIf="form"
                  [forcedViewMode]="true"
                  [form]="form"
                  [fieldsConfig]="rightConfig"
                  [withoutSave]="true" [materialForm]="true" [isLightForm]="true"
        >
        </ano-form>
        <div class="row">
          <div class="col-5">
            <label class="form-control-label"><b>Gutschrift wurde überwiesen</b></label>
          </div>
          <div class="col-2">
            <form [formGroup]="activateForm">
              <mat-slide-toggle *ngIf="payout" [formControlName]="'active'" class="video-activation-toggle"></mat-slide-toggle>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="details-actions">
              <ano-button (click)="viewPdf()" [config]="{
                type: 'button',
                classList: 'cursor-pointer full-width',
                styleType: 'primary'}">
                <i *ngIf="viewDone" class="icon icon-check text-success cursor-pointer"></i>
                <i class="icon icon-admin-eye text-white cursor-pointer"></i>
                <ano-loader [isOpen]="viewLoading"></ano-loader>
                Show PDF
              </ano-button>
              <ano-button (click)="downloadPdf()" [config]="{
                type: 'button',
                classList: 'cursor-pointer full-width',
                styleType: 'primary'}">
                <i *ngIf="downloadDone" class="icon icon-check text-success cursor-pointer"></i>
                <i class="icon icon-download text-white cursor-pointer">
                </i>
                <ano-loader [isOpen]="download"></ano-loader>
                Download PDF
              </ano-button>
              <ano-button (click)="sendPdf()" [config]="{
                type: 'button',
                classList: 'cursor-pointer full-width',
                styleType: 'success'}">
                <i *ngIf="sendDone" class="icon icon-check text-white cursor-pointer"></i>
                <i class="icon icon-telegram-round text-white cursor-pointer"></i>
                <ano-loader [isOpen]="sendLoading"></ano-loader>
                Send PDF
              </ano-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ano-loader [isOpen]="!payout"></ano-loader>
</div>
