<div class="card">
  <div class="card-header bg-white pb-0">
    <div class="row">
      <div class="col">
        <h2 class="title-level-3">Pay Out Detail</h2>
        <ano-button (click)="loginAs()" [config]="{
                    type: 'button',
                    classList: 'cursor-pointer padding-bottom-10',
                    styleType: 'link'}" [isDisabled]="publisher?.accountStatuses.includes(ACCOUNT_STATUS.DEACTIVATED)"><i class="icon icon-user"></i> Login to account
        </ano-button>
      </div>
    </div>
    <ano-button (click)="goToPayouts()" [config]="{
    type: 'button',
    classList: 'cursor-pointer width110px',
    styleType: 'primary'}"><i class="icon icon-back-arrow text-white cursor-pointer"></i> Zurück
    </ano-button>
  </div>
  <div class="card-body bg-white">
    <div class="row">
      <div class="col-5">
        <ano-form *ngIf="form"
                  [fieldsConfig]="leftConfig"
                  [forcedViewMode]="true"
                  [form]="form"
                  [isLightForm]="true" [materialForm]="true" [withoutSave]="true"
        >
        </ano-form>
      </div>
      <div class="col-5">
        <ano-form *ngIf="form" [fieldsConfig]="rightConfig"
                  [forcedViewMode]="true"
                  [form]="form"
                  [isLightForm]="true"
                  [materialForm]="true" [withoutSave]="true" id="right-form"
        >
        </ano-form>
        <div class="row">
          <div class="col-5">
            <label class="form-control-label"><b>Gutschrift wurde überwiesen</b></label>
          </div>
          <div class="col-2">
            <form [formGroup]="activateForm">
              <mat-slide-toggle [formControlName]="'active'" class="video-activation-toggle"></mat-slide-toggle>
            </form>
          </div>
        </div>
      </div>
    </div>
    <ano-table #tableComponent
               [withSearch]="true"
               [timeRangeConfig]="timeRangeConfig"
               (search$)="search($event)"
               (timeRangeFilter$)="timeRangeFilter($event)"
               [showDatepicker]="true"
               [tableConfig]="tableConfig">
      <!--               [filters]="filters"-->
<!--                     (search$)="search($event)"-->
      <!--               (filter$)="onFilter($event)"-->
      <!--    >-->
    </ano-table>
  </div>
</div>

<ng-template #idTmpl let-data="rowData">
</ng-template>

<ng-template #statusTmpl let-data="rowData">
  <i class="icon-{{ data?.payoutDone ? 'check' : 'cross'}} text-primary"></i>
</ng-template>

<ng-template #titleTmpl let-data="rowData">
  <span>{{data?.title}}</span>
</ng-template>

<ng-template #priceTmpl let-data="rowData">
  <span>{{{amount: data?.price, currency: 'EUR'} | currency}}</span>
</ng-template>
<ng-template #productTypeTmpl let-data="rowData">
  <span>{{('stream.revenue.' + data.productType) | localize}}</span>
</ng-template>

<ng-template #dateTmpl let-data="rowData">
<!--  <span>{{data?.payoutDate | dateFormat: 'DD MMM YYYY'}}</span>-->
  <span style="display: block">{{data?.payoutDate | dateFormat: 'MMM YYYY'}}</span>
<!--  <span style="display: block; margin-top: -10px">{{data?.payoutDate | lastDay}}</span>-->
</ng-template>

<ng-template #soldAmountTmpl let-data="rowData">
  <span>{{data?.soldAmount}}</span>
</ng-template>

<ng-template #openPayoutTmpl let-data="rowData">
  <span>{{{amount: data?.payoutLeft, currency: 'EUR'} | currency }}</span>
</ng-template>

<ng-template #transactionTmpl let-data="rowData">
  <span>{{data?.creditNotId}}</span>
</ng-template>

<ng-template #actionsTmpl let-data="rowData">
  <div class="table-actions-list">
    <app-action-dropdown *ngIf="actions" [placeholder]="'Select action'" [values]="actions[data.id]"></app-action-dropdown>
    <ano-loader [isOpen]="actionLoaders[data.id]"></ano-loader>
  </div>
<!--  <span (click)="goToPayout(data?.id)"><i class="icon icon-login text-primary cursor-pointer"></i></span>-->
</ng-template>

<ng-template #statusToggle let-data="rowData">
  <form [formGroup]="this.forms[data.id]" class="border-form">
    <ano-input (toggle$)="toggle(data)"
               [config]="{
                                  inputType: INPUT_TYPES.CHECKBOX,
                                  classList: 'form-control-lg'}"
               [formControlName]="data.id"
               [isEditMode]="true"
    ></ano-input>
  </form>
</ng-template>

