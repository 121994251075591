import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  AutoDestroyService,
  IListFilterItem,
  ITableConfig,
  ITableFilters,
  IUnitKeyValue,
  PUBLISHER_FILTERS,
  TableComponent,
  INPUT_TYPES
} from 'ui-elements';
import {ACCOUNT_STATUS, IPublisher, PUBLISHER_ACTIVE_STATE} from '../../models/publisher.model';
import {PublisherService} from '../../services/publisher/publisher.service';
import {take, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';
import {IDropdownConfig} from '../../models/types';
import {MatDialog} from '@angular/material/dialog';
import {ACCOUNT_PAGE_TYPE, UserService} from '../../services/user/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.sass'],
  providers: [AutoDestroyService, PublisherService]
})
export class PublisherComponent implements OnInit {
  ACCOUNT_PAGE_TYPE = ACCOUNT_PAGE_TYPE;
  public PUBLISHER_ACTIVE_STATE = PUBLISHER_ACTIVE_STATE;
  public userType: ACCOUNT_PAGE_TYPE;
  public confirmTitle: CONFIRM_TITLE;
  public confirmDescription: CONFIRM_DESCRIPTION;
  public ACCOUNT_STATUS = ACCOUNT_STATUS;
  @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;
  @ViewChild('tableComponent', {static: false}) public table: TableComponent;
  @ViewChild('idTmpl', {static: true}) public idTmpl: TemplateRef<any>;
  @ViewChild('firstNameTmpl', {static: true}) public firstNameTmpl: TemplateRef<any>;
  @ViewChild('lastNameTmpl', {static: true}) public lastNameTmpl: TemplateRef<any>;
  @ViewChild('companyTmpl', {static: true}) public companyTmpl: TemplateRef<any>;
  @ViewChild('subscriptionTmpl', {static: true}) public subscriptionTmpl: TemplateRef<any>;
  @ViewChild('countryTmpl', {static: true}) public countryTmpl: TemplateRef<any>;
  @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
  @ViewChild('emailTmpl', {static: true}) public emailTmpl: TemplateRef<any>;
  @ViewChild('onlineStatus', {static: true}) public onlineStatus: TemplateRef<any>;
  @ViewChild('activeTmpl', {static: true}) public activeTmpl: TemplateRef<any>;
  @ViewChild('regDateTmpl', {static: true}) public regDateTmpl: TemplateRef<any>;
  @ViewChild('storageTmpl', {static: true}) public storageTmpl: TemplateRef<any>;
  @ViewChild('endDateTmpl', {static: true}) public endDateTmpl: TemplateRef<any>;
  @ViewChild('currencyTmpl', {static: true}) public currencyTmpl: TemplateRef<any>;
  @ViewChild('emailConfirmedTmpl', {static: true}) public emailConfirmedTmpl: TemplateRef<any>;
  @ViewChild('resetPassword', {static: false}) resetPassword: TemplateRef<any>;

  public tableConfig: ITableConfig<IPublisher>;
  public searchTerm: string;
  public actions: IDropdownConfig;
  public testAction: IUnitKeyValue[];
  public filters: ITableFilters;
  public queryFilters: IListFilterItem[];

  public INPUT_TYPES = INPUT_TYPES;
  public newPasswordForm: FormGroup = this.fb.group({
    password: [''],
  });

  constructor(
    private publisherService: PublisherService,
    private destroy$: AutoDestroyService,
    private router: Router,
    private authService: AuthService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.data.pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.userType = params.userType;
        if (this.userType === ACCOUNT_PAGE_TYPE.PUBLISHER) {
          this.publisherService.getFilters({filterTypes: [PUBLISHER_FILTERS.PUBLISHER_TYPE, PUBLISHER_FILTERS.SUBSCRIPTION_TYPE]})
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
              this.filters = res;
            });
        }
        this.tableConfig = {
          dataField: 'data',
          matPaginator: true,
          filtersWidth100: this.userType !== ACCOUNT_PAGE_TYPE.VIEWER,
          fullHeight: true,
          highlight: (data) => {
            if (data.user.waitingApproval) {
              return {warning: true};
            }
            if (data.activeState === PUBLISHER_ACTIVE_STATE.DELETED) {
              return {deactivated: true};
            }
            if (data.user.accountStatuses.includes(ACCOUNT_STATUS.DEACTIVATED)) {
              return {deactivated: true};
            }

            if (data.user.accountStatuses.includes(ACCOUNT_STATUS.MARKED_FOR_DELETION)) {
              return {deactivated: true};
            }
          },
          searchFn: (sortParams, pagingParams) => {
            return this.userService.getUsersByType(this.userType, this.searchTerm, sortParams, pagingParams, this.queryFilters)
              .pipe(tap((res) => {
                this.mapToActions(res.results.data.items);
              }));
          },
          columns: [
            {
              name: 'actions',
              tmpl: this.actionsTmpl,
              class: 'dropdown-actions'
            },
            {
              name: 'AKTIV',
              tmpl: this.activeTmpl,
              sortField: 'ACTIVE_STATE',
              class: 'left'
            },
            {
              name: 'Kunden Id',
              tmpl: this.idTmpl,
              sortField: 'ID',
              class: 'left no-white-space'
            },
            {
              name: 'Subscription type',
              tmpl: this.subscriptionTmpl,
              class: 'left',
              sortField: 'PUBLISHER_TYPE'
            },
            {
              name: 'Speicher- Abo',
              tmpl: this.storageTmpl,
              class: 'left',
              hide: this.userType === ACCOUNT_PAGE_TYPE.VIEWER,
              sortField: 'HAS_STORAGE_SPACE_SUBSCRIPTION'
            },
            {
              name: 'Anmeldung',
              tmpl: this.regDateTmpl,
              class: 'left',
              sortField: 'REGISTRATION_DATE'
            },
            {
              name: 'Live',
              tmpl: this.onlineStatus,
              sortField: 'LIVE_STREAM',
              class: 'center'
            },
            {
              name: 'firstName',
              tmpl: this.firstNameTmpl,
              sortField: 'FIRST_NAME',
              class: 'left'
            },
            {
              name: 'lastName',
              tmpl: this.lastNameTmpl,
              sortField: 'LAST_NAME',
              class: 'left'
            },
            {
              name: 'email',
              tmpl: this.emailTmpl,
              sortField: 'EMAIL',
              class: 'left'
            },
            {
              name: 'email confirmed',
              tmpl: this.emailConfirmedTmpl,
              width: 200,
              class: 'left',
              hide: this.userType !== ACCOUNT_PAGE_TYPE.VIEWER
            },
            {
              name: 'firm',
              tmpl: this.companyTmpl,
              sortField: 'COMPANY',
              class: 'left'
            },
            {
              name: 'land',
              tmpl: this.countryTmpl,
              sortField: 'COUNTRY',
              class: 'left'
            },
            {
              name: 'currency',
              tmpl: this.currencyTmpl,
              sortField: 'CURRENCY',
              class: 'left'
            },
            {
              name: 'Vetragslaufzeit',
              tmpl: this.endDateTmpl,
              width: 200,
              class: 'left',
              hide: this.userType === ACCOUNT_PAGE_TYPE.VIEWER
            }
          ]

        };
        if (this.userType === ACCOUNT_PAGE_TYPE.VIEWER) {
          const subscriptionCol = this.tableConfig.columns.findIndex((col) => col.sortField === 'PUBLISHER_TYPE');
          this.tableConfig.columns.splice(subscriptionCol, 1);
          const onlineCol = this.tableConfig.columns.findIndex((col) => col.sortField === 'LIVE_STREAM');
          this.tableConfig.columns.splice(onlineCol, 1);
        }
      });
  }

  public search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.table.resetTableData();
    this.table.refreshData({});
  }

  public openPublisherDetails(id: string): void {
    this.router.navigate([`${this.userType}/${id}`]);
  }

  private mapToActions(res: IPublisher[]): void {
    this.actions = {};
    this.testAction = [{
      id: 'dddd',
      key: 'Login to account',
      value: 'Login to account',
      hide: false,
      callback: () => {
        // this.authService.loginToAccount(publisher.accountId, 'streams');
      }
    }];
    res.forEach((publisher) => {
      this.actions[publisher.accountId] = [
        {
          id: publisher.accountId,
          key: 'Login to account',
          value: 'Login to account',
          hide: publisher?.user?.accountStatuses?.includes(ACCOUNT_STATUS.DEACTIVATED),
          callback: () => {
            this.authService.loginToAccount(publisher.accountId);
          }
        },
        {
          id: publisher.accountId,
          key: 'Show details and changes',
          value: 'Show details and changes',
          callback: () => this.openPublisherDetails(publisher.accountId)
        },
        {
          id: publisher.accountId,
          key: 'Change password',
          value: 'Change password',
          callback: () => this.openInputModal(() => {
            const pass = this.newPasswordForm.get('password').value;
            if (pass) {
              this.userService.changePassword(publisher.user.email, pass)
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                  this.table.refreshPage();
                });
            }
          })
        },
        {
          id: publisher.accountId,
          key: 'Incoming Transactions',
          value: 'Incoming Transactions',
          callback: () => {
            this.router.navigate(['publisher', publisher.accountId, 'transactions']);
          }
        },
      ];
      if (publisher.user.waitingApproval) {
        const approveActions: IUnitKeyValue[] = [
          {
            key: 'Approve publisher',
            value: 'Approve publisher',
            hide: this.userType !== ACCOUNT_PAGE_TYPE.PUBLISHER,
            callback: () => {
              this.confirmTitle = CONFIRM_TITLE.REJECT;
              this.confirmDescription = CONFIRM_DESCRIPTION.REJECT;
              this.openModal(() => {
                this.publisherService.approvePublisher(publisher.user.accountId)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe(() => {
                    this.table.refreshPage();
                  });
              });
            }
          },
          {
            key: 'Reject publisher',
            value: 'Reject publisher',
            hide: this.userType !== ACCOUNT_PAGE_TYPE.PUBLISHER,
            callback: () => {
              this.confirmTitle = CONFIRM_TITLE.REJECT;
              this.confirmDescription = CONFIRM_DESCRIPTION.REJECT;
              this.openModal(() => {
                this.publisherService.rejectPublisher(publisher.user.accountId)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe(() => {
                    this.table.refreshPage();
                  });
              });
            }
          }
        ];
        approveActions.forEach((action) => this.actions[publisher.accountId].push(action));
      }

      if (!publisher.user.waitingApproval && publisher?.user?.accountStatuses?.includes(ACCOUNT_STATUS.DEACTIVATED)) {
        const activateActions = [
          {
            key: 'Activate',
            value: 'Activate ' + this.resolveUserType(),
            callback: () => {
              this.confirmTitle = CONFIRM_TITLE.ACTIVATE;
              this.confirmDescription = CONFIRM_DESCRIPTION.ACTIVATE;
              this.openModal(() => {
                this.userService.toggleUserState(this.userType, publisher.user.accountId, false)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe(() => {
                    this.table.refreshPage();
                  });
              });
            }
          }];
        activateActions.forEach((action) => this.actions[publisher.accountId].push(action));
      }

      if (!publisher.user.waitingApproval && !publisher?.user?.accountStatuses?.includes(ACCOUNT_STATUS.DEACTIVATED)) {
        const deactivateActions = [
          {
            key: 'Deactivate',
            value: 'Deactivate ' + this.resolveUserType(),
            callback: () => {
              this.confirmTitle = CONFIRM_TITLE.DEACTIVATE;
              this.confirmDescription = CONFIRM_DESCRIPTION.DEACTIVATE;
              this.openModal(() => {
                this.userService.toggleUserState(this.userType, publisher.user.accountId, true)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe(() => {
                    this.table.refreshPage();
                  });
              });
            }
          }];
        deactivateActions.forEach((action) => this.actions[publisher.accountId].push(action));
      }
    });
  }

  public openModal(callback: () => void): void {
    const dialogRef = this.dialog.open(this.confirm);
    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe((confirm: boolean) => {
        if (confirm) {
          callback();
        }
      });
  }

  public openInputModal(callback: () => void): void {
    this.newPasswordForm.reset();
    const dialogRef = this.dialog.open(this.resetPassword);

    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe((confirm: boolean) => {
          if (confirm) {
            callback();
          }
          this.newPasswordForm.reset();
      });
  }

  public onFilter(filters: IListFilterItem[]): void {
    this.queryFilters = filters.filter((filter) => filter.value);
    this.table.resetTableData();
    this.table.refreshData({});
  }

  public resolveUserType(): string {
    switch (this.userType) {
      case ACCOUNT_PAGE_TYPE.PUBLISHER:
        return 'publisher';

      case ACCOUNT_PAGE_TYPE.VIEWER:
        return 'viewer';
      default:
        return null;
    }
  }

}

enum CONFIRM_TITLE {
  APPROVE= 'admin.account.confirm.approve.title',
  REJECT = 'admin.account.confirm.reject.title',
  ACTIVATE = 'admin.account.activation.activate.title',
  DEACTIVATE = 'admin.account.activation.deactivate.title',
}

enum CONFIRM_DESCRIPTION {
  APPROVE= 'admin.account.confirm.approve.description',
  REJECT = 'admin.account.confirm.reject.description',
  ACTIVATE = 'admin.account.activation.activate.description',
  DEACTIVATE = 'admin.account.activation.deactivate.description',
}
