import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {filter, map, take, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, ActivationEnd, Router} from '@angular/router';
import {AutoDestroyService, IInfoPanel, LocalizationProvider} from 'ui-elements';
import {PublisherService} from '../../../../services/publisher/publisher.service';
import {ISubscription, PAGE_TYPE} from '../../../../models/publisher.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CURRENCY_SYMBOLS, IFormFieldsConfig, INPUT_TYPES} from 'ui-elements';
import {cutString} from 'ui-elements';
import {DateHelper} from 'ui-elements';
import {saveAs} from 'file-saver/FileSaver';
import {getFileNameFromResponseContentDisposition} from 'ui-elements';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-subscription-details',
  templateUrl: './subscription-details.component.html',
  styleUrls: ['./subscription-details.component.sass'],
  providers: [AutoDestroyService, PublisherService]
})
export class SubscriptionDetailsComponent implements OnInit {
  @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;
  public confirmTitle: CONFIRM_TITLE;
  public confirmDescription: CONFIRM_DESCRIPTION;
  private backUrl: string;

  public viewLoading: boolean;
  public viewDone: boolean;
  public download: boolean;
  public downloadDone: boolean;
  public sendLoading: boolean;
  public sendDone: boolean;
  public viewChargebackLoading: boolean;
  public viewChargebackDone: boolean;
  public downloadChargeback: boolean;
  public downloadChargebackDone: boolean;
  public sendChargebackLoading: boolean;
  public sendChargebackDone: boolean;

  private subscriptionId: string;
  public subscription: ISubscription;
  public subscriptionCanceling: boolean;
  public subscriptionDeleting: boolean;
  public chargeBackLoading: boolean;
  public form: FormGroup = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    companyName: ['', [Validators.required]],
    accountId: ['', [Validators.required]],
    type: ['', [Validators.required]],
    billNumber: ['', [Validators.required]],
    date: ['', [Validators.required]],
    amount: ['', [Validators.required]],
  });
  public activateForm = this.fb.group({
    active: [false]
  });

  public leftConfig: IFormFieldsConfig[] = [
    {
      name: 'firstName',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Name',
        placeholder: '',
      }
    },
    {
      name: 'lastName',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Vorname',
        placeholder: '',
      }
    },
    {
      name: 'companyName',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Firma',
        placeholder: '',
      }
    },
    {
      name: 'accountId',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Kunden ID',
        placeholder: '',
      }
    },
    {
      name: 'type',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Abo Art',
        placeholder: '',
      }
    },
    {
      name: 'billNumber',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Rechnungsnummer',
        placeholder: '',
      }
    },
  ];

  public rightConfig: IFormFieldsConfig[] = [
    {
      name: 'date',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Datum',
      }
    },
    {
      name: 'amount',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Summe',
      }
    }
  ];

  public chargeBackInfoPanel: IInfoPanel = {textKey: 'admin.subscription.chargeBack.infoPanel'};

  constructor(
    private activatedRoute: ActivatedRoute,
    private destroy$: AutoDestroyService,
    private publisherService: PublisherService,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private localizationProvider: LocalizationProvider
  ) {
    this.router.events.pipe(
      takeUntil(this.destroy$),
      filter((event) => event instanceof ActivationEnd)
    )
      .subscribe((res: ActivationEnd) =>  {
        if (!res.snapshot?.data.hasOwnProperty('pageType')) {
          return;
        }
        this.backUrl = res.snapshot.data.pageType === PAGE_TYPE.SUBSCRIPTION ? 'subscriptions' : 'publisher';
      });
  }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(takeUntil(this.destroy$))
      .subscribe(({subscriptionId}) => {
        this.subscriptionId = subscriptionId;
        this.publisherService.getSubscriptionDetails(this.subscriptionId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => {
            this.subscription = res;
            this.fillForm();
          });
      });
  }

  private fillForm(): void {
    this.form.get('firstName').setValue(this.subscription.user.firstName);
    this.form.get('lastName').setValue(this.subscription.user.lastName);
    this.form.get('companyName').setValue(this.subscription.user.companyName);
    this.form.get('accountId').setValue(cutString(this.subscription.user.accountId, 0, 8));
    this.form.get('type').setValue(this.resolveSubscriptionType());
    this.form.get('billNumber').setValue(cutString(this.subscription.transactionNumber, 0, 8));
    this.form.get('date').setValue(DateHelper.formatDate(this.subscription.subscription.created, 'DD MMM YYYY'));
    this.form.get('amount').setValue(CURRENCY_SYMBOLS[this.subscription.subscription.price.currency] + ' ' + (this.subscription.subscription.price.amount.toFixed(2)));
  }

  private resolveSubscriptionType(): string {
    let res = this.localizationProvider.getByKey('admin.subscription.' + (this.subscription.subscription.planType?.toLowerCase() || this.subscription.subscription.type?.toLowerCase()));
    if (this.subscription.subscription.bandwidth && !this.subscription.subscription.planType) {
      res += ' (' + this.subscription.subscription.bandwidth.name + ')';
    }
    if (this.subscription.subscription.storageSpace && !this.subscription.subscription.planType) {
      res += ' (' + this.subscription.subscription.storageSpace.name + ')';
    }
    return res;
  }

  public back(): void {
    this.activatedRoute.parent.params.pipe(takeUntil(this.destroy$)).subscribe(({id}) => {
      this.router.navigate([`${this.backUrl}/${id}`]);
    });
  }

  public downloadPdf(): void {
    this.download = true;
    this.publisherService.downloadPdf(this.subscription.purchaseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.download = false;
        this.downloadDone = true;
        setTimeout(() => this.downloadDone = false, 5000);
        saveAs(
          res.body,
          getFileNameFromResponseContentDisposition(res)
        );
      }, () => this.download = false);
  }

  public viewPdf(): void {
    this.viewLoading = true;
    this.publisherService.downloadPdf(this.subscription.purchaseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.viewLoading = false;
        this.viewDone = true;
        setTimeout(() => this.viewDone = false, 5000);
        const fileURL = URL.createObjectURL(new Blob([res.body], {type: 'application/pdf'}));
        window.open(fileURL);
      }, () => this.viewLoading = false);
  }

  public downloadChargebackPdf(): void {
    this.downloadChargeback = true;
    this.publisherService.getChargebackPdf(this.subscription.purchaseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.downloadChargeback = false;
        this.downloadChargebackDone = true;
        setTimeout(() => this.downloadChargebackDone = false, 5000);
        saveAs(
          res.body,
          getFileNameFromResponseContentDisposition(res)
        );
      }, () => this.downloadChargeback = false);
  }

  public viewChargebackPdf(): void {
    this.viewChargebackLoading = true;
    this.publisherService.getChargebackPdf(this.subscription.purchaseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.viewChargebackLoading = false;
        this.viewChargebackDone = true;
        setTimeout(() => this.viewChargebackDone = false, 5000);
        const fileURL = URL.createObjectURL(new Blob([res.body], {type: 'application/pdf'}));
        window.open(fileURL);
      }, () => this.viewChargebackLoading = false);
  }

  public sendPdf(): void {
    this.sendLoading = true;
    this.publisherService.sendPdf(this.subscription.purchaseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.sendLoading = false;
        this.sendDone = true;
        setTimeout(() => this.sendDone = false, 5000);
      }, () => this.sendLoading = false);
  }

  public sendChargebackPdf(): void {
    this.sendChargebackLoading = true;
    this.publisherService.sendChargebackPdf(this.subscription.purchaseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.sendChargebackLoading = false;
        this.sendChargebackDone = true;
        setTimeout(() => this.sendChargebackDone = false, 5000);
      }, () => this.sendChargebackLoading = false);
  }

  public cancelSubscription(): void {
    if (!this.subscription.subscription.prolongation.active) {
      return;
    }
    this.subscriptionCanceling = true;
    this.confirmTitle = CONFIRM_TITLE.CANCEL;
    this.confirmDescription = CONFIRM_DESCRIPTION.CANCEL;
    this.openModal(() => this.publisherService.cancelSubscription(this.subscription.subscription.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.subscription.subscription.prolongation.active = false;
        if (!res.success) {
          return;
        }
      }, () => this.subscriptionCanceling = false));
  }

  public deleteSubscription(): void {
    // if (!this.subscription.subscription.prolongation.active) {
    //   return;
    // }
    this.subscriptionDeleting = true;
    this.confirmTitle = CONFIRM_TITLE.DELETE;
    this.confirmDescription = CONFIRM_DESCRIPTION.DELETE;
    this.openModal(() => this.publisherService.deleteSubscription(this.subscription.subscription.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        // this.subscription.subscription.prolongation.active = false;
        if (!res.success) {
          return;
        }
        this.back();
      }, () => this.subscriptionDeleting = false));
  }

  public chargeBack(): void {
    if (!this.subscription.subscription.active) {
      return;
    }
    this.chargeBackLoading = true;
    this.confirmTitle = CONFIRM_TITLE.CHARGE_BACK;
    this.confirmDescription = CONFIRM_DESCRIPTION.CHARGE_BACK;
    this.openModal(() => this.publisherService.chargeback(this.subscription.subscription.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.subscription.subscription.active = false;
        if (!res.success) {
          return;
        }
      }, () => this.chargeBackLoading = false));
  }

  public openModal(callback: () => void): void {
    const dialogRef = this.dialog.open(this.confirm);
    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe((confirm: boolean) => {
        this.chargeBackLoading = false;
        this.subscriptionCanceling = false;
        this.subscriptionDeleting = false;
        if (confirm) {
          callback();
        }
      });
  }
}


enum CONFIRM_TITLE {
  CHARGE_BACK= 'admin.subscription.chargeBack.title',
  CANCEL = 'admin.subscription.cancel.title',
  DELETE = 'admin.subscription.delete.title',
}

enum CONFIRM_DESCRIPTION {
  CHARGE_BACK= 'admin.subscription.chargeBack.description',
  CANCEL = 'admin.subscription.cancel.description',
  DELETE = 'admin.subscription.delete.description',
}
