import {IBill, IBillsTableConfig, IMediaTimerange, IPrice, ITimerange, IUnitKeyValue} from 'ui-elements';
import {Observable} from 'rxjs';
import {ACCOUNT_PAGE_TYPE} from '../services/user/user.service';
import {SUBSCRIPTION_PLAN_TYPES} from '../services/subscription-manage/subscription-manage.service';

export interface IDropdownConfig {
  [key: string]: IUnitKeyValue[];
}

export interface IBillConfig {
  getConfig(id: string, type?: ACCOUNT_PAGE_TYPE): Observable<IBillsTableConfig<IBill>[]>;
  getTimeRanges(): Observable<IMediaTimerange[]>;
}

export enum ACCESS_TYPE {
  FREE = 'FREE',
  PAYMENT = 'PAYMENT',
  LEAD_REGISTRATION = 'LEAD_REGISTRATION'
}

export interface ISubscriptionTariffPlanConfig {
  planPeriod: TARIFF_PLAN_PERIOD;
  hideActionButton: boolean;
  coverClass?: number;
  isAuthorized?: boolean;
}

export interface ISubscriptionTariffPlan {
  id?: string;
  title: string;
  benefits: string[];
  mostPopular: boolean;
  prices: ITariffPlanPrice[];
  subscriptionPlanType: SUBSCRIPTION_PLAN_TYPES;
  subscriptionType: SUBSCRIPTION_PLAN_TYPES;
  type: TARIFF_PLAN_TYPES;
}

export interface ITariffPlanPrice {
  period: TARIFF_PLAN_PERIOD;
  prices: ITariffPlanPeriodPrice[]
}

export interface ITariffPlanPeriodPrice {
  period: TARIFF_PLAN_PERIOD;
  price: IPrice;
}

export enum TARIFF_PLAN_PERIOD {
  WEEK = 'WEEK',
  TWO_WEEKS = 'TWO_WEEKS',
  MONTH = 'MONTH',
  TWO_MONTH = 'TWO_MONTH',
  YEAR = 'YEAR'
}

export enum TARIFF_PLAN_TYPES {
  SUBSCRIPTION = 'SUBSCRIPTION',
  STORAGE_SPACE = 'STORAGE_SPACE',
  BANDWIDTH = 'BANDWIDTH'
}

export interface IStartPaymentReq extends IPaymentUrlsReq {
  userCardId?: string;
  paymentCard?: IPaymentCard;
  saveCard?: boolean;
  subscriptionTariffId?: string;
  groupId?: string;
  // externalCardId?: string,
  subscriptionPeriod?: TARIFF_PLAN_PERIOD,
  subscriptionTariffType?: TARIFF_PLAN_TYPES;
  // type?: PAYMENT_CARD_TYPE;
  // number?: string;
  // expiryDate?: string;
  // heidelpayCard?: IHeidelpayCard;
  // newCard?: boolean;
  accessToken?: string;
}

export interface IPaymentReturnResponse {
  externalCardId?: string;
  reason?: PAYMENT_FAIL_MESSAGES;
  message: string;
  paymentStatus: string;
  redirectUrl: string;
  purchaseInfo: {
    productId: string;
    purchaseType: PAYMENT_PURCHASE_TYPES;
  };
  success: boolean;
}

export enum PAYMENT_FAIL_MESSAGES {
  SYSTEM_ERROR = 'SYSTEM_ERROR',
  PROVIDER_ERROR = 'PROVIDER_ERROR'
}

export enum PAYMENT_PURCHASE_TYPES {
  STREAM = 'STREAM',
  STREAM_GROUP = 'STREAM_GROUP',
  STREAM_RECORDING = 'STREAM_RECORDING',
  VIDEO_ON_DEMAND = 'VIDEO_ON_DEMAND',
  SUBSCRIPTION = 'SUBSCRIPTION',
  STORAGE_SPACE = 'STORAGE_SPACE',
  BANDWIDTH = 'BANDWIDTH',
  MULTIBITRATE = 'MULTIBITRATE',
  DONATION = 'DONATION'
}

export interface IPaymentUrlsReq {
  returnUrl: string;
  successUrl: string;
  failUrl: string;
}

export interface IPaymentCard {
  id?: string;
  active?: boolean;
  accountId?: string;
  updatedAt?: number;
  createdAt?: number;
  externalCardId: string;
  type: PAYMENT_CARD_TYPE;
  number: string;
  heidelpayCard?: IHeidelpayCard;
  expiryDate?: string;
}

export enum PAYMENT_CARD_TYPE {
  VISA = 'VISA',
  MASTER_CARD = 'MASTER'
}

export interface IHeidelpayCard {
  '3ds': string;
  brand: PAYMENT_CARD_TYPE;
  cardDetails: {
    account: string;
    cardType: string;
    countryIsoA2: string;
    countryName: string;
    issuerName: string;
    issuerPhoneNumber: string;
    issuerUrl: string;
  };
  cvc: string;
  expiryDate: string;
  id: string;
  method: string;
  number: string;
}
