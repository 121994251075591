<div class="card">
  <div class="card-body bg-white">
    <ano-table #tableComponent
               [withSearch]="true"
               [tableConfig]="tableConfig"
               [filters]="filters"
               (search$)="search($event)"
               (filter$)="onFilter($event)"
    >
    </ano-table>
  </div>
</div>

<ng-template #idTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.user.accountId | stringCut }}
        </span>
  </div>
</ng-template>
<ng-template #activeTmpl let-data="rowData">
  <ng-container *ngIf="userType !== ACCOUNT_PAGE_TYPE.VIEWER">
    <div class="status-field">
      <span
        *ngIf="data.activeState === PUBLISHER_ACTIVE_STATE.WAITING_FOR_APPROVAL">{{'admin.publisher.type.waiting_approval' | localize}}</span>

      <span *ngIf="data.activeState === PUBLISHER_ACTIVE_STATE.DEACTIVATED">
            {{'Deactivated'}}
        </span>
      <span *ngIf="data.activeState === PUBLISHER_ACTIVE_STATE.ACTIVE">
            {{ 'Active'}}
      </span>
        <span *ngIf="data.activeState === PUBLISHER_ACTIVE_STATE.DELETED">
            {{ 'Deleted'}}
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="userType === ACCOUNT_PAGE_TYPE.VIEWER">
    <span *ngIf="data.user.accountStatuses.includes(PUBLISHER_ACTIVE_STATE.DEACTIVATED)">{{'Deactivated'}}</span>
    <span *ngIf="!data.user.accountStatuses.includes(PUBLISHER_ACTIVE_STATE.DEACTIVATED) && !data.user.accountStatuses.includes(PUBLISHER_ACTIVE_STATE.MARKED_FOR_DELETION)">{{'Active'}}</span>
    <span *ngIf="data.user.accountStatuses.includes(PUBLISHER_ACTIVE_STATE.MARKED_FOR_DELETION)">{{'Deleted'}}</span>
  </ng-container>
</ng-template>

<ng-template #firstNameTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.user.firstName}}
        </span>
  </div>
</ng-template>

<ng-template #lastNameTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.user.lastName}}
        </span>
  </div>
</ng-template>

<ng-template #emailTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.user.email}}
        </span>
  </div>
</ng-template>

<ng-template #subscriptionTmpl let-data="rowData">
  <div class="long-text status-field">
    <ng-container *ngIf="!data.user.waitingApproval">
      <span *ngIf="!data.subscriptionInfo.hasAnySubscription">
          {{'admin.publisher.type.free' | localize}}
      </span>
      <ng-container *ngIf="data.subscriptionInfo.hasAnySubscription">
        <span>{{('admin.publisher.type.' + data.subscriptionInfo.premiumSubscriptionType.toLowerCase()) | localize}}</span>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="data.user.waitingApproval">
      <span>{{'admin.publisher.type.waiting_approval' | localize}}</span>
    </ng-container>
  </div>
</ng-template>

<ng-template #companyTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.user.companyName || '-'}}
        </span>
  </div>
</ng-template>

<ng-template #countryTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{(data.user.countryCode | country) || '-'}}
        </span>
  </div>
</ng-template>

<ng-template #storageTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data?.subscriptionInfo?.hasStorageSpaceSubscription ? 'JA' : 'NEIN'}}
        </span>
  </div>
</ng-template>

<ng-template #emailConfirmedTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data?.user?.accountStatuses.includes(ACCOUNT_STATUS.EMAIL_CONFIRMED) ? 'JA' : 'NEIN'}}
        </span>
  </div>
</ng-template>

<ng-template #onlineStatus let-data="rowData">
  <div class="flex justify-content-center">
    <div class="status-circle" [class.success-circle]="data.liveStream" [class.danger-circle]="!data.liveStream"></div>
  </div>
</ng-template>

<ng-template #actionsTmpl let-data="rowData">
  <div class="table-actions-list">
    <app-action-dropdown [values]="actions[data.user.accountId]" [placeholder]="'Select action'"></app-action-dropdown>
  </div>
</ng-template>

<ng-template #confirm let-dialogRef="dialogRef">
  <ano-modal [dialogRef]="dialogRef" [title]="confirmTitle | localize" [isConfirmation]="true">
    {{confirmDescription | localize}}
  </ano-modal>
</ng-template>

<ng-template #resetPassword let-dialogRef="dialogRef">
  <ano-modal [dialogRef]="dialogRef" [title]="confirmTitle | localize" [isConfirmation]="true">
    {{'new.password' }}
    <form [formGroup]="newPasswordForm">
      <div class="form-inputs">
        <div class="form-group">
          <ano-input
            formControlName="password"
            [isEditMode]="true"
            [config]="{ inputType: INPUT_TYPES.PASSWORD, type: 'password', placeholder: 'Password', isRequired: true }">
          </ano-input>
        </div>
      </div>
    </form>
  </ano-modal>
</ng-template>

<ng-template #regDateTmpl let-data="rowData">
  <div class="long-text">
    <span style="display: block">{{data?.registrationDate | dateFormat: 'DD MMM YYYY'}}, {{data?.registrationDate | dateTimeFormat: 'HH:mm'}}</span>
  </div>
</ng-template>

<ng-template #endDateTmpl let-data="rowData">
  <div class="long-text">
    <span>{{data?.subscriptionInfo?.premiumSubscriptionEndDate && data?.subscriptionInfo?.premiumSubscriptionStartDate ? ((data?.subscriptionInfo?.premiumSubscriptionStartDate | dateTimeFormat: 'DD MMM YYYY') + ' - ' + (data?.subscriptionInfo?.premiumSubscriptionEndDate | dateTimeFormat: 'DD MMM YYYY') ) : '-' }}</span>
  </div>
</ng-template><ng-template #currencyTmpl let-data="rowData">
  <div class="long-text">
    <span>{{data?.user?.currency || '-' }}</span>
  </div>
</ng-template>
