import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DateTimeFormatPipe} from '../pipes/date-time-format.pipe';
import {CountryPipe} from '../pipes/country.pipe';
import {MaterialModule} from './material/material.module';
import {SubscriptionDetailsComponent} from '../pages/subscriptions/components/subscription-details/subscription-details.component';
import {UiElementsModule} from 'ui-elements';
import {LastDayPipe} from '../pipes/last-day.pipe';
import {UserBillingComponent} from '../components/user-billing/user-billing.component';
import {CurrentSubscriptionComponent} from '../components/user-billing/current-subscription/current-subscription.component';
import {ActionDropdownComponent} from '../components/action-dropdown/action-dropdown.component';
import {MultibitrateDetailsComponent} from '../components/multibitrate-details/multibitrate-details.component';
import {MediaTransactionsComponent} from '../pages/transactions/media-transactions/media-transactions.component';
import {PublisherTransactionsComponent} from '../pages/transactions/publisher-transactions/publisher-transactions.component';

@NgModule({
  declarations: [
    DateTimeFormatPipe,
    CountryPipe,
    SubscriptionDetailsComponent,
    LastDayPipe,
    UserBillingComponent,
    CurrentSubscriptionComponent,
    ActionDropdownComponent,
    MultibitrateDetailsComponent,
    MediaTransactionsComponent,
    PublisherTransactionsComponent
  ],
  imports: [
    CommonModule,
    UiElementsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  exports: [
    UiElementsModule,
    FormsModule,
    ReactiveFormsModule,
    DateTimeFormatPipe,
    CountryPipe,
    MaterialModule,
    SubscriptionDetailsComponent,
    LastDayPipe,
    UserBillingComponent,
    CurrentSubscriptionComponent,
    ActionDropdownComponent,
    MultibitrateDetailsComponent,
    MediaTransactionsComponent,
    PublisherTransactionsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SharedModule {
}
