<form class="border-form">
  <div class="form-group">
    <div class="dropdown default">
      <div class="dropdown-menu dark-primary" aria-labelledby="dropdownMenu2">
        <button class="dropdown-item" type="button" *ngFor="let value of values" (click)="selectValue(value)">{{value.value}}</button>
        <ng-container *ngIf="!values.length">
          <button class="dropdown-item" type="button"></button>
        </ng-container>
      </div>
      <button class="form-control dark-primary form-group-select text-left toggle-border" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="cut-placeholder">{{placeholder}}</span>
      </button>
    </div>
  </div>
</form>
