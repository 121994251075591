import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivationEnd, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  public headerTitle: string;

  constructor(
    private router: Router
  ) {}

  ngOnInit(): void {
    this.routerData$.subscribe((res) => {
      this.headerTitle = res.title;
    });
  }

  get routerData$(): Observable<{ [key: string]: string }> {
    return this.router.events.pipe(
      filter((event) => event instanceof ActivationEnd),
      map((res: ActivationEnd) =>  res.snapshot.data));
  }
}
