import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.sass']
})
export class NavComponent implements OnInit {
  public config: INavConfig[] = [
    {
      link: 'dashboard',
      title: 'Dashboard',
      icon: 'admin-home'
    },
    {
      link: 'publisher',
      title: 'Publisher',
      icon: 'admin-users'
    },
    {
      link: 'livestreams',
      title: 'Livestreams',
      icon: 'admin-video'
    },
    {
      link: 'videos',
      title: 'Videos',
      icon: 'admin-play'
    },
    {
      link: 'subscriptions',
      title: 'Abonnenten',
      icon: 'admin-star'
    },
    {
      link: 'viewer',
      title: 'Viewer Accounts',
      icon: 'admin-users'
    },
    {
      link: 'views',
      title: 'Viewer Payments',
      icon: 'admin-eye'
    },
    {
      link: 'invoices',
      title: 'Invoices',
      icon: 'invoice'
    },
    {
      link: 'payouts',
      title: 'Payouts',
      icon: 'admin-coin'
    },
  ];
  constructor(
    public authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  public logout(): void {
    this.authService.logout();
  }

}

interface INavConfig {
  title: string;
  link: string;
  icon: string;
}
