<section class="section-admin">
  <div class="container-fluid">
    <div class="content-admin bg-primary-dark">
      <app-nav></app-nav>
      <h1 class="title-page mb-40">{{headerTitle}}</h1>
      <div class="admin-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>
