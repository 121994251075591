export interface IInvoice {
  priceGross: string;
  invoiceDate: string;
  invoiceNumber: string;
  productType: string;
  fullName: string;
  company: string;
  companyVatId: string;
  publisherCountry: string;
  areaType: string;
  priceNet: string;
  priceVat: string;
  vatPercent: string;
  userId: string;
}

export enum INVOICE_FILTERS {
  TYPE = 'TYPE',
}
