import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DashboardStatistic, IChartData, IChartLegend, IDashboardSummary, ScatterChartComponent} from 'ui-elements';

@Component({
  selector: 'app-chart-and-summary',
  templateUrl: './chart-and-summary.component.html',
  styleUrls: ['./chart-and-summary.component.sass']
})
export class ChartAndSummaryComponent implements OnInit {
  @ViewChild('chartComponent', {static: false}) public chartComponent: ScatterChartComponent;
  @Input() id: number = 1;
  @Input() title: string;
  @Input() data: DashboardStatistic;
  @Input() legend: IChartLegend[];
  @Input() summary: IDashboardSummary;
  @Input() tableHeaderItems: string[];
  public chartData: IChartData[];
  @Input() colorScheme: any = {
    line1: '#0e54a9',
    line2: '#0c1d3f',
    line3: '#007e8c',
    line4: '#ffbe00',
    line5: '#bad668',
    total: '#fc4349'
  };


  constructor() { }

  ngOnInit(): void {
    this.initChartData();
  }

  private initChartData(): void {
    this.chartData = [];
    this.data.category.forEach((cat, index) => {
      this.chartData.push({
        date: new Date(cat).getDate()
      });
      this.chartData[index].values = {};
      Object.entries(this.data.values).forEach(([key, values], index1) => {
        this.chartData[index].values[key] = values[index];
      });
      this.data.category[index] = new Date(cat).getDate() - 1;
    });
  }

  private clearChart(): void {
    delete (this.data.values as any).line1;
    if (this.legend.findIndex((v) => v.title === 'line1')) {
      this.legend.splice(this.legend.findIndex((v) => v.title === 'line1'), 1);
    }
  }

  public updateChart(): void {
    this.initChartData();
    this.chartComponent.rerenderChart();
    // setTimeout(() => this.chartComponent.rerenderChart());
  }
}
