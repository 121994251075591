import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  CurrencyPipe,
  cutString,
  DateHelper,
  getFileNameFromResponseContentDisposition,
  IFormFieldsConfig,
  INPUT_TYPES,
  ITableConfig
} from 'ui-elements';
import {take, takeUntil, tap} from 'rxjs/operators';
import {AuthService} from '../../../../services/auth/auth.service';
import {AutoDestroyService} from 'ui-elements';
import {PayoutsService} from '../../../../services/payouts/payouts.service';
import {TableComponent} from 'ui-elements';
import {ActivatedRoute, Router} from '@angular/router';
import {ITimerange, ITimeRangeConfig} from 'ui-elements/lib/types/types';
import {PublisherService} from '../../../../services/publisher/publisher.service';
import {ACCOUNT_STATUS, IPublisher} from '../../../../models/publisher.model';
import moment from 'moment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IDropdownConfig} from '../../../../models/types';
import {saveAs} from 'file-saver/FileSaver';
import {IPayoutAggregation} from '../../../../models/payouts.model';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-payout-history',
  templateUrl: './payout-history.component.html',
  styleUrls: ['./payout-history.component.sass'],
  providers: [AutoDestroyService]
})
export class PayoutHistoryComponent implements OnInit {
  INPUT_TYPES = INPUT_TYPES;
  ACCOUNT_STATUS = ACCOUNT_STATUS;
  @ViewChild('tableComponent', {static: false}) public table: TableComponent;
  @ViewChild('idTmpl', {static: true}) public idTmpl: TemplateRef<any>;
  @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
  @ViewChild('titleTmpl', {static: true}) public titleTmpl: TemplateRef<any>;
  @ViewChild('priceTmpl', {static: true}) public priceTmpl: TemplateRef<any>;
  @ViewChild('soldAmountTmpl', {static: true}) public soldAmountTmpl: TemplateRef<any>;
  @ViewChild('openPayoutTmpl', {static: true}) public openPayoutTmpl: TemplateRef<any>;
  @ViewChild('dateTmpl', {static: true}) public dateTmpl: TemplateRef<any>;
  @ViewChild('transactionTmpl', {static: true}) public transactionTmpl: TemplateRef<any>;
  @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
  @ViewChild('statusToggle', {static: true}) public statusToggle: TemplateRef<any>;
  @ViewChild('productTypeTmpl', {static: true}) public productTypeTmpl: TemplateRef<any>;
  public tableConfig: ITableConfig<any>;
  public searchTerm: string;
  public publisherId: string;
  public timeRangeConfig: ITimeRangeConfig;
  public timeRange: ITimerange;
  public publisher: IPublisher;

  public form: FormGroup = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    accountId: ['', [Validators.required]],
    sum: ['', [Validators.required]],
    actualSum: ['', [Validators.required]],
    lastPayout: ['', [Validators.required]],
    sentDate: ['', [Validators.required]],
  });

  public leftConfig: IFormFieldsConfig[] = [
    {
      name: 'firstName',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Name',
        placeholder: '',
      }
    },
    {
      name: 'lastName',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Vorname',
        placeholder: '',
      }
    },
    {
      name: 'accountId',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Kunden ID',
        placeholder: '',
      }
    },
    {
      name: 'sum',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Summe Insgesamt',
        placeholder: '',
      }
    },
  ];

  public activateForm = this.fb.group({
    active: [false]
  });

  public rightConfig: IFormFieldsConfig[] = [
    {
      name: 'actualSum',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Aktuelle pay out Summe',
        placeholder: '',
      }
    },
    {
      name: 'lastPayout',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Last Unpaid Payout',
        placeholder: '',
      }
    }
  ];
  public actions: IDropdownConfig;
  public actionLoaders: any;
  public forms: any;
  public formsDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthService,
    private destroy$: AutoDestroyService,
    private payoutsService: PayoutsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private publisherService: PublisherService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.timeRange = {
      start: moment(new Date()).utc().startOf('month').toDate().getTime(),
      end: moment(new Date()).utc().endOf('month').toDate().getTime()
    };
    this.activatedRoute.params.pipe(takeUntil(this.destroy$))
      .subscribe(({id}) => {
        this.publisherId = id;
        this.publisherService.getPublisher(this.publisherId)
          .pipe(take(1))
          .subscribe((res) => {
            this.publisher = res;
            this.form.get('firstName').setValue(this.publisher.profile.firstName);
            this.form.get('lastName').setValue(this.publisher.profile.lastName);
            this.form.get('accountId').setValue(cutString(this.publisher.profile.ownerId, 0, 8));
            this.payoutsService.getPayouts(this.publisher.profile.ownerId).pipe(take(1))
              .subscribe((payouts) => {
                const payout = payouts.results.data.items[0];
                if (!payout) {
                  return;
                }

                const currencyPipe = new CurrencyPipe();

                this.form.get('sum').patchValue(currencyPipe.transform({amount: payout?.income, currency: 'EUR'}));
                this.form.get('actualSum').patchValue(currencyPipe.transform({amount: payout?.payoutLeft, currency: 'EUR'}));
                this.form.get('lastPayout').patchValue(payout?.unpaidPayoutDate ? DateHelper.formatDate(payout?.unpaidPayoutDate, 'MMM YYYY') : '-');
                this.activateForm.get('active').patchValue(payout.payoutDone);
                // this.activateForm.get('active').valueChanges.pipe(takeUntil(this.destroy$))
                //   .subscribe((active) => {
                //     this.payoutsService.togglePayouts(this.publisherId, active)
                //       .pipe(take(1))
                //       .subscribe(() => {
                //         console.log('toggle');
                //
                //         this.table.refreshData({});
                //       });
                //   });
              });
          });
        this.timeRangeConfig = {
          fetchMethod: (filters) => this.payoutsService.getTimerange(this.publisherId).pipe(takeUntil(this.destroy$)),
          filters: null,
          withoutFilters: true,
          withoutAll: true,
          preselectMonthIndex: 1
        };
        this.tableConfig = {
          dataField: 'data',
          matPaginator: true,
          filtersWidth100: true,
          fullHeight: true,
          searchFn: (sortParams, pagingParams) => {
            return this.payoutsService.getPayoutHistory(this.publisherId, this.searchTerm, sortParams, pagingParams, this.timeRange)
              .pipe(tap((res) => {
                this.mapToActions(res.results.data.items);
              }));
          },
          columns: [
            {
              name: 'Actions',
              tmpl: this.actionsTmpl,
              class: 'center'
            },
            {
              name: 'Status',
              tmpl: this.statusToggle,
              sortField: 'PAYOUT_STATUS',
              class: 'center'
            },
            {
              name: 'Titel',
              tmpl: this.titleTmpl,
              sortField: 'TITLE',
              class: 'left'
            },
            {
              name: 'Type',
              tmpl: this.productTypeTmpl,
              class: 'left'
            },
            {
              name: 'Preis',
              tmpl: this.priceTmpl,
              class: 'right',
              sortField: 'PRICE',
            },
            {
              name: 'Verkaufte Tickets',
              tmpl: this.soldAmountTmpl,
              class: 'right',
              sortField: 'SOLD_AMOUNT'
            },
            {
              name: 'Offene Auszahlung',
              tmpl: this.openPayoutTmpl,
              class: 'right',
              sortField: 'INCOME_AMOUNT'
            },
            {
              name: 'Pay out date',
              tmpl: this.dateTmpl,
              class: 'left no-white-space'
            },
            {
              name: 'Gutschrift nummer',
              tmpl: this.transactionTmpl,
              class: 'price'
            }
          ]

        };
      });
  }

  public mapToActions(data: any): void {
    const currencyPipe = new CurrencyPipe();
    this.formsDestroy$.next();
    this.actions = {};
    this.actionLoaders = {};
    this.forms = {};

    data.forEach((payout) => {
      this.actionLoaders[payout?.id] = false;
      this.forms[payout?.id] = this.fb.group({
        [payout?.id]: [payout.payoutDone]
      });
      this.forms[payout?.id].get(payout?.id.toString()).valueChanges
        .pipe(takeUntil(this.formsDestroy$))
        .subscribe((doPayout: boolean) => {
          this.payoutsService.sendPayout(payout?.id, doPayout).subscribe(() => {
            payout.payoutDone = doPayout;
            this.publisherService.getPublisher(this.publisherId)
              .pipe(take(1)).subscribe(() => {
              this.payoutsService.getPayouts(this.publisher.profile.ownerId).pipe(take(1))
                .subscribe((payouts) => {
                  const currentPayout = payouts.results.data.items[0];
                  if (!currentPayout) {
                    return;
                  }

                  this.form.get('sum').patchValue(currencyPipe.transform({amount: currentPayout?.income, currency: 'EUR'}));
                  this.form.get('actualSum').patchValue(currencyPipe.transform({amount: currentPayout?.payoutLeft, currency: 'EUR'}));
                  this.form.get('lastPayout').patchValue(currentPayout?.unpaidPayoutDate ? DateHelper.formatDate(currentPayout?.unpaidPayoutDate, 'MMM YYYY') : '-');
                  this.activateForm.get('active').patchValue(currentPayout.payoutDone);
                });
            });
            this.table.refreshData({});
          });
        });
      this.actions[payout?.id] = [
        {
          key: 'Download PDF',
          value: 'Download PDF',
          callback: () => {
            this.actionLoaders[payout?.id] = true;
            this.payoutsService.downloadPayoutPDF(payout.id.toString())
              .pipe(takeUntil(this.destroy$))
              .subscribe((res) => {
                this.actionLoaders[payout?.id] = false;
                saveAs(
                  res.body,
                  getFileNameFromResponseContentDisposition(res)
                );
              }, () => this.actionLoaders[payout?.id] = false);
          }
        },
        {
          key: 'Show PDF',
          value: 'Show PDF',
          callback: () => {
            this.actionLoaders[payout?.id] = true;
            this.payoutsService.downloadPayoutPDF(payout.id.toString())
              .pipe(takeUntil(this.destroy$))
              .subscribe((res) => {
                this.actionLoaders[payout?.id] = false;
                const fileURL = URL.createObjectURL(new Blob([res.body], {type: 'application/pdf'}));
                window.open(fileURL);
              }, () => this.actionLoaders[payout?.id] = false);
          }
        },
        {
          key: 'Send PDF',
          value: 'Send PDF',
          callback: () => {
            this.actionLoaders[payout?.id] = true;
            this.payoutsService.sendPdf(payout.id.toString())
              .pipe(takeUntil(this.destroy$))
              .subscribe((res) => {
                this.actionLoaders[payout?.id] = false;
              }, () => this.actionLoaders[payout?.id] = false);
          }
        }
      ];
    });
  }

  public goToPayout(id: string): void {
    this.router.navigate(['payouts/' + this.publisherId + '/payout/' + id]);
  }

  public timeRangeFilter(event): void {
    this.timeRange = event;
    this.table.refreshData({});
  }

  public search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.table.resetTableData();
    this.table.refreshData({});
  }

  public goToPayouts(): void {
    this.router.navigate(['/payouts']);
  }

  public loginAs(): void {
    this.authService.loginToAccount(this.publisher.profile.ownerId);
  }

  public toggle(payout: IPayoutAggregation): void {
    payout.payoutDone = !payout.payoutDone;
  }

}
