import {CURRENCY_SYMBOLS, PUBLISHER_FILTERS} from 'ui-elements';

export interface IPublisher {
  accountId: string;
  user: IUser;
  registrationDate: boolean;
  subscriptionInfo: ISubscriptionInfo;
  liveStream: boolean;
  deactivated?: boolean;
  profile: IProfile;
  accountStatuses: ACCOUNT_STATUS[];
  email?: string;
  activeState?: string;
  limitOff?: boolean;
}

export interface IProfile {
  ownerId: string;
  firstName: string;
  lastName: string;
  currency: string;
  waitingApproval: boolean;
  subscribed: boolean;
  profilePhoto: {
    photoId: string;
    photoUrl: string;
    waitingApproval: boolean;
  };
  address: {
    street: string;
    city: string;
    postalCode: string;
    country: string;
    phone: string;
  };
  bankDetails: {
    accountRecipient: string;
    accountInstitution: string;
    getiBan: string;
    bic: string;
  };
  publisherData: {
    name: string;
    description: string;
    website: string;
    picture: {
      photoId: string;
      photoUrl: string;
      waitingApproval: boolean;
    };
    headerPicture: {
      photoId: string;
      photoUrl: string;
      waitingApproval: boolean;
    }
  };
  contactInfo: {
    phone: string;
    email: string;
    website: string;
  };
  companyInfo: {
    company: string;
    vatId: string;
  };
  hiddenDataLimits?: boolean;
}

export interface ISubscriptionInfo {
  premiumSubscriptionType: boolean;
  premiumSubscriptionEndDate: boolean;
  hasAnySubscription: boolean;
}

export enum ACCOUNT_STATUS {
  REGISTERED = 'REGISTERED',
  CONFIRMED = 'CONFIRMED',
  PREMIUM = 'PREMIUM',
  DEACTIVATED = 'DEACTIVATED',
  MARKED_FOR_DELETION = 'MARKED_FOR_DELETION',
  APPROVED = 'APPROVED',
  EMAIL_CONFIRMED = 'EMAIL_CONFIRMED'
}

export interface IUser {
  accountId: string;
  email: string;
  firstName: string;
  lastName: string;
  photo: string;
  headerPhoto: string;
  phone: string;
  countryCode: string;
  companyName: string;
  accountStatuses: ACCOUNT_STATUS[];
  waitingApproval: boolean;
}

export interface IPublisherFilters {
  filterTypes: PUBLISHER_FILTERS[];
}

export interface IPublisherSubscriptionSummary extends IPublisher {
  lastPremiumSubscriptionPaymentDate: number;
  premiumSubscription: ISubscriptionInfo;
  storageSpaceSubscription: ISubscriptionInfo;
  bandwidthSubscription: ISubscriptionInfo;
}

export interface ISubscriptionSummary {
  startDate: number;
  endDate: number;
  type: SUBSCRIPTION_TYPE;
  active: boolean;
}

export interface ISubscription {
  user: IUser;
  subscription: {
    id: number;
    accountId: {
      internalId: string;
    };
    period: string;
    price: {
      amount: number;
      currency: string;
      zero: boolean;
    },
    currency: string
    type: string
    planType: string
    paymentType: string
    paymentCardId: number
    active: boolean
    startedAt: number;
    endAt: number
    prolongation: {
      subscriptionId: number;
      nextPaymentDate: number;
      retryStartDate: number;
      paymentRetryAmount: number;
      emailUnableProlongSent: boolean;
      active: boolean;
      created: number
      updated: number;
    },
    bandwidth: {
      name: string;
      dataSize: {
        value: number;
        unit: string;
      }
    },
    storageSpace: {
      name: string;
      dataSize: {
        value: number;
        unit: string;
      }
    },
    created: number
    updated: number
  };
  purchaseDate: number;
  invoiceSentDate: number;
  invoiceNumber: number;
  transactionNumber: string;
  purchaseId: string;
  chargeBack?: boolean;
}

export enum SUBSCRIPTION_TYPE {
  SUBSCRIPTION = 'SUBSCRIPTION',
  STORAGE_SPACE = 'STORAGE_SPACE',
  BANDWIDTH = 'BANDWIDTH'

}

export enum PAGE_TYPE {
  SUBSCRIPTION, PUBLISHER
}

export enum PUBLISHER_ACTIVE_STATE {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  MARKED_FOR_DELETION = 'MARKED_FOR_DELETION',
  DELETED = 'DELETED'
}
