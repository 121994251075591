import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayoutsComponent } from './payouts.component';
import {SharedModule} from '../../modules/shared.module';
import { PayoutDetailsComponent } from './components/payout-details/payout-details.component';
import { PayoutHistoryComponent } from './components/payout-history/payout-history.component';

@NgModule({
  declarations: [PayoutsComponent, PayoutDetailsComponent, PayoutHistoryComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class PayoutsModule { }
