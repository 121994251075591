import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {API_URLS_ADMIN} from './const/api.urls';
import {ApiService, CURRENT_CURRENCY} from 'ui-elements';
import {LoginModule} from './pages/login/login.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PublisherModule} from './pages/publisher/publisher.module';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import { NavComponent } from './components/nav/nav.component';
import {StreamsComponent} from './pages/streams/streams.component';
import {StreamsModule} from './pages/streams/streams.module';
import {DashboardModule} from './pages/dashboard/dashboard.module';
import {VideosModule} from './pages/videos/videos.module';
import {InvoicesModule} from './pages/invoices/invoices.module';
import {LocalizationProvider} from 'ui-elements';
import {CmsService} from 'ui-elements';
import {localizationProviderFactory} from 'ui-elements';
import {API_URLS} from 'ui-elements';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxMaskModule} from 'ngx-mask';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {SubscriptionsModule} from './pages/subscriptions/subscriptions.module';
import {ViewsModule} from './pages/views/views.module';
import {PayoutsModule} from './pages/payouts/payouts.module';
import {environment} from '../environments/environment';
import {ENVIRONMENT} from 'ui-elements';
import { LastDayPipe } from './pipes/last-day.pipe';
import {BehaviorSubject} from 'rxjs';
import { CurrentSubscriptionComponent } from './components/user-billing/current-subscription/current-subscription.component';
import {ErrorInterceptor} from './interceptors/error.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    LoginModule,
    PublisherModule,
    StreamsModule,
    DashboardModule,
    VideosModule,
    InvoicesModule,
    NgxMaskModule.forRoot(),
    SubscriptionsModule,
    ViewsModule,
    PayoutsModule
  ],
  providers: [
    ApiService,
    {
      provide: API_URLS,
      useValue: API_URLS_ADMIN
    },
    {
      provide: CURRENT_CURRENCY,
      useValue: new BehaviorSubject<string>(null)
    },
    {
      provide: ENVIRONMENT,
      useValue: environment
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    LocalizationProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: localizationProviderFactory,
      deps: [LocalizationProvider, CmsService],
      multi: true
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
