import { Pipe, PipeTransform } from '@angular/core';
import {CmsService} from 'ui-elements';

@Pipe({
  name: 'country'
})
export class CountryPipe implements PipeTransform {

  public constructor(private readonly cmsService: CmsService) {}

  transform(value: string, ...args: unknown[]): string {
    return this.cmsService.getCountryByKey(value)?.value;
  }

}
