<div class="card">
  <div class="card-header bg-white pb-0">
    <h2 class="title-level-3">Abonnent Detail</h2>
    <ano-button (click)="back()" [config]="{
    type: 'button',
    classList: 'cursor-pointer width110px',
    styleType: 'primary'}"><i class="icon icon-back-arrow text-white cursor-pointer"></i> Zurück
    </ano-button>
  </div>
  <div class="card-body bg-white">
    <div class="row">
      <div class="col-5">
        <ano-form *ngIf="form"
                  [forcedViewMode]="true"
                  [form]="form"
                  [fieldsConfig]="leftConfig"
                  [withoutSave]="true" [materialForm]="true" [isLightForm]="true"
        >
        </ano-form>
      </div>
      <div class="col-5">
        <ano-form *ngIf="form"
                  [forcedViewMode]="true"
                  [labelWidth]="5"
                  [fieldWidth]="7"
                  [form]="form"
                  [fieldsConfig]="rightConfig"
                  [withoutSave]="true" [materialForm]="true" [isLightForm]="true"
        >
        </ano-form>
        <div class="row" *ngIf="subscription">
          <ng-container *ngIf="subscription?.subscription.active">
          <div class="col-6">
            <ano-button (click)="chargeBack()" [config]="{
                type: 'button',
                classList: 'cursor-pointer',
                styleType: 'primary',
                isDisabled: !subscription?.subscription.active}">
              <!--            <i *ngIf="viewDone" class="icon icon-check text-success cursor-pointer"></i>-->
              <i class="icon icon-money text-white cursor-pointer"></i>
              <ano-loader [isOpen]="chargeBackLoading"></ano-loader>
              {{'admin.subscription.chargeback.btn' | localize}}
            </ano-button>
          </div>
          <div class="col-6">
            <ano-button (click)="cancelSubscription()" [config]="{
                type: 'button',
                classList: 'cursor-pointer',
                styleType: 'danger',
                isDisabled: !subscription?.subscription?.prolongation?.active || !subscription?.subscription?.active
                }">
              <!--            <i *ngIf="viewDone" class="icon icon-check text-success cursor-pointer"></i>-->
              <i class="icon icon-cross text-white cursor-pointer"></i>
              <ano-loader [isOpen]="subscriptionCanceling"></ano-loader>
              {{'admin.subscription.cancel.btn' | localize}}
            </ano-button>
          </div>
          </ng-container>
          <ng-container *ngIf="subscription?.chargeBack">
            <div class="col-12">
              <ano-info-panel [infoPanel]="chargeBackInfoPanel"></ano-info-panel>
            </div>
          </ng-container>
        </div>

        <div *ngIf="subscription && subscription.subscription.paymentType === 'MANUAL'" class="row delete-btn">
          <div class="col-6">
            <ano-button (click)="deleteSubscription()" [config]="{
                type: 'button',
                classList: 'cursor-pointer',
                styleType: 'danger'
              }">
              <!--            <i *ngIf="viewDone" class="icon icon-check text-success cursor-pointer"></i>-->
              <i class="icon icon-cross text-white cursor-pointer"></i>
              <ano-loader [isOpen]="subscriptionDeleting"></ano-loader>
              {{'admin.subscription.delete.btn' | localize}}
            </ano-button>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="details-actions">
              <ano-button (click)="viewPdf()" [config]="{
                type: 'button',
                classList: 'cursor-pointer full-width',
                styleType: 'primary'}">
                <i *ngIf="viewDone" class="icon icon-check text-success cursor-pointer"></i>
                <i class="icon icon-admin-eye text-white cursor-pointer"></i>
                <ano-loader [isOpen]="viewLoading"></ano-loader>
                Show PDF
                {{'admin.subscription.show.pdf' | localize}}
              </ano-button>
              <ano-button (click)="downloadPdf()" [config]="{
                type: 'button',
                classList: 'cursor-pointer full-width',
                styleType: 'primary'}">
                <i *ngIf="downloadDone" class="icon icon-check text-success cursor-pointer"></i>
                <i class="icon icon-download text-white cursor-pointer">
                </i>
                <ano-loader [isOpen]="download"></ano-loader>
                {{'admin.subscription.download.pdf' | localize}}
              </ano-button>
              <ano-button (click)="sendPdf()" [config]="{
                type: 'button',
                classList: 'cursor-pointer full-width',
                styleType: 'success'}">
                <i *ngIf="sendDone" class="icon icon-check text-white cursor-pointer"></i>
                <i class="icon icon-telegram-round text-white cursor-pointer"></i>
                <ano-loader [isOpen]="sendLoading"></ano-loader>
                {{'admin.subscription.send.pdf' | localize}}
              </ano-button>
            </div>
          </div>
        </div>

        <div *ngIf="subscription?.chargeBack" class="row">
          <div class="col">
            <div class="details-actions">
              <ano-button (click)="viewChargebackPdf()" [config]="{
                type: 'button',
                classList: 'cursor-pointer full-width',
                styleType: 'primary'}">
                <i *ngIf="viewChargebackDone" class="icon icon-check text-success cursor-pointer"></i>
                <i class="icon icon-admin-eye text-white cursor-pointer"></i>
                <ano-loader [isOpen]="viewChargebackLoading"></ano-loader>
                {{'admin.subscription.show.pdf.chargeback' | localize}}
              </ano-button>
              <ano-button (click)="downloadChargebackPdf()" [config]="{
                type: 'button',
                classList: 'cursor-pointer full-width',
                styleType: 'primary'}">
                <i *ngIf="downloadChargebackDone" class="icon icon-check text-success cursor-pointer"></i>
                <i class="icon icon-download text-white cursor-pointer">
                </i>
                <ano-loader [isOpen]="downloadChargeback"></ano-loader>
                {{'admin.subscription.download.pdf.chargeback' | localize}}
              </ano-button>
              <ano-button (click)="sendChargebackPdf()" [config]="{
                type: 'button',
                classList: 'cursor-pointer full-width',
                styleType: 'success'}">
                <i *ngIf="sendChargebackDone" class="icon icon-check text-white cursor-pointer"></i>
                <i class="icon icon-telegram-round text-white cursor-pointer"></i>
                <ano-loader [isOpen]="sendChargebackLoading"></ano-loader>
                {{'admin.subscription.send.pdf.chargeback' | localize}}
              </ano-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ano-loader [isOpen]="!subscription"></ano-loader>
</div>


<ng-template #confirm let-dialogRef="dialogRef">
  <ano-modal [dialogRef]="dialogRef" [title]="confirmTitle | localize" [isConfirmation]="true">
    {{confirmDescription | localize}}
  </ano-modal>
</ng-template>
