<div class="card">
  <div class="card-header bg-white pb-0">
    <div class="row">
      <div class="col">
        <h2 class="title-level-3">{{publisher?.profile?.firstName}}  {{publisher?.profile?.lastName}}</h2>
        <ano-button (click)="loginAs()" [config]="{
                    type: 'button',
                    classList: 'cursor-pointer padding-bottom-10',
                    styleType: 'link'}" [isDisabled]="publisher?.accountStatuses.includes(ACCOUNT_STATUS.DEACTIVATED)"><i class="icon icon-user"></i> Login to account
        </ano-button>
      </div>
    </div>
    <div class="back-button">
      <ano-button (click)="goBack()" [config]="{
        type: 'button',
        classList: 'cursor-pointer width110px',
        styleType: 'primary'}"><i class="icon icon-back-arrow text-white cursor-pointer"></i> Zurück
      </ano-button>
    </div>
  </div>
  <div class="card-body bg-white">
    <ano-table #tableComponent
               (search$)="search($event)"
               [tableConfig]="tableConfig"
               [withSearch]="true"
    >
    </ano-table>
  </div>
</div>

<ng-template #actionsTmpl let-data="rowData">
  <div class="table-actions-list">
    <app-action-dropdown [placeholder]="'Select action'" [values]="actions[data.purchaseId]"></app-action-dropdown>
    <ano-loader [isOpen]="actionLoaders[data.purchaseId]"></ano-loader>
  </div>
</ng-template>

<ng-template #statusTmpl let-data="rowData">
  <i class="icon-{{ data?.status === 'PAID' ? 'check' : 'cross'}} text-primary"></i>
</ng-template>

<ng-template #dateTmpl let-data="rowData">
  {{data.date | dateFormat: 'DD MMM YYYY'}}
</ng-template>

<ng-template #billNumberTmpl let-data="rowData">
  {{data.billNumber | stringCut}}
</ng-template>

<ng-template #amountTmpl let-data="rowData">
  {{data?.amount | currency}}
</ng-template>

<ng-template #typeTmpl let-data="rowData">
  <span>{{('stream.revenue.' + data?.type) | localize}}</span>
</ng-template>
