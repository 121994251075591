import { Injectable } from '@angular/core';
import {ApiService, IResponse} from 'ui-elements';
import {Observable} from 'rxjs';
import {IPublisher} from '../../models/publisher.model';
import {IListFilterItem, IPagedResponse, PagingParams, SortParam} from 'ui-elements';
import {API_URLS_ADMIN, UrlGenerator} from '../../const/api.urls';
import {pluck} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ViewerService {

  constructor(private apiService: ApiService) { }

  public getViewers(
    searchTerm: string = '',
    sort: SortParam[] = DEFAULT_SORT,
    paging: PagingParams = DEFAULT_PAGING
  ): Observable<IPagedResponse<IPublisher>> {
    return this.apiService.post(API_URLS_ADMIN.VIEWERS_LIST, {
      searchTerm,
      sort,
      paging
    });
  }

  public toggleViewerState(id: string, deactivated: boolean): Observable<IResponse> {
    return this.apiService.put(UrlGenerator.generate(API_URLS_ADMIN.TOGGLE_VIEWER_STATE, {id}), {deactivate: deactivated});
  }

  public getViewer(id: string): Observable<any> {
    return this.apiService.get(UrlGenerator.generate(API_URLS_ADMIN.VIEWER, {id}))
      .pipe(pluck('results', 'data'));
  }
}

const DEFAULT_PAGING = {
  page: 0,
  itemsOnPage: 24
} as PagingParams;

const DEFAULT_SORT = [{
  field: 'REGISTRATION_DATE',
  order: 'DESC'
}] as SortParam[];
