import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  IListFilterItem,
  ITableConfig, ITimerange,
  ITimeRangeConfig
} from 'ui-elements/lib/types/types';
import {takeUntil, tap} from 'rxjs/operators';
import {ViewsService} from '../../services/views/views.service';
import {
  AutoDestroyService,
  getFileNameFromResponseContentDisposition,
  ITableFilters,
  TableComponent,
  VIEWER_FILTERS
} from 'ui-elements';
import {ACCESS_TYPE, IDropdownConfig} from '../../models/types';
import {AuthService} from '../../services/auth/auth.service';
import {Router} from '@angular/router';
import {saveAs} from 'file-saver/FileSaver';
import {GenerateUtil} from '../../utils/generate.util';

@Component({
  selector: 'app-views',
  templateUrl: './views.component.html',
  styleUrls: ['./views.component.sass'],
  providers: [AutoDestroyService, ViewsService]
})
export class ViewsComponent implements OnInit {
  public ACCESS_TYPE = ACCESS_TYPE;
  @ViewChild('tableComponent', {static: false}) public table: TableComponent;
  @ViewChild('dateTmpl', {static: true}) public dateTmpl: TemplateRef<any>;
  @ViewChild('firstNameTmpl', {static: true}) public firstNameTmpl: TemplateRef<any>;
  @ViewChild('lastNameTmpl', {static: true}) public lastNameTmpl: TemplateRef<any>;
  @ViewChild('invoiceNumberTmpl', { static: true }) public invoiceNumberTmpl: TemplateRef<any>;
  @ViewChild('transactionTmpl', {static: true}) public transactionTmpl: TemplateRef<any>;
  @ViewChild('titleTmpl', {static: true}) public titleTmpl: TemplateRef<any>;
  @ViewChild('publisherTmpl', {static: true}) public publisherTmpl: TemplateRef<any>;
  @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
  @ViewChild('emailTmpl', {static: true}) public emailTmpl: TemplateRef<any>;
  @ViewChild('accessTmpl', {static: true}) public accessTmpl: TemplateRef<any>;
  @ViewChild('priceTmpl', {static: true}) public priceTmpl: TemplateRef<any>;
  @ViewChild('mediaIdTmpl', {static: true}) public mediaIdTmpl: TemplateRef<any>;
  public tableConfig: ITableConfig<any>;
  public searchTerm: string;
  public queryFilters: IListFilterItem[];
  public actions: IDropdownConfig;
  public actionLoaders: any;
  public timeRangeConfig: ITimeRangeConfig;
  public timeRange: ITimerange;
  public filters: ITableFilters;

  constructor(
    private viewsService: ViewsService,
    private authService: AuthService,
    private destroy$: AutoDestroyService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.timeRangeConfig = {
      fetchMethod: (filters) => this.viewsService.getTimeRanges(),
      filters: null,
      withoutFilters: true
    };
    this.viewsService.getFilters({filterTypes: [VIEWER_FILTERS.VIEW_ACCESS_TYPE]})
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.filters = res;
        this.filters.filterListItems[0].isMultiselectInSingleDropdown = true;
      });
    this.tableConfig = {
      dataField: 'data',
      matPaginator: true,
      filtersWidth100: true,
      fullHeight: true,
      searchFn: (sortParams, pagingParams) => {
        return this.viewsService.getViews(this.searchTerm, sortParams, pagingParams, this.queryFilters, this.timeRange)
          .pipe(tap((res) => {
            this.mapToActions(res.results.data.items);
          }));
      },
      columns: [
        {
          name: 'Actions',
          tmpl: this.actionsTmpl,
          class: 'dropdown-actions'
        },
        {
          name: 'Name',
          // dataField: 'firstName'
          tmpl: this.firstNameTmpl,
          sortField: 'VIEWER_FIRST_NAME',
          class: 'left'
        },
        {
          name: 'Vorname',
          tmpl: this.lastNameTmpl,
          sortField: 'VIEWER_LAST_NAME',
          class: 'left'
          // sortField: 'accountId'
          // dataField: 'lastName'
        },
        {
          name: 'E-mail',
          // dataField: 'email'
          tmpl: this.emailTmpl,
          sortField: 'VIEWER_EMAIL',
          class: 'left'
          // class: 'left'
        },
        {
          name: 'Kaufdatum',
          tmpl: this.dateTmpl,
          sortField: 'VIEW_DATE',
          class: 'left'
        },
        {
          name: 'Invoice Number',
          tmpl: this.invoiceNumberTmpl,
          sortField: 'INVOICE_ID',
          class: 'left',
        },
        {
          name: 'Transaktoins- nummer',
          tmpl: this.transactionTmpl,
          sortField: 'TRANSACTION_ID',
          class: 'left'
          // sortField: 'firstName'
        },
        {
          name: 'Publisher',
          tmpl: this.publisherTmpl,
          sortField: 'PUBLISHER_ID',
          class: 'left'
        },
        {
          name: 'Media ID',
          tmpl: this.mediaIdTmpl,
          // sortField: 'MEDIA_ID',
          class: 'left'
        },
        {
          name: 'Titel/Gruppe',
          tmpl: this.titleTmpl,
          sortField: 'TITLE',
          class: 'left'
        },
        {
          name: 'Zugang',
          tmpl: this.accessTmpl,
          sortField: 'ACCESS_TYPE',
          class: 'left'
        },
        {
          name: 'Price',
          tmpl: this.priceTmpl,
          sortField: 'PRICE',
          class: 'right'
        },
      ]

    };
  }

  public onFilter(filters: IListFilterItem[]): void {
    this.queryFilters = filters.filter((filter) => filter.value);
    this.table.resetTableData();
    this.table.refreshData({});
  }

  private mapToActions(data: any): void {
    this.actions = {};
    this.actionLoaders = {};
    data.forEach((view) => {
      view._purchaseId = view._purchaseId || GenerateUtil.uuidv4();
      this.actionLoaders[view?._purchaseId] = false;
      this.actions[view?._purchaseId] = [];
      if (view?.viewerId && view.email) {
        this.actions[view?._purchaseId].push(
          {
            key: 'Login to account',
            value: 'Login to account',
            callback: () => {
              this.authService.loginToAccount(view?.viewerId);
            }
          }
        );
      }

      if (!view.purchaseId) {
        return;
      }
      this.actions[view?._purchaseId] = this.actions[view?._purchaseId].concat([
        {
          key: 'Download',
          value: 'Download',
          callback: () => {
            this.actionLoaders[view?._purchaseId] = true;
            this.viewsService.downloadPdf(view?.purchaseId)
              .pipe(takeUntil(this.destroy$))
              .subscribe((res) => {
                this.actionLoaders[view?._purchaseId] = false;
                saveAs(
                  res.body,
                  getFileNameFromResponseContentDisposition(res)
                );
              }, () => this.actionLoaders[view?.purchaseId] = false);
          }
        },
        {
          key: 'Zeige PDF',
          value: 'Zeige PDF',
          callback: () => {
            this.actionLoaders[view?._purchaseId] = true;
            this.viewsService.downloadPdf(view?.purchaseId)
              .pipe(takeUntil(this.destroy$))
              .subscribe((res) => {
                this.actionLoaders[view?._purchaseId] = false;
                const fileURL = URL.createObjectURL(new Blob([res.body], {type: 'application/pdf'}));
                window.open(fileURL);
              }, () => this.actionLoaders[view?.purchaseId] = false);
          }
        }
      ]);

      if (view?.email) {
        this.actions[view._purchaseId] = this.actions[view._purchaseId].concat([
          {
            key: 'An Zuschauer PDF',
            value: 'An Zuschauer PDF',
            callback: () => {
              this.actionLoaders[view?._purchaseId] = true;
              this.viewsService.sendPdf(view?.purchaseId)
                .pipe(takeUntil(this.destroy$))
                .subscribe((res) => {
                  this.actionLoaders[view?._purchaseId] = false;
                }, () => this.actionLoaders[view?._purchaseId] = false);
            }
          }
        ]);
      }
    });
  }

  public goToPublisher(id: string): void {
    this.router.navigate(['/views/publisher/' + id]);
  }

  public timeRangeFilter(event): void {
    this.timeRange = event;
    this.table.refreshData({});
  }

  public search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.table.resetTableData();
    this.table.refreshData({});
  }

}
