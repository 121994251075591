import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {PublisherService} from '../../../../services/publisher/publisher.service';
import {AutoDestroyService} from 'ui-elements';
import {ACCOUNT_STATUS, IPublisher} from '../../../../models/publisher.model';
import {take, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  IFormFieldsConfig,
  INPUT_TYPES,
  IBillsTableConfig,
  IBill,
  ITimeRangeConfig,
  MEDIA_FILTERS_FIELDS, FILTER_MEDIA_TYPES, ITableColumn, TEMPLATE_ID, STREAM_STATUS, BILL_STATUS
} from 'ui-elements';
import {CmsService} from 'ui-elements';
import {BillsConfig} from '../config/BillsConfig';
import {BillsService} from '../../../../services/bills/bills.service';
import {MatDialog} from '@angular/material/dialog';
import {cutString} from 'ui-elements';
import {AuthService} from '../../../../services/auth/auth.service';
import {ACCOUNT_PAGE_TYPE, UserService} from '../../../../services/user/user.service';

@Component({
  selector: 'app-publisher-details',
  templateUrl: './publisher-details.component.html',
  styleUrls: ['./publisher-details.component.sass'],
  providers: [AutoDestroyService, PublisherService, BillsConfig, BillsService]
})
export class PublisherDetailsComponent implements OnInit {
  public BILL_STATUS = BILL_STATUS;
  ACCOUNT_PAGE_TYPE = ACCOUNT_PAGE_TYPE;
  public userType: ACCOUNT_PAGE_TYPE;
  @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;
  // @ViewChild('dateTmpl', {static: true}) public dateTmpl: TemplateRef<any>;
  // @ViewChild('amountTmpl', {static: true}) public amountTmpl: TemplateRef<any>;
  // @ViewChild('dataSizeTmpl', {static: true}) public dataSizeTmpl: TemplateRef<any>;
  // @ViewChild('dateRangeTmpl', {static: true}) public dateRangeTmpl: TemplateRef<any>;
  // @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
  // @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
  public confirmTitle: CONFIRM_TITLE;
  public confirmDescription: CONFIRM_DESCRIPTION;

  public activateForm = this.fb.group({
    active: [false]
  });
  public ACCOUNT_STATUS = ACCOUNT_STATUS;
  public accountStatuses: ACCOUNT_STATUS[];
  public publisher: IPublisher;
  public form: FormGroup = this.fb.group({
    accountId: ['', [Validators.required]],
    email: ['', [Validators.required]],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    street: ['', [Validators.required]],
    postalCode: ['', [Validators.required]],
    city: ['', [Validators.required]],
    country: ['', [Validators.required]],
    phone: ['', [Validators.required]],
  });
  public config: IFormFieldsConfig[] = [
    {
      name: 'accountId',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Kunden ID',
        placeholder: '',
        isRequired: true
      }
    },
    {
      name: 'email',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'E-Mail',
        placeholder: '',
        isRequired: true
      }
    },
    {
      name: 'firstName',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'profile.firstName',
        placeholder: '',
        isRequired: true
      }
    },
    {
      name: 'lastName',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'profile.lastName',
        placeholder: '',
        isRequired: true
      }
    },
    {
      name: 'street',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'profile.streetAndHouse',
        placeholder: '',
        isRequired: true
      }
    },
    {
      name: 'postalCode',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'profile.zip',
        placeholder: '',
        isRequired: true
      }
    },
    {
      name: 'city',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'profile.city',
        placeholder: '',
        isRequired: true
      }
    },
    {
      name: 'country',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'profile.country',
        isAutocomplete: true,
        // placeholder: '',
        isRequired: true
      }
    },
    {
      name: 'phone',
      config: {
        inputType: INPUT_TYPES.PHONE,
        type: 'tel',
        label: 'profile.contact.phone',
        placeholder: '',
        isRequired: true
      }
    },
  ];
  public timeRangeConfig: ITimeRangeConfig;
  public currentBill: IBill;
  public loading: boolean;

  constructor(
    private publisherService: PublisherService,
    private destroy$: AutoDestroyService,
    private activatedRoute: ActivatedRoute,
    private cmsService: CmsService,
    private fb: FormBuilder,
    private router: Router,
    public billsConfig: BillsConfig,
    public dialog: MatDialog,
    private authService: AuthService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.resolveAccountStatuses();
    this.getPublisher();
  }

  private resolveAccountStatuses(): void {
    this.publisherService.getAccountStatuses()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.accountStatuses = res;
      });
  }

  private getPublisher(): void {
    this.loading = true;
    this.activatedRoute.params.pipe(takeUntil(this.destroy$))
      .subscribe(({id}) => {
        this.activatedRoute.data.pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            this.userType = data.userType;
            this.userService.getUser(this.userType, id)
              .pipe(takeUntil(this.destroy$))
              .subscribe((res) => {
                this.loading = false;
                this.publisher = res;
                this.fillPublisherInfo();
                // this.resolveBillsTableConfigs();
                if (!this.publisher.profile.waitingApproval) {
                  this.listenActiveChange();
                }
              }, () => this.loading = false);
          });
      });
  }

  private fillPublisherInfo(): void {
    this.form.get('accountId').setValue(cutString(this.publisher.profile.ownerId, 0, 8));
    this.form.get('email').setValue(this.publisher.email);
    this.form.get('firstName').setValue(this.publisher.profile.firstName);
    this.form.get('lastName').setValue(this.publisher.profile.lastName);
    this.form.get('phone').setValue(this.publisher.profile.address.phone);
    this.form.get('city').setValue(this.publisher.profile.address.city);
    this.form.get('street').setValue(this.publisher.profile.address.street);
    this.form.get('postalCode').setValue(this.publisher.profile.address.postalCode);
    this.form.get('country').setValue(this.cmsService.getCountryByKey(this.publisher.profile.address.country).value);
    this.activateForm.get('active').setValue(!this.publisher.accountStatuses.includes(ACCOUNT_STATUS.DEACTIVATED));
  }

  private listenActiveChange(): void {
    this.activateForm.get('active').valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.togglePublisherState(!res);
      });
  }

  public rejectPublisher(): void {
    this.loading = true;
    this.confirmTitle = CONFIRM_TITLE.REJECT;
    this.confirmDescription = CONFIRM_DESCRIPTION.REJECT;
    this.openModal(() => {
      this.publisherService.rejectPublisher(this.publisher.profile.ownerId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.loading = false;
          this.router.navigate(['publisher']);
        }, () => this.loading = false);
    });
  }

  public approvePublisher(): void {
    this.loading = true;
    this.confirmTitle = CONFIRM_TITLE.APPROVE;
    this.confirmDescription = CONFIRM_DESCRIPTION.APPROVE;
    this.openModal(() => {
      this.publisherService.approvePublisher(this.publisher.profile.ownerId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.getPublisher();
        }, () => this.loading = false);
    });
  }

  public togglePublisherState( state: boolean): void {
    this.userService.toggleUserState(this.userType, this.publisher.profile.ownerId, state)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!res?.success) {
          return;
        }
        if (!state) {
          this.publisher.accountStatuses
            .splice(this.publisher.accountStatuses.findIndex((status) => status === ACCOUNT_STATUS.DEACTIVATED), 1);
          return;
        }
        this.publisher.accountStatuses.push(ACCOUNT_STATUS.DEACTIVATED);
      });
  }

  public back(): void {
    this.currentBill = null;
  }

  public goToPublishers(): void {
    this.router.navigate(['/' + this.userType]);
  }

  public openModal(callback: () => void): void {
    const dialogRef = this.dialog.open(this.confirm);
    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe((confirm: boolean) => {
        this.loading = false;
        if (confirm) {
          this.loading = true;
          callback();
        }
      });
  }

  public loginAs(): void {
    this.authService.loginToAccount(this.publisher.profile.ownerId);
  }

}

enum CONFIRM_TITLE {
  APPROVE= 'admin.account.confirm.approve.title',
  REJECT = 'admin.account.confirm.reject.title'
}

enum CONFIRM_DESCRIPTION {
  APPROVE= 'admin.account.confirm.approve.description',
  REJECT = 'admin.account.confirm.reject.description'
}
