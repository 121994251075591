<div class="card">
  <div class="card-header bg-white pb-0">
    <h2 class="title-level-3">Abonnent Übersicht</h2>
    <ano-button (click)="goToSubscriptions()" [config]="{
        type: 'button',
        classList: 'cursor-pointer width110px',
        styleType: 'primary'}"><i class="icon icon-back-arrow text-white cursor-pointer"></i> Zurück
    </ano-button>
  </div>
  <div class="card-body bg-white">
    <app-user-billing [accountId]="accountId" [userType]="userType" [config]="subscriptionsConfig"></app-user-billing>
<!--    <div class="panel" *ngFor="let config of tableConfigs">-->
<!--      <div class="panel-header pb-0" *ngIf="config.groupTitle">-->
<!--        <h2><b>{{config.groupTitle | localize}}</b></h2>-->
<!--      </div>-->
<!--      <div class="panel-body bg-white padding-top-20 padding-bottom-20">-->
<!--        <ano-bills-table [config]="config" [timeRangeConfig]="timeRangeConfig"></ano-bills-table>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>

<ng-template #dateTmpl let-data="rowData">
  <div class="long-text  center">
        <span>
            {{data.date | dateFormat: 'DD MMM YYYY'}}
        </span>
  </div>
</ng-template>
<ng-template #amountTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.amount| currency}}
        </span>
  </div>
</ng-template>

<ng-template #dataSizeTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.dataSize.value}} {{data.dataSize.unit}}
        </span>
  </div>
</ng-template>

<ng-template #renewalTmpl let-data="rowData">
  <div class="long-text">
    <span>{{data.prolongationActive ? 'Ja' : 'Nein'}}</span>
  </div>
</ng-template>

<ng-template #statusTmpl let-data="rowData">
  <div class="long-text">
    <span *ngIf="data?.chargeBack">Charged Back</span>
    <span *ngIf="!data?.chargeBack">
        {{ data?.prolongationActive ? ((data.status === BILL_STATUS.PAID ? 'bills.status.paid' : 'bills.status.unpaid') | localize) : 'Canceled'}}
    </span>
  </div>
</ng-template>

<ng-template #dateRangeTmpl let-data="rowData">
  <div class="range-col flex flex-column center" *ngIf="data.validity">
        <span>
            {{data.validity.startDate | dateFormat: 'DD MMM YYYY'}} -
            {{data.validity.endDate | dateFormat: 'DD MMM YYYY'}}
        </span>
  </div>
  <div class="range-col flex flex-column center" *ngIf="!data.validity">
    -
  </div>
</ng-template>
<ng-template #actionsTmpl let-data="rowData">
  <span (click)="goToDetails(data.productId)"><i class="icon icon-login text-primary cursor-pointer"></i></span>
</ng-template>

<ng-template #actionDownloadTmpl let-data="rowData">
  <span (click)="goToMultibitrateDetails(data.productId)"><i class="icon icon-login text-primary cursor-pointer"></i></span>
</ng-template>


<ng-template #mediaIdTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.mediaId | stringCut}}
        </span>
  </div>
</ng-template>
