import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TableComponent, PUBLISHER_FILTERS, INPUT_TYPES} from 'ui-elements';
import {IListFilterItem, ITableConfig, ITableFilters} from 'ui-elements';
import {ACCOUNT_STATUS, IPublisher} from '../../models/publisher.model';
import {IDropdownConfig} from '../../models/types';
import {PublisherService} from '../../services/publisher/publisher.service';
import {AutoDestroyService} from 'ui-elements';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';
import {take, takeUntil, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UserService} from '../../services/user/user.service';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.sass'],
  providers: [AutoDestroyService, PublisherService]
})
export class SubscriptionsComponent implements OnInit {
  public confirmTitle: CONFIRM_TITLE;
  public confirmDescription: CONFIRM_DESCRIPTION;
  public ACCOUNT_STATUS = ACCOUNT_STATUS;
  @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;
  @ViewChild('tableComponent', {static: false}) public table: TableComponent;
  @ViewChild('idTmpl', {static: true}) public idTmpl: TemplateRef<any>;
  @ViewChild('activeTmpl', {static: true}) public activeTmpl: TemplateRef<any>;
  @ViewChild('firstNameTmpl', {static: true}) public firstNameTmpl: TemplateRef<any>;
  @ViewChild('lastNameTmpl', {static: true}) public lastNameTmpl: TemplateRef<any>;
  @ViewChild('companyTmpl', {static: true}) public companyTmpl: TemplateRef<any>;
  @ViewChild('subscriptionTmpl', {static: true}) public subscriptionTmpl: TemplateRef<any>;
  @ViewChild('countryTmpl', {static: true}) public countryTmpl: TemplateRef<any>;
  @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
  @ViewChild('emailTmpl', {static: true}) public emailTmpl: TemplateRef<any>;
  @ViewChild('dateTmpl', {static: true}) public dateTmpl: TemplateRef<any>;
  @ViewChild('storageTmpl', {static: true}) public storageTmpl: TemplateRef<any>;
  @ViewChild('bandwidthTmpl', {static: true}) public bandwidthTmpl: TemplateRef<any>;
  @ViewChild('bandwidthUsedTmpl', {static: true}) public bandwidthUsedTmpl: TemplateRef<any>;
  @ViewChild('bandwidthLimitTmpl', {static: true}) public bandwidthLimitTmpl: TemplateRef<any>;
  @ViewChild('storageDateTmpl', {static: true}) public storageDateTmpl: TemplateRef<any>;
  @ViewChild('premiumDateTmpl', {static: true}) public premiumDateTmpl: TemplateRef<any>;
  @ViewChild('onlineStatus', {static: true}) public onlineStatus: TemplateRef<any>;
  @ViewChild('renewalTmpl', {static: true}) public renewalTmpl: TemplateRef<any>;
  @ViewChild('currencyTmpl', {static: true}) public currencyTmpl: TemplateRef<any>;
  @ViewChild('resetPassword', {static: false}) resetPassword: TemplateRef<any>;

  public tableConfig: ITableConfig<IPublisher>;
  public searchTerm: string;
  public actions: IDropdownConfig;
  public filters: ITableFilters;
  public queryFilters: IListFilterItem[];

  public INPUT_TYPES = INPUT_TYPES;
  public newPasswordForm: FormGroup = this.fb.group({
    password: [''],
  });

  constructor(
    private publisherService: PublisherService,
    private destroy$: AutoDestroyService,
    private router: Router,
    private authService: AuthService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {

    this.publisherService.getFilters({filterTypes: [PUBLISHER_FILTERS.PUBLISHER_TYPE, PUBLISHER_FILTERS.SUBSCRIPTION_TYPE]})
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        res.filterListItems[0].selectOptions.splice(res.filterListItems[0].selectOptions.findIndex((item) => item.key === 'FREE'), 1);
        // tslint:disable-next-line:max-line-length
        res.filterListItems[0].selectOptions.splice(res.filterListItems[0].selectOptions.findIndex((item) => item.key === 'WAITING_APPROVAL'), 1);
        this.filters = res;
      });
    this.tableConfig = {
      dataField: 'data',
      matPaginator: true,
      filtersWidth100: true,
      fullHeight: true,
      highlight: (data) => {
        if (data.user.waitingApproval) {
          return {warning: true};
        }
        if (data.user.accountStatuses.includes(ACCOUNT_STATUS.DEACTIVATED)) {
          return {deactivated: true};
        }
      },
      searchFn: (sortParams, pagingParams) => {
        if (!this.queryFilters?.length) {
          this.queryFilters = [{field: PUBLISHER_FILTERS.PUBLISHER_TYPE, value: ['BEGINNER', 'ADVANCED', 'PROFESSIONAL']}];
        }
        return this.publisherService.getSubscriptions(this.searchTerm, sortParams, pagingParams, this.queryFilters)
          .pipe(tap((res) => {
            this.mapToActions(res.results.data.items);
          }));
      },
      columns: [
        {
          name: 'actions',
          tmpl: this.actionsTmpl,
          class: 'dropdown-actions'
        },
        {
          name: 'Aktiv',
          tmpl: this.activeTmpl,
          width: 100,
          sortField: 'PUBLISHER_ACTIVE_STATE',
          class: 'left'
        },
        {
          name: 'Kunden Id',
          tmpl: this.idTmpl,
          sortField: 'ID',
          width: 100,
          class: 'left no-white-space'
        },
        {
          name: 'LIVE',
          tmpl: this.onlineStatus,
          width: 100,
          sortField: 'LIVE_STREAM',
          class: 'center'
        },
        {
          name: 'Bezahldatum',
          tmpl: this.dateTmpl,
          class: 'left',
          width: 150,
          sortField: 'LAST_PAYMENT_DATE'
        },
        {
          name: 'firstName',
          tmpl: this.firstNameTmpl,
          sortField: 'FIRST_NAME',
          width: 200,
          class: 'left'
        },
        {
          name: 'lastName',
          tmpl: this.lastNameTmpl,
          sortField: 'LAST_NAME',
          width: 200,
          class: 'left'
        },
        {
          name: 'email',
          tmpl: this.emailTmpl,
          sortField: 'EMAIL',
          width: 200,
          class: 'left'
        },
        {
          name: 'firm',
          tmpl: this.companyTmpl,
          sortField: 'COMPANY',
          width: 200,
          class: 'left'
        },
        {
          name: 'land',
          tmpl: this.countryTmpl,
          sortField: 'COUNTRY',
          width: 100,
          class: 'left'
        },
        {
          name: 'currency',
          tmpl: this.currencyTmpl,
          sortField: 'CURRENCY',
          class: 'left'
        },
        {
          name: 'Subscription type',
          tmpl: this.subscriptionTmpl,
          class: 'left',
          sortField: 'PREMIUM_SUBSCRIPTION_PLAN',
          width: 100
        },
        {
          name: 'Vetragslaufzeit',
          tmpl: this.premiumDateTmpl,
          width: 200,
          class: 'left'
        },
        {
          name: 'Vetragslaufzeit Speicherabo',
          tmpl: this.storageDateTmpl,
          width: 200,
          class: 'left'
        },
        {
          name: 'Update bandbreite',
          tmpl: this.bandwidthTmpl,
          sortField: 'HAS_BANDWIDTH',
          width: 100,
          class: 'left'
        },
        {
          name: 'Genutzte bandbreite',
          tmpl: this.bandwidthUsedTmpl,
          // sortField: 'HAS_BANDWIDTH',
          width: 100,
          class: 'left'
        },
        {
          name: 'Begrenzung der Bandbreite',
          tmpl: this.bandwidthLimitTmpl,
          // sortField: 'HAS_BANDWIDTH',
          width: 100,
          class: 'left'
        },
        {
          name: 'Renewal',
          tmpl: this.renewalTmpl,
          width: 100,
          class: 'left'
        }
      ]

    };
  }

  public togglePublisherState(data: IPublisher, state: boolean): void {
    this.publisherService.togglePublisherState(data.accountId, state)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!res?.success) {
          return;
        }
        if (!state) {
          data.user.accountStatuses.splice(data.user.accountStatuses.findIndex((status) => status === ACCOUNT_STATUS.DEACTIVATED), 1);
          return;
        }
        data.user.accountStatuses.push(ACCOUNT_STATUS.DEACTIVATED);
      });
  }

  public search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.table.resetTableData();
    this.table.refreshData({});
  }

  public openPublisherDetails(id: string): void {
    this.router.navigate([`subscriptions/publisher-details/${id}`]);
  }

  private mapToActions(res: IPublisher[]): void {
    this.actions = {};
    res.forEach((publisher) => {
      this.actions[publisher.accountId] = [
        {
          id: publisher.accountId,
          key: 'Login to account',
          value: 'Login to account',
          callback: () => {
            this.authService.loginToAccount(publisher.accountId);
          }
        },
        {
          id: publisher.accountId,
          key: 'Abonnent Übesicht',
          value: 'Abonnent Übesicht',
          callback: () => {
            this.router.navigate(['/subscriptions/' + publisher.accountId]);
          }
        },
        {
          id: publisher.accountId,
          key: 'Show details and changes',
          value: 'Show details and changes',
          callback: () => this.openPublisherDetails(publisher.accountId)
        },
        {
          id: publisher.accountId,
          key: 'Change password',
          value: 'Change password',
          callback: () => this.openInputModal(() => {
            const pass = this.newPasswordForm.get('password').value;
            if (pass) {
              this.userService.changePassword(publisher.user.email, pass)
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                  this.table.refreshPage();
                });
            }
          })
        },
      ];
      if (!publisher.user.waitingApproval && publisher?.user?.accountStatuses?.includes(ACCOUNT_STATUS.DEACTIVATED)) {
        const activateActions = [
          {
            key: 'Activate publisher',
            value: 'Activate publisher',
            callback: () => {
              this.confirmTitle = CONFIRM_TITLE.ACTIVATE;
              this.confirmDescription = CONFIRM_DESCRIPTION.ACTIVATE;
              this.openModal(() => {
                this.publisherService.togglePublisherState(publisher.user.accountId, false)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe(() => {
                    this.table.refreshPage();
                  });
              });
            }
          }];
        activateActions.forEach((action) => this.actions[publisher.accountId].push(action));
      }

      if (!publisher.user.waitingApproval && !publisher?.user?.accountStatuses?.includes(ACCOUNT_STATUS.DEACTIVATED)) {
        const deactivateActions = [
          {
            key: 'Deactivate publisher',
            value: 'Deactivate publisher',
            callback: () => {
              this.confirmTitle = CONFIRM_TITLE.DEACTIVATE;
              this.confirmDescription = CONFIRM_DESCRIPTION.DEACTIVATE;
              this.openModal(() => {
                this.publisherService.togglePublisherState(publisher.user.accountId, true)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe(() => {
                    this.table.refreshPage();
                  });
              });
            }
          }];
        deactivateActions.forEach((action) => this.actions[publisher.accountId].push(action));
      }
    });
  }

  public openInputModal(callback: () => void): void {
    this.newPasswordForm.reset();
    const dialogRef = this.dialog.open(this.resetPassword);

    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe((confirm: boolean) => {
          if (confirm) {
            callback();
          }
          this.newPasswordForm.reset();
      });
  }

  public onFilter(filters: IListFilterItem[]): void {
    this.queryFilters = filters.filter((filter) => filter.value);
    this.table.resetTableData();
    this.table.refreshData({});
  }

  public openModal(callback: () => void): void {
    const dialogRef = this.dialog.open(this.confirm);
    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe((confirm: boolean) => {
        if (confirm) {
          callback();
        }
      });
  }

}

enum CONFIRM_TITLE {
  APPROVE= 'admin.account.confirm.approve.title',
  REJECT = 'admin.account.confirm.reject.title',
  ACTIVATE = 'admin.account.activation.activate.title',
  DEACTIVATE = 'admin.account.activation.deactivate.title',
}

enum CONFIRM_DESCRIPTION {
  APPROVE= 'admin.account.confirm.approve.description',
  REJECT = 'admin.account.confirm.reject.description',
  ACTIVATE = 'admin.account.activation.activate.description',
  DEACTIVATE = 'admin.account.activation.deactivate.description',
}
