<div class="card">
  <div class="card-body bg-white">
    <ano-table #tableComponent
               [withSearch]="true"
               [timeRangeConfig]="timeRangeConfig"
               (timeRangeFilter$)="timeRangeFilter($event)"
               [tableConfig]="tableConfig"
               [showDatepicker]="true"
               (search$)="search($event)"
               [filters]="filters"
               (filter$)="onFilter($event)">
<!--               (search$)="search($event)"-->
<!--    >-->
    </ano-table>
  </div>
</div>

<ng-template #firstNameTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.firstName || '-'}}
        </span>
  </div>
</ng-template>

<ng-template #lastNameTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.lastName || '-'}}
        </span>
  </div>
</ng-template>

<ng-template #invoiceNumberTmpl let-data="rowData">
  <div class="long-text">
    <span>{{ data.invoiceNumber }}</span>
  </div>
</ng-template>

<ng-template #transactionTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{(data.transactionId | stringCut) || '-'}}
        </span>
  </div>
</ng-template>

<ng-template #titleTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data?.productInfo?.mediaTitle}}
        </span>
  </div>
</ng-template>

<ng-template #publisherTmpl let-data="rowData">
  <div class="long-text">
        <a *ngIf="data?.productInfo?.publisherId?.internalId" class="cursor-pointer" href="javascript:void(0)" (click)="goToPublisher(data?.productInfo?.publisherId?.internalId)">
            {{data?.productInfo?.publisherId?.internalId | stringCut}}
        </a>
    <ng-container *ngIf="!data?.productInfo?.publisherId?.internalId">
      <span>-</span>
    </ng-container>
  </div>
</ng-template>

<ng-template #emailTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data?.email || '-'}}
        </span>
  </div>
</ng-template>

<ng-template #accessTmpl let-data="rowData">
  <div class="long-text">
      <span>{{('admin.views.access.' + data?.productAccess?.accessType) | localize}}</span>
    <ng-container *ngIf="data?.productAccess?.accessData?.freeVoucher">
      Free
    </ng-container>
    <ng-container *ngIf="data?.productAccess?.accessData?.priceDiscount?.type === 'FIXED_PRICE'">
      {{{amount: data?.productAccess?.accessData?.priceDiscount?.value, currency: data?.productAccess?.accessData?.price?.currency} | currency }}
    </ng-container>
    <ng-container *ngIf="data?.productAccess?.accessData?.priceDiscount?.type === 'PERCENT' && data?.productAccess?.accessData?.priceDiscount?.value < 100">
      {{data?.productAccess?.accessData?.priceDiscount?.value}}%
    </ng-container>
  </div>
</ng-template>

<ng-template #priceTmpl let-data="rowData">
  <div class="long-text">
    <ng-container *ngIf="!data?.productAccess?.accessData?.price?.amount">
      <span>-</span>
    </ng-container>
    <ng-container *ngIf="data?.productAccess?.accessData?.price?.amount">
      <span>
            {{{amount: data?.productAccess?.accessData?.price?.amount, currency: data?.productAccess?.accessData?.price?.currency} | currency }}
        </span>
    </ng-container>
  </div>
</ng-template>
<ng-template #dateTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.date | dateFormat: 'DD MMM YYYY'}}
        </span>
  </div>
</ng-template>

<ng-template #actionsTmpl let-data="rowData">
  <div class="table-actions-list">
    <app-action-dropdown *ngIf="actions" [values]="actions[data._purchaseId]" [placeholder]="'Select action'"></app-action-dropdown>
    <ano-loader [isOpen]="actionLoaders[data.purchaseId]"></ano-loader>
  </div>
</ng-template>

<ng-template #mediaIdTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.productInfo.mediaId | stringCut}}
        </span>
  </div>
</ng-template>

