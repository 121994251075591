import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ActivationEnd, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {AutoDestroyService, cutString, getFileNameFromResponseContentDisposition, IFormFieldsConfig, INPUT_TYPES} from 'ui-elements';
import {IMediaTransactionInfo, MultibitrateService} from '../../services/multibitrate/multibitrate.service';
import {IPublisher, PAGE_TYPE} from '../../models/publisher.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {saveAs} from 'file-saver/FileSaver';
import {ViewsService} from '../../services/views/views.service';
import {PublisherService} from '../../services/publisher/publisher.service';

@Component({
  selector: 'app-multibitrate-details',
  templateUrl: './multibitrate-details.component.html',
  styleUrls: ['./multibitrate-details.component.sass'],
  providers: [AutoDestroyService]
})
export class MultibitrateDetailsComponent implements OnInit {
  public leftForm: FormGroup = this.fb.group({
    firstName: [''],
    lastName: [''],
    company: [''],
    accountId: [''],
    mediaId: [''],
    title: [''],
  });
  public leftConfig: IFormFieldsConfig[] = [
    {
      name: 'firstName',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Name',
        placeholder: '',
      }
    },
    {
      name: 'lastName',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Vorname',
        placeholder: '',
      }
    },
    {
      name: 'company',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Firma',
        placeholder: '',
      }
    },
    {
      name: 'accountId',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Kunden ID',
        placeholder: '',
      }
    },
    {
      name: 'mediaId',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Media ID',
        placeholder: '',
      }
    },
    {
      name: 'title',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Title',
        placeholder: '',
      }
    },
  ];
  public rightForm: FormGroup = this.fb.group({
    transactionId: [''],
    price: ['']
  });
  public rightConfig: IFormFieldsConfig[] = [
    {
      name: 'transactionId',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Rechnungsnummer',
        placeholder: '',
      }
    },
    {
      name: 'price',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Summe',
        placeholder: '',
      }
    }
  ];
  public viewLoading: boolean;
  public viewDone: boolean;
  public download: boolean;
  public downloadDone: boolean;
  public sendLoading: boolean;
  public sendDone: boolean;
  private mbId: string;
  private backUrl: string;
  public data: IMediaTransactionInfo;
  public media: any;
  public publisher: IPublisher;

  constructor(
    private mbService: MultibitrateService,
    private activatedRoute: ActivatedRoute,
    private destroy$: AutoDestroyService,
    private router: Router,
    private fb: FormBuilder,
    private viewsService: ViewsService,
    private publisherService: PublisherService
  ) {
    this.router.events.pipe(
      takeUntil(this.destroy$),
      filter((event) => event instanceof ActivationEnd)
    )
      .subscribe((res: ActivationEnd) =>  {
        if (!res.snapshot?.data.hasOwnProperty('pageType')) {
          return;
        }
        this.backUrl = res.snapshot.data.pageType === PAGE_TYPE.SUBSCRIPTION ? 'subscriptions' : 'publisher';
      });
  }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(takeUntil(this.destroy$))
      .subscribe(({mbId}) => {
        this.mbId = mbId;
        this.mbService.getPurchase(this.mbId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => {
            this.data = res;
            this.mbService.getMedia(this.mbId)
              .pipe(takeUntil(this.destroy$))
              .subscribe((media) => {
                this.media = media;
                this.publisherService.getPublisher(this.data.accountId)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe((publisher) => {
                    this.publisher = publisher;
                    this.mapToForms();
                  });
              });
          });
      });
  }

  public back(): void {
    this.activatedRoute.parent.params.pipe(takeUntil(this.destroy$)).subscribe(({id}) => {
      this.router.navigate([`${this.backUrl}/${id}`]);
    });
  }

  public downloadPdf(): void {
    this.download = true;
    this.viewsService.downloadPdf(this.mbId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.download = false;
        this.downloadDone = true;
        setTimeout(() => this.downloadDone = false, 5000);
        saveAs(
          res.body,
          getFileNameFromResponseContentDisposition(res)
        );
      }, () => this.download = false);
  }

  public viewPdf(): void {
    this.viewLoading = true;
    this.viewsService.downloadPdf(this.mbId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.viewLoading = false;
        this.viewDone = true;
        setTimeout(() => this.viewDone = false, 5000);
        const fileURL = URL.createObjectURL(new Blob([res.body], {type: 'application/pdf'}));
        window.open(fileURL);
      }, () => this.viewLoading = false);
  }

  public sendPdf(): void {
    this.sendLoading = true;
    this.viewsService.sendPdf(this.mbId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.sendLoading = false;
        this.sendDone = true;
        setTimeout(() => this.sendDone = false, 5000);
      }, () => this.sendLoading = false);
  }

  private mapToForms(): void {
    this.leftForm.get('firstName').patchValue(this.data.firstName);
    this.leftForm.get('lastName').patchValue(this.data.lastName);
    this.leftForm.get('company').patchValue(this.publisher?.profile?.companyInfo?.company || '-');
    this.leftForm.get('accountId').patchValue(cutString(this.data.accountId, 0, 8));
    this.leftForm.get('mediaId').patchValue(cutString(this.media.id, 0, 8));
    this.leftForm.get('title').patchValue(this.media.title);
    this.rightForm.get('transactionId').patchValue(cutString(this.data.billNumber, 0, 8));
    this.rightForm.get('price').patchValue('EUR ' + this.data.totalCost);
  }

}
