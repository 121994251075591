<div class="card">
  <div class="card-body bg-white">
    <!--<div class="table-wrapper-border">-->
    <ano-table #tableComponent
               [withSearch]="true"
               [tableConfig]="tableConfig"
               [filters]="filters"
               (search$)="search($event)"
               (filter$)="onFilter($event)"
    >
    </ano-table>
    <!--</div>-->
  </div>
</div>

<ng-template #idTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.user.accountId | stringCut }}
        </span>
  </div>
</ng-template>

<ng-template #dateTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.lastPremiumSubscriptionPaymentDate ? (data.lastPremiumSubscriptionPaymentDate | dateFormat: 'DD MMM YYYY') : '-' }}
        </span>
  </div>
</ng-template>
<ng-template #firstNameTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.user.firstName}}
        </span>
  </div>
</ng-template>

<ng-template #lastNameTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.user.lastName}}
        </span>
  </div>
</ng-template>

<ng-template #emailTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.user.email}}
        </span>
  </div>
</ng-template>

<ng-template #subscriptionTmpl let-data="rowData">
  <div class="long-text">
    <ng-container *ngIf="!data.user.waitingApproval">
      <span *ngIf="!data.subscriptionInfo.hasAnySubscription">
          {{'admin.publisher.type.free' | localize}}
      </span>
      <ng-container *ngIf="data.subscriptionInfo.hasAnySubscription">
        <span>{{('admin.publisher.type.' + data.subscriptionInfo.premiumSubscriptionType.toLowerCase()) | localize}}</span>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="data.user.waitingApproval">
      <span>{{'admin.publisher.type.waitingApproval' | localize}}</span>
    </ng-container>
  </div>
</ng-template>

<ng-template #companyTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.user.companyName || '-'}}
        </span>
  </div>
</ng-template>

<ng-template #countryTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{(data.user.countryCode | country) || '-'}}
        </span>
  </div>
</ng-template>

<ng-template #onlineStatus let-data="rowData">
  <div class="flex justify-content-center">
    <div class="status-circle" [class.success-circle]="data.liveStream" [class.danger-circle]="!data.liveStream"></div>
  </div>
</ng-template>

<ng-template #activeTmpl let-data="rowData">
  <span *ngIf="data.user.waitingApproval">{{'admin.publisher.type.waitingApproval' | localize}}</span>

  <span *ngIf="!data.user.waitingApproval">
        {{data.user.accountStatuses.includes(ACCOUNT_STATUS.DEACTIVATED) ? 'Deactivated' : 'Active'}}
    </span>
</ng-template>

<ng-template #actionsTmpl let-data="rowData">
  <div class="table-actions-list">
    <app-action-dropdown *ngIf="actions" [values]="actions[data.user.accountId]" [placeholder]="'Select action'"></app-action-dropdown>
  </div>
</ng-template>

<ng-template #storageTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data?.storageSpaceSubscription?.active ? 'JA' : 'NEIN'}}
        </span>
  </div>
</ng-template>

<ng-template #bandwidthTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data?.bandwidthSubscriptions?.length ? 'JA' : 'NEIN'}}
        </span>
  </div>
</ng-template>

<ng-template #bandwidthUsedTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data?.dataLimits?.bandwidth?.used.value}} {{data?.dataLimits?.bandwidth?.used.unit}}
        </span>
  </div>
</ng-template>

<ng-template #bandwidthLimitTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data?.dataLimits?.bandwidth?.limit.value}} {{data?.dataLimits?.bandwidth?.limit.unit}}
        </span>
  </div>
</ng-template>

<ng-template #renewalTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data?.premiumSubscription?.prolongationActive ? 'JA' : 'NEIN'}}
        </span>
  </div>
</ng-template>

<ng-template #premiumDateTmpl let-data="rowData">
  <div class="long-text center">
    <ng-container *ngIf="data?.premiumSubscription?.active">
      <span style="display: block">{{data.premiumSubscription.startDate | dateFormat: 'DD MMM YYYY'}} bis {{data.premiumSubscription.endDate | dateFormat: 'DD MMM YYYY'}}</span>
    </ng-container>
    <ng-container *ngIf="!data?.premiumSubscription?.active">
      <span>-</span>
    </ng-container>
  </div>
</ng-template>

<ng-template #storageDateTmpl let-data="rowData">
  <div class="long-text">
<!--    <ng-container *ngIf="data?.storageSpaceSubscription?.active">-->
<!--      <span>{{data.storageSpaceSubscription.startDate | dateFormat: 'DD MMM YYYY'}} bis {{data.storageSpaceSubscription.endDate | dateFormat: 'DD MMM YYYY'}} </span>-->
<!--    </ng-container>-->
<!--    <ng-container *ngIf="!data?.storageSpaceSubscription?.active">-->
<!--      <span>-</span>-->
<!--    </ng-container>-->
    <span>{{data?.storageSpaceSubscriptions?.length || '-'}}</span>
  </div>
</ng-template>

<ng-template #currencyTmpl let-data="rowData">
  <div class="long-text">
    <span>{{data?.user?.currency || '-' }}</span>
  </div>
</ng-template>


<ng-template #resetPassword let-dialogRef="dialogRef">
  <ano-modal [dialogRef]="dialogRef" [title]="confirmTitle | localize" [isConfirmation]="true">
    {{'new.password' }}
    <form [formGroup]="newPasswordForm">
      <div class="form-inputs">
        <div class="form-group">
          <ano-input
            formControlName="password"
            [isEditMode]="true"
            [config]="{ inputType: INPUT_TYPES.PASSWORD, type: 'password', placeholder: 'Password', isRequired: true }">
          </ano-input>
        </div>
      </div>
    </form>
  </ano-modal>
</ng-template>

<ng-template #confirm let-dialogRef="dialogRef">
  <ano-modal [dialogRef]="dialogRef" [title]="confirmTitle | localize" [isConfirmation]="true">
    {{confirmDescription | localize}}
  </ano-modal>
</ng-template>
