import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideosComponent } from './videos.component';
import {SharedModule} from '../../modules/shared.module';



@NgModule({
  declarations: [VideosComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class VideosModule { }
