export const getQueryParamsFromUrl = (url: string): { [key: string]: string }  => {
  const queryParams = url.split('?')[1];

  if (!queryParams) { return {} as any ; }

  const parsedQueryParams = queryParams.split('&').map(param => param.split('='));

  if (!parsedQueryParams) { return {} as any; }

  const queryParamsObject = {};
  parsedQueryParams.forEach(([key, value]) => queryParamsObject[key] = value);
  return queryParamsObject;
};
