import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  BILL_STATUS,
  FILTER_MEDIA_TYPES, getFileNameFromResponseContentDisposition,
  IBill,
  IBillsTableConfig, IMediaTimerange, ITableColumn, ITimeRangeConfig,
  MEDIA_FILTERS_FIELDS,
  STREAM_STATUS, TEMPLATE_ID
} from 'ui-elements';
import {SubscriptionsConfig} from './config/subscriptions-config';
import {takeUntil} from 'rxjs/operators';
import {AutoDestroyService} from 'ui-elements';
import {ActivatedRoute, Router} from '@angular/router';
import {BillsService} from '../../../../services/bills/bills.service';
import {ACCOUNT_PAGE_TYPE} from '../../../../services/user/user.service';
import {PublisherService} from '../../../../services/publisher/publisher.service';
import {saveAs} from 'file-saver/FileSaver';

@Component({
  selector: 'app-subscriptions-list',
  templateUrl: './subscriptions-list.component.html',
  styleUrls: ['./subscriptions-list.component.sass'],
  providers: [AutoDestroyService, SubscriptionsConfig, BillsService]
})
export class SubscriptionsListComponent implements OnInit {
  public BILL_STATUS = BILL_STATUS;
  public userType = ACCOUNT_PAGE_TYPE.SUBSCRIPTIONS;
  @ViewChild('dateTmpl', {static: true}) public dateTmpl: TemplateRef<any>;
  @ViewChild('amountTmpl', {static: true}) public amountTmpl: TemplateRef<any>;
  @ViewChild('dataSizeTmpl', {static: true}) public dataSizeTmpl: TemplateRef<any>;
  @ViewChild('dateRangeTmpl', {static: true}) public dateRangeTmpl: TemplateRef<any>;
  @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
  @ViewChild('actionDownloadTmpl', {static: true}) public actionDownloadTmpl: TemplateRef<any>;
  @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
  @ViewChild('renewalTmpl', {static: true}) public renewalTmpl: TemplateRef<any>;
  @ViewChild('mediaIdTmpl', {static: true}) public mediaIdTmpl: TemplateRef<any>;
  public accountId: string;
  public tableConfigs: IBillsTableConfig<IBill>[];
  public timeRangeConfig: ITimeRangeConfig;
  private ranges: IMediaTimerange[] = [
    {
      year: 2020,
      month: 'September',
      timeRange: {
        start: 1598907600000,
        end: 1601499599999
      }
    },
    {
      year: 2020,
      month: 'Oktober',
      timeRange: {
        start: 1601499600000,
        end: 1604181599999
      }
    },
    {
      year: 2020,
      month: 'November',
      timeRange: {
        start: 1604181600000,
        end: 1606773599999
      }
    },
    {
      year: 2020,
      month: 'Dezember',
      timeRange: {
        start: 1606773600000,
        end: 1609451999999
      }
    }
  ];
  isPdfLoading = false;
  private pdfDownloadCurrentId: number;

  constructor(
    public subscriptionsConfig: SubscriptionsConfig,
    private destroy$: AutoDestroyService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private publisherService: PublisherService
    ) { }

  ngOnInit(): void {
    this.timeRangeConfig = {
      fetchMethod: (filters) => this.subscriptionsConfig.getTimeRanges(),
      filters: null,
      withoutFilters: true
    };
    this.activatedRoute.params.pipe(takeUntil(this.destroy$))
      .subscribe(({id}) => {
        this.accountId = id;
        this.resolveBillsTableConfigs();
      });
  }

  private resolveBillsTableConfigs(): void {
    this.subscriptionsConfig.getConfig(this.accountId).pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.tableConfigs = res;
        this.addTemplatesToConfigs();
      });
  }

  public downloadPdf(bill: IBill): void {
    if (this.isPdfLoading) {
      return;
    }
    this.pdfDownloadCurrentId = bill.purchaseId;

    this.isPdfLoading = true;

    this.publisherService.downloadPdf(bill.purchaseId.toString())
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.isPdfLoading = false;
        saveAs(
          res.body,
          getFileNameFromResponseContentDisposition(res)
        );
      }, () => this.isPdfLoading = false);
  }

  public goToSubscriptions(): void {
    this.router.navigate(['subscriptions']);
  }

  public goToDetails( id: string): void {
    this.router.navigate(['subscriptions/' + this.accountId + '/details/' + id]);
  }

  public goToMultibitrateDetails( id: string): void {
    this.router.navigate(['subscriptions/' + this.accountId + '/multibitrate/details/' + id]);
  }

  private addTemplatesToConfigs(): void {
    this.tableConfigs.forEach((config: IBillsTableConfig<IBill>) => {
      config.tableConfig.columns.forEach((column: ITableColumn) => {
        if (column?.tmplId === TEMPLATE_ID.DATE) {
          column.tmpl = this.dateTmpl;
        }

        if (column?.tmplId === TEMPLATE_ID.PRICE) {
          column.tmpl = this.amountTmpl;
        }

        if (column?.tmplId === TEMPLATE_ID.VALUE_UNIT) {
          column.tmpl = this.dataSizeTmpl;
        }

        if (column?.tmplId === TEMPLATE_ID.DATE_RANGE) {
          column.tmpl = this.dateRangeTmpl;
        }

        if (column?.tmplId === TEMPLATE_ID.ACTIONS) {
          column.tmpl = this.actionsTmpl;
        }

        if (column?.tmplId === TEMPLATE_ID.STATUS) {
          column.tmpl = this.statusTmpl;
        }

        if (column?.tmplId === TEMPLATE_ID.PROLONGATION) {
          column.tmpl = this.renewalTmpl;
        }

        if (column?.tmplId === TEMPLATE_ID.MEDIA_ID) {
          column.tmpl = this.mediaIdTmpl;
        }

        if (column?.tmplId === TEMPLATE_ID.ACTION_DOWNLOAD) {
          column.tmpl = this.actionDownloadTmpl;
        }
      });
    });
  }

}
