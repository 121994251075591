import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {IBill, ITableConfig, LocalizationProvider, TableComponent} from 'ui-elements';
import {of} from 'rxjs';
import {SubscriptionManageService} from '../../../services/subscription-manage/subscription-manage.service';
import {flatMap} from 'rxjs/operators';
import {ACCOUNT_PAGE_TYPE} from '../../../services/user/user.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-current-subscription',
  templateUrl: './current-subscription.component.html',
  styleUrls: ['./current-subscription.component.sass']
})
export class CurrentSubscriptionComponent implements OnInit {
  @Input() id: string;
  @Input() userType: ACCOUNT_PAGE_TYPE;
  @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
  @ViewChild('subscriptionPaymentTmpl', {static: true}) public subscriptionPaymentTmpl: TemplateRef<any>;
  @ViewChild('nextPaymentTmpl', {static: true}) public nextPaymentTmpl: TemplateRef<any>;
  @ViewChild('tariffPlanTmpl', {static: true}) public tariffPlanTmpl: TemplateRef<any>;
  @ViewChild('dateTmpl', {static: true}) public dateTmpl: TemplateRef<any>;
  @ViewChild('manualTmpl', {static: true}) public manualTmpl: TemplateRef<any>;
  @ViewChild('subscriptionsTable', {static: true}) public subscriptionsTable: TableComponent;
  @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;
  @Output() subscriptionEmitter = new EventEmitter<any>();

  tableConfig: ITableConfig<any>;

  constructor(
    private subscriptionManageService: SubscriptionManageService,
    private localizationProvider: LocalizationProvider,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.tableConfig = {
      dataField: 'data',
      matPaginator: true,
      searchFn: (sortParams, pagingParams) => this.subscriptionManageService.getCurrentSubscription(this.id, null, pagingParams, sortParams).pipe(flatMap((subscriptionData) => {
        subscriptionData.results.data.items = subscriptionData.results.data.items.map((subscription) => {
          subscription.tariffPlan = this.localize(this.subscriptionManageService.getSubscriptionTypeTranslate(subscription.type)) + ' '
            + this.localize(this.subscriptionManageService.getSubscriptionPlanTypeTranslate(subscription.planType));
          subscription.period = this.localize(this.subscriptionManageService.getSubscriptionPeriodTranslate(subscription.period));
          return subscription;
        });
        this.subscriptionEmitter.emit(subscriptionData.results.data.items);
        return of(subscriptionData);
      })),
      columns: [
        {
          name: 'subscription-manage.table.headers.current-subscriptions',
          tmpl: this.tariffPlanTmpl
          // dataField: 'tariffPlan',
        },
        {
          name: 'subscription-manage.table.headers.payment-method',
          tmpl: this.subscriptionPaymentTmpl
        },
        {
          name: 'subscription-manage.table.headers.next-payment',
          tmpl: this.nextPaymentTmpl,
        },
        {
          name: 'subscription-manage.table.headers.expiry-date',
          // date: true,
          // dataField: 'expireDate'
          tmpl: this.dateTmpl
        },
        {
          name: 'subscription-manage.table.subscription-type.type',
          tmpl: this.manualTmpl
        },
        {
          name: 'streams.title.table.column.action',
          tmpl: this.actionsTmpl,
          class: 'right'
        }
      ]
    };

    this.tableConfig.columns.find((column) => column.name === 'streams.title.table.column.action').tmpl = this.actionsTmpl;
    this.tableConfig.columns.find((column) => column.name === 'subscription-manage.table.headers.payment-method').tmpl = this.subscriptionPaymentTmpl;
    this.tableConfig.columns.find((column) => column.name === 'subscription-manage.table.headers.next-payment').tmpl = this.nextPaymentTmpl;
    this.tableConfig.columns.find((column) => column.name === 'subscription-manage.table.headers.current-subscriptions').tmpl = this.tariffPlanTmpl;
    this.tableConfig.columns.find((column) => column.name === 'subscription-manage.table.headers.expiry-date').tmpl = this.dateTmpl;
  }

  localize(key: string): string {
    if (!key) {
      return '';
    }
    return this.localizationProvider.getByKey(key);
  }

  public goToBillDetails(data: any): void {
    this.router.navigate([this.userType + '/' + this.id + '/details/' + data.id]);
  }

  public refreshSubscriptions(): void {
    this.subscriptionsTable.refreshData({});
  }

}
