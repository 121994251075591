<ng-container *ngIf="!currentBill">
  <div class="card">
    <div class="card-header bg-white pb-0">
      <div class="row row-padding-10">
        <div class="col-8 mr-auto">
          <div class="row row-padding-10">
            <div class="col">
              <h2 class="title-level-3">{{publisher?.profile?.firstName}}  {{publisher?.profile?.lastName}}</h2>
              <ano-button (click)="loginAs()" [config]="{
                type: 'button',
                classList: 'cursor-pointer',
                styleType: 'link'}" [isDisabled]="publisher?.accountStatuses.includes(ACCOUNT_STATUS.DEACTIVATED)"><i class="icon icon-user"></i> Login to account
              </ano-button>
            </div>
            <div class="col-auto">
              <div class="row row-padding-10">
                <ng-container *ngIf="publisher?.accountStatuses.includes(ACCOUNT_STATUS.APPROVED) || userType === ACCOUNT_PAGE_TYPE.VIEWER">
                  <div class="col-auto">
                    <span class="text-active">Active</span>
                  </div>
                  <div class="col-auto">
                    <form [formGroup]="activateForm">
                      <mat-slide-toggle [formControlName]="'active'" class="video-activation-toggle"></mat-slide-toggle>
                    </form>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <ano-button (click)="goToPublishers()" [config]="{
            type: 'button',
            classList: 'cursor-pointer width110px',
            styleType: 'primary'}"><i class="icon icon-back-arrow text-white cursor-pointer"></i> Zurück
          </ano-button>
        </div>
      </div>
    </div>
    <div class="card-body bg-white">
      <ano-loader [isOpen]="loading"></ano-loader>
      <div class="row">
        <div class="col-8">
          <div class="btn-group-container mb-10">
            <ng-container *ngIf="publisher && userType !== ACCOUNT_PAGE_TYPE.VIEWER">
              <div class="row row-padding-5 align-items-center">
                <ng-container *ngIf="!publisher?.accountStatuses.includes(ACCOUNT_STATUS.APPROVED)">
                  <div class="col">
                    <div class="alert alert-info-box alert-block alert-icon alert-warning mb-0" role="alert">
                      <i class="icon icon-info"></i>
                      Confirmation
                    </div>
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-success btn-lg" (click)="approvePublisher()">Approve</button>
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-danger btn-lg" (click)="rejectPublisher()">Reject</button>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <ano-form *ngIf="form"
                    [forcedViewMode]="true"
                    [form]="form"
                    [fieldsConfig]="config"
                    [withoutSave]="true" [materialForm]="true" [isLightForm]="true">
          </ano-form>
        </div>
      </div>
<!--      <div class="panel configs-panel" *ngFor="let config of billsTableConfigs">-->
<!--        <div class="panel-header pb-0" *ngIf="config.groupTitle">-->
<!--          <h2><b>{{config.groupTitle | localize}}</b></h2>-->
<!--        </div>-->
<!--        <div class="panel-body bg-white padding-top-20 padding-bottom-20">-->
<!--          <ano-bills-table [config]="config" [timeRangeConfig]="timeRangeConfig"></ano-bills-table>-->
<!--        </div>-->
<!--      </div>-->

      <div class="bills-container">
        <app-user-billing *ngIf="publisher"
                          [limitOff]="publisher.limitOff"
                          [hideBandwidthLimit]="publisher.profile.hiddenDataLimits"
                          [accountId]="publisher.profile.ownerId"
                          [userType]="userType"
                          [config]="billsConfig"
        ></app-user-billing>
      </div>
    </div>
  </div>
</ng-container>

<!--<ng-template #dateTmpl let-data="rowData">-->
<!--  <div class="long-text">-->
<!--    <span>-->
<!--      {{data.date | dateFormat: 'DD MMM YYYY'}}-->
<!--    </span>-->
<!--  </div>-->
<!--</ng-template>-->

<!--<ng-template #amountTmpl let-data="rowData">-->
<!--  <div class="long-text">-->
<!--    <span>-->
<!--      {{data.amount| currency}}-->
<!--    </span>-->
<!--  </div>-->
<!--</ng-template>-->

<!--<ng-template #dataSizeTmpl let-data="rowData">-->
<!--  <div class="long-text">-->
<!--    <span>-->
<!--      {{data.dataSize.value}} {{data.dataSize.unit}}-->
<!--    </span>-->
<!--  </div>-->
<!--</ng-template>-->

<!--<ng-template #statusTmpl let-data="rowData">-->
<!--  <div class="long-text">-->
<!--    <span>-->
<!--      {{ (data.status === BILL_STATUS.PAID ? 'bills.status.paid' : 'bills.status.unpaid') | localize}}-->
<!--    </span>-->
<!--  </div>-->
<!--</ng-template>-->

<!--<ng-template #dateRangeTmpl let-data="rowData">-->
<!--  <div class="range-col flex flex-column center" *ngIf="data.validity">-->
<!--    <span>-->
<!--      {{data.validity.startDate | dateFormat: 'DD.MM.YYYY'}} - -->
<!--    </span>-->
<!--    <span>-->
<!--      {{data.validity.endDate | dateFormat: 'DD.MM.YYYY'}}-->
<!--    </span>-->
<!--  </div>-->
<!--  <div class="range-col flex flex-column center" *ngIf="!data.validity">-->
<!--    - -->
<!--  </div>-->
<!--</ng-template>-->

<!--<ng-template #actionsTmpl let-data="rowData">-->
<!--  <span (click)="goToBillDetails(data)"><i class="icon icon-login text-primary cursor-pointer"></i></span>-->
<!--</ng-template>-->

<ng-template #confirm let-dialogRef="dialogRef">
  <ano-modal [dialogRef]="dialogRef" [title]="confirmTitle | localize" [isConfirmation]="true">
    {{confirmDescription | localize}}
  </ano-modal>
</ng-template>
