<div class="card">
  <div class="card-body bg-white">
    <ano-table #tableComponent [tableConfig]="tableConfig"
               [withSearch]="true"
               (search$)="search($event)">
    </ano-table>
  </div>
</div>


<ng-template #idTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.media.accountId.internalId | stringCut}}
        </span>
  </div>
</ng-template>

<ng-template #videoIdTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.media.id | stringCut}}
        </span>
  </div>
</ng-template>

<ng-template #updatedTmpl let-data="rowData">
  <div class="long-text">
    <span style="display: block">{{data?.media.video.updated  | dateFormat: 'DD MMM YYYY'}}, {{data?.media.updated  | dateTimeFormat: 'HH:mm'}}</span>
  </div>
</ng-template>

<ng-template #titleTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.media.title}}
        </span>
  </div>
</ng-template>

<ng-template #firstNameTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.user.firstName}}
        </span>
  </div>
</ng-template>

<ng-template #lastNameTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.user.lastName}}
        </span>
  </div>
</ng-template>

<ng-template #companyTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.user.companyName || '-'}}
        </span>
  </div>
</ng-template>

<ng-template #countryTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{(data.user.countryCode | country) || '-'}}
        </span>
  </div>
</ng-template>


<ng-template #actionsTmpl let-data="rowData">
  <div class="table-actions-list">
    <app-action-dropdown *ngIf="actions" [values]="actions[data.media.id]" [placeholder]="'Select action'"></app-action-dropdown>
  </div>
</ng-template>

<ng-template #activeTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.media.deactivated ? 'Deactivated' : 'Active'}}
        </span>
  </div>
</ng-template>

<ng-template #confirm let-dialogRef="dialogRef">
  <ano-modal [dialogRef]="dialogRef" [title]="confirmTitle | localize" [isConfirmation]="true">
    {{confirmDescription | localize}}
  </ano-modal>
</ng-template>
