import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'lastDay'
})
export class LastDayPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    if (!value) {
      return '';
    }

    return moment(value).endOf('month').locale('DE').format('DD MMM YYYY');
  }

}
