import {Injectable} from '@angular/core';
import {
  ApiService,
  IFilterListItem, IListFilterItem,
  IMediaTimerange,
  IResponse,
  ITableFilters,
  ITimerange,
  IUnitKeyValue,
  LocalizationProvider,
} from 'ui-elements';
import {IPagedResponse, PagingParams, SortParam} from 'ui-elements';
import {Observable, of} from 'rxjs';
import {API_URLS_ADMIN, UrlGenerator} from '../../const/api.urls';
import {IInvoice, INVOICE_FILTERS} from '../../models/invoices.model';
import moment from 'moment';
import {map, pluck} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {
  private viewsFiltersNamespace = 'admin.invoice.access.';

  constructor(
    private apiService: ApiService,
    private localizationProvider: LocalizationProvider,
  ) { }

  public getInvoice(
    searchTerm: string = '',
    sort: SortParam[] = DEFAULT_SORT,
    paging: PagingParams = DEFAULT_PAGING,
    groupPurchaseFilters: IListFilterItem[],
    timeRange: ITimerange = { start: +moment().startOf('month').format('x'), end: +moment().endOf('month').format('x') }
  ): Observable<IPagedResponse<IInvoice>> {
    return this.apiService.post(API_URLS_ADMIN.GET_INVOICE, {
      searchTerm,
      sort,
      paging,
      groupPurchaseFilters,
      timeRange,
    });
  }

  public downloadInvoiceXLSX(
    timeRange: ITimerange = { start: +moment().startOf('month').format('x'), end: +moment().endOf('month').format('x') },
    itemNum: number
  ): Observable<any> {
    return this.apiService.get(UrlGenerator.generate(API_URLS_ADMIN.DOWNLOAD_INVOICE_XLSX, {
      timeRange: `${timeRange.start}-${timeRange.end}`,
      itemNum,
    }), {responseType: 'blob', observe: 'response'});
  }

  public downloadPdf(id: string): Observable<any> {
    return this.apiService.get(UrlGenerator.generate(API_URLS_ADMIN.DOWNLOAD_PURCHASE_PDF, {id}),
      {responseType: 'blob', observe: 'response'});
  }

  public sendPdf(id: string): Observable<IResponse> {
    return this.apiService.post(UrlGenerator.generate(API_URLS_ADMIN.SEND_PDF, {id}), {});
  }

  public getTimeRanges(): Observable<IMediaTimerange[]> {
    const firstDateTimeRange = {
      year: 2020,
      month: 8,
      timeRange: {
        start: 1601499600000,
        end: 1604181599999
      }
    };
    const currentDate = new Date();
    const diffYears = currentDate.getFullYear() - firstDateTimeRange.year;
    let ranges: IMediaTimerange[] = [];
    for (let i = 0; i <= diffYears; i++) {
      const year = firstDateTimeRange.year + i;
      if (year === firstDateTimeRange.year) {
        ranges = ranges.concat(this.fillMonths(firstDateTimeRange.month, 11, year));
        // ranges.push(this.getYearRange(year));
      }
      if (year === currentDate.getFullYear()) {
        ranges = ranges.concat(this.fillMonths(firstDateTimeRange.year === year  ? firstDateTimeRange.month : 0, currentDate.getMonth(), year));
        // ranges.push(this.getYearRange(year));
      }
      if (year !== firstDateTimeRange.year && year !== currentDate.getFullYear()) {
        ranges = ranges.concat(this.fillMonths(0, 11, year));
        // ranges.push(this.getYearRange(year));
      }
    }
    return of(ranges.reverse());
  }

  public fillMonths(from: number = 0, to: number = 11, year: number): IMediaTimerange[] {
    const res = [];
    for (let i = from; i <= to; i++) {
      const startDate = moment([year, i]);
      const endDate = moment(startDate).endOf('month');

      const timeRange: IMediaTimerange = {
        year: year,
        month: moment(new Date(year, i, 1)).locale('DE').format('MMMM'),
        timeRange: {
          start: startDate.toDate().getTime(),
          end: endDate.toDate().getTime()
        }
      };
      res.push(timeRange);
    }
    return res;
  }

  public getFilters(filterTypes: IInvoiceFilters): Observable<ITableFilters> {
    return this.apiService.post(API_URLS_ADMIN.INVOICE_FILTERS, filterTypes)
      .pipe(pluck('results', 'data'), map((res) => {
        const filterItems: IFilterListItem[] = [];
        Object.keys(res).forEach((filterOptionKey) => {
          const selectOptions = this.resolveFilterSelectOptions(res[filterOptionKey]);
          selectOptions.unshift({
            key: '',
            value: 'Alle Zahlungen'
          });
          filterItems.push({
            name: filterOptionKey,
            placeholder: 'Zugang',
            selectOptions,
          });
        });
        return {
          filterListItems: filterItems,
          filters: Object.keys(res) as INVOICE_FILTERS[]
        };
      }));
  }

  private resolveFilterSelectOptions(filtersOptionsList): IUnitKeyValue[] {
    return filtersOptionsList.map((filterOptionData) => ({
    key: filterOptionData,
      value: this.localizationProvider.getByKey(this.viewsFiltersNamespace + filterOptionData)
    }));
  }
}

const DEFAULT_PAGING = {
  page: 0,
  itemsOnPage: 10
} as PagingParams;

const DEFAULT_SORT = [{
  field: 'DATE',
  order: 'DESC'
}] as SortParam[];

export interface IInvoiceFilters {
  filterTypes: INVOICE_FILTERS[];
}
