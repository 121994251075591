import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {IDropdownConfig} from '../../models/types';
import {AuthService} from '../../services/auth/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {InvoicesService} from '../../services/invoices/invoices.service';
import {IInvoice, INVOICE_FILTERS} from '../../models/invoices.model';
import {takeUntil, tap} from 'rxjs/operators';
import {GenerateUtil} from '../../utils/generate.util';
import {saveAs} from 'file-saver/FileSaver';
import {
  AutoDestroyService, getFileNameFromResponseContentDisposition,
  IListFilterItem,
  ITableConfig,
  ITableFilters,
  ITimerange,
  ITimeRangeConfig, PagingParams, TableComponent
} from 'ui-elements';

@Component({
  selector: 'app-videos',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.sass'],
  providers: [AutoDestroyService]
})
export class InvoicesComponent implements OnInit {
  @ViewChild('confirm', { static: false }) confirm: TemplateRef<any>;
  @ViewChild('tableComponent', { static: false }) public table: TableComponent;
  @ViewChild('idTmpl', { static: true }) public idTmpl: TemplateRef<any>;
  @ViewChild('invoiceDateTmpl', { static: true }) public invoiceDateTmpl: TemplateRef<any>;
  @ViewChild('invoiceNumberTmpl', { static: true }) public invoiceNumberTmpl: TemplateRef<any>;
  @ViewChild('productTypeTmpl', { static: true }) public productTypeTmpl: TemplateRef<any>;
  @ViewChild('firstNameTmpl', { static: true }) public firstNameTmpl: TemplateRef<any>;
  @ViewChild('lastNameTmpl', { static: true }) public lastNameTmpl: TemplateRef<any>;
  @ViewChild('companyTmpl', { static: true }) public companyTmpl: TemplateRef<any>;
  @ViewChild('companyVatIdTmpl', { static: true }) public companyVatIdTmpl: TemplateRef<any>;
  @ViewChild('publisherCountryTmpl', { static: true }) public publisherCountryTmpl: TemplateRef<any>;
  @ViewChild('areaTypeTmpl', { static: true }) public areaTypeTmpl: TemplateRef<any>;
  @ViewChild('priceNetTmpl', { static: true }) public priceNetTmpl: TemplateRef<any>;
  @ViewChild('priceGrossTmpl', { static: true }) public priceGrossTmpl: TemplateRef<any>;
  @ViewChild('priceVatTmpl', { static: true }) public priceVatTmpl: TemplateRef<any>;
  @ViewChild('vatPercentTmpl', { static: true }) public vatPercentTmpl: TemplateRef<any>;
  @ViewChild('actionsTmpl', { static: true }) public actionsTmpl: TemplateRef<any>;
  @ViewChild('activeTmpl', { static: true }) public activeTmpl: TemplateRef<any>;
  public tableConfig: ITableConfig<IInvoice>;
  public searchTerm: string;
  public queryFilters: IListFilterItem[];
  public actions: IDropdownConfig;
  public actionLoaders: any;
  public loading: boolean;
  public isXlsxLoading = false;
  public confirmTitle: CONFIRM_TITLE;
  public confirmDescription: CONFIRM_DESCRIPTION;
  public timeRangeConfig: ITimeRangeConfig;
  public timeRange: ITimerange;
  public filters: ITableFilters;
  private itemNum: number;

  constructor(
    private invoicesService: InvoicesService,
    private destroy$: AutoDestroyService,
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.timeRangeConfig = {
      fetchMethod: (filters) => this.invoicesService.getTimeRanges(),
      filters: null,
      withoutFilters: true
    };
    this.invoicesService.getFilters({filterTypes: [INVOICE_FILTERS.TYPE]})
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.filters = res;
        this.filters.filterListItems[0].isMultiselectInSingleDropdown = true;
      });
    this.tableConfig = {
      dataField: 'data',
      matPaginator: true,
      filtersWidth100: true,
      fullHeight: true,
      searchFn: (sortParams, pagingParams) => {
        return this.invoicesService.getInvoice(this.searchTerm, sortParams, pagingParams, this.queryFilters, this.timeRange)
          .pipe(tap((res) => {
            this.setPagingXLSX(res.results.data.paging);
            this.mapToActions(res.results.data.items);
          }));
      },
      columns: [
        {
          name: 'Actions',
          tmpl: this.actionsTmpl,
          class: 'dropdown-actions'
        },
        {
          name: 'Invoice Date',
          tmpl: this.invoiceDateTmpl,
          sortField: 'DATE',
          class: 'left',
        },
        {
          name: 'Invoice Number',
          tmpl: this.invoiceNumberTmpl,
          sortField: 'INVOICE_ID',
          class: 'left',
        },
        {
          name: 'Product Type',
          tmpl: this.productTypeTmpl,
          sortField: 'PURCHASE_TYPE',
          class: 'left',
        },
        {
          name: 'First Name',
          tmpl: this.firstNameTmpl,
          sortField: 'FIRST_NAME',
          class: 'left',
        },
        {
          name: 'Last Name',
          tmpl: this.lastNameTmpl,
          sortField: 'LAST_NAME',
          class: 'left',
        },
        {
          name: 'Company',
          tmpl: this.companyTmpl,
          // sortField: 'COMPANY',
          class: 'left',
        },
        {
          name: 'Company VAT ID',
          tmpl: this.companyVatIdTmpl,
          // sortField: 'COMPANY_VAT_ID',
          class: 'left',
        },
        {
          name: 'Publisher Country',
          tmpl: this.publisherCountryTmpl,
          sortField: 'PUBLISHERS_COUNTRY',
          class: 'left',
        },
        {
          name: 'Area Type',
          tmpl: this.areaTypeTmpl,
          // sortField: 'AREA_TYPE',
          class: 'left',
        },
        {
          name: 'Price Gross',
          tmpl: this.priceGrossTmpl,
          sortField: 'TOTAL_COST',
          class: 'left',
        },
        {
          name: 'Price Net',
          tmpl: this.priceNetTmpl,
          // sortField: 'PRICE_NET',
          class: 'left',
        },
        {
          name: 'Price VAT',
          tmpl: this.priceVatTmpl,
          sortField: 'PRICE_VAT',
          class: 'left',
        },
        {
          name: 'VAT Percent',
          tmpl: this.vatPercentTmpl,
          // sortField: 'VAT_PERCENT',
          class: 'left',
        },
      ],
    };
  }

  private setPagingXLSX(paging: PagingParams): void {
    this.itemNum = paging.items;
  }

  public onFilter(filters: IListFilterItem[]): void {
    this.queryFilters = filters.filter((filter) => filter.value);
    this.table.resetTableData();
    this.table.refreshData({});
  }

  public resolveConfirmText(deactivated: boolean): void {
    if (deactivated) {
      this.confirmTitle = CONFIRM_TITLE.ACTIVATE;
      this.confirmDescription = CONFIRM_DESCRIPTION.DEACTIVATE;
      return;
    }
    this.confirmTitle = CONFIRM_TITLE.DEACTIVATE;
    this.confirmDescription = CONFIRM_DESCRIPTION.DEACTIVATE;
  }

  public timeRangeFilter(event): void {
    this.timeRange = event;
    this.table.refreshData({});
  }

  public search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.table.resetTableData();
    this.table.refreshData({});
  }

  public downloadXLSX(): void {
    if (this.isXlsxLoading) {
      return;
    }
    this.isXlsxLoading = true;

    this.invoicesService.downloadInvoiceXLSX(this.timeRange, this.itemNum)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.isXlsxLoading = false;
        const fileURL = URL.createObjectURL(new Blob([res.body],
          {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
          ));
        window.open(fileURL);
      });
  }

  private mapToActions(data: any): void {
    this.actions = {};
    this.actionLoaders = {};
    data.forEach((view) => {
      view._purchaseId = view._purchaseId || GenerateUtil.uuidv4();
      this.actionLoaders[view?._purchaseId] = false;
      this.actions[view?._purchaseId] = [];
      if (view?.userId) {
        this.actions[view?._purchaseId].push(
          {
            key: 'Login to account',
            value: 'Login to account',
            callback: () => {
              this.authService.loginToAccount(view?.userId);
            }
          }
        );
      }

      if (!view.purchaseId) {
        return;
      }
      this.actions[view?._purchaseId] = this.actions[view?._purchaseId].concat([
        {
          key: 'Download',
          value: 'Download',
          callback: () => {
            this.actionLoaders[view?._purchaseId] = true;
            this.invoicesService.downloadPdf(view?.purchaseId)
              .pipe(takeUntil(this.destroy$))
              .subscribe((res) => {
                this.actionLoaders[view?._purchaseId] = false;
                saveAs(
                  res.body,
                  getFileNameFromResponseContentDisposition(res)
                );
              }, () => this.actionLoaders[view?.purchaseId] = false);
          }
        },
        {
          key: 'Zeige PDF',
          value: 'Zeige PDF',
          callback: () => {
            this.actionLoaders[view?._purchaseId] = true;
            this.invoicesService.downloadPdf(view?.purchaseId)
              .pipe(takeUntil(this.destroy$))
              .subscribe((res) => {
                this.actionLoaders[view?._purchaseId] = false;
                const fileURL = URL.createObjectURL(new Blob([res.body], {type: 'application/pdf'}));
                window.open(fileURL);
              }, () => this.actionLoaders[view?.purchaseId] = false);
          }
        }
      ]);

      if (view?.email) {
        this.actions[view._purchaseId] = this.actions[view._purchaseId].concat([
          {
            key: 'An Publisher PDF',
            value: 'An Publisher PDF',
            callback: () => {
              this.actionLoaders[view?._purchaseId] = true;
              this.invoicesService.sendPdf(view?.purchaseId)
                .pipe(takeUntil(this.destroy$))
                .subscribe((res) => {
                  this.actionLoaders[view?._purchaseId] = false;
                }, () => this.actionLoaders[view?._purchaseId] = false);
            }
          }
        ]);
      }
    });
  }
}

enum CONFIRM_TITLE {
  ACTIVATE = 'admin.vod.confirm.activate.title',
  DEACTIVATE = 'admin.vod.confirm.deactivate.title'
}

enum CONFIRM_DESCRIPTION {
  ACTIVATE = 'admin.vod.confirm.activate.description',
  DEACTIVATE = 'admin.vod.confirm.deactivate.description'
}
