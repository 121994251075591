import { Injectable } from '@angular/core';
import {
  ApiService,
  IFilterListItem,
  ITableFilters,
  IUnitKeyValue,
  LocalizationProvider,
  VIEWER_FILTERS
} from 'ui-elements';
import {Observable, of} from 'rxjs';
import {
  IListFilterItem, IMediaTimerange,
  IPagedResponse,
  IResponse, ITimerange,
  PagingParams,
  SortParam
} from 'ui-elements';
import {API_URLS_ADMIN, UrlGenerator} from '../../const/api.urls';
import moment from 'moment';
import {IPublisherFilters} from '../../models/publisher.model';
import {map, pluck} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ViewsService {
  private viewsFiltersNamespace = 'admin.views.access.';

  constructor(
    private apiService: ApiService,
    private localizationProvider: LocalizationProvider
  ) { }

  public getViews(
    searchTerm: string = '',
    sort: SortParam[] = DEFAULT_SORT,
    paging: PagingParams = DEFAULT_PAGING,
    groupFilters: IListFilterItem[],
    timeRange: ITimerange = { start: +moment().startOf('month').format('x'), end: +moment().endOf('month').format('x') }
  ): Observable<IPagedResponse<any>> {
    return this.apiService.post(API_URLS_ADMIN.GET_VIEWS, {
      searchTerm,
      sort,
      paging,
      groupFilters,
      timeRange
    });
  }

  public downloadPdf(id: string): Observable<any> {
    return this.apiService.get(UrlGenerator.generate(API_URLS_ADMIN.DOWNLOAD_PURCHASE_PDF, {id}),
      {responseType: 'blob', observe: 'response'});
  }

  public sendPdf(id: string): Observable<IResponse> {
    return this.apiService.post(UrlGenerator.generate(API_URLS_ADMIN.SEND_PDF, {id}), {});
  }

  public getTimeRanges(): Observable<IMediaTimerange[]> {
    const firstDateTimeRange = {
      year: 2020,
      month: 8,
      timeRange: {
        start: 1601499600000,
        end: 1604181599999
      }
    };
    const currentDate = new Date();
    const diffYears = currentDate.getFullYear() - firstDateTimeRange.year;
    let ranges: IMediaTimerange[] = [];
    for (let i = 0; i <= diffYears; i++) {
      const year = firstDateTimeRange.year + i;
      if (year === firstDateTimeRange.year) {
        ranges = ranges.concat(this.fillMonths(firstDateTimeRange.month, 11, year));
        // ranges.push(this.getYearRange(year));
      }
      if (year === currentDate.getFullYear()) {
        ranges = ranges.concat(this.fillMonths(firstDateTimeRange.year === year  ? firstDateTimeRange.month : 0, currentDate.getMonth(), year));
        // ranges.push(this.getYearRange(year));
      }
      if (year !== firstDateTimeRange.year && year !== currentDate.getFullYear()) {
        ranges = ranges.concat(this.fillMonths(0, 11, year));
        // ranges.push(this.getYearRange(year));
      }
    }
    return of(ranges.reverse());
  }

  public getYearRange(year: number): IMediaTimerange {
    const yearStartDate = moment([year]);
    const yearEndDate = moment(yearStartDate).endOf('year');
    return {
      year: year,
      timeRange: {
        start: yearStartDate.toDate().getTime(),
        end: yearEndDate.toDate().getTime()
      }
    }
  }

  public fillMonths(from: number = 0, to: number = 11, year: number): IMediaTimerange[] {
    const res = [];
    for (let i = from; i <= to; i++) {
      const startDate = moment([year, i]);
      const endDate = moment(startDate).endOf('month');

      const timeRange: IMediaTimerange = {
        year: year,
        month: moment(new Date(year, i, 1)).locale('DE').format('MMMM'),
        timeRange: {
          start: startDate.toDate().getTime(),
          end: endDate.toDate().getTime()
        }
      };
      res.push(timeRange);
    }
    return res;
  }

  public getFilters(filterTypes: IViewerFilters): Observable<ITableFilters> {
    return this.apiService.post(API_URLS_ADMIN.VIEWS_FILTERS, filterTypes)
      .pipe(pluck('results', 'data'), map((res) => {
        const filterItems: IFilterListItem[] = [];
        Object.keys(res).forEach((filterOptionKey) => {
          const selectOptions = this.resolveFilterSelectOptions(res[filterOptionKey]);
          selectOptions.unshift({
            key: '',
            value: 'Alle Zahlungen'
          });
          filterItems.push({
            name: filterOptionKey,
            placeholder: 'Zugang',
            selectOptions,
          });
        });
        return {
          filterListItems: filterItems,
          filters: Object.keys(res) as VIEWER_FILTERS[]
        };
      }));
  }

  private resolveFilterSelectOptions(filtersOptionsList): IUnitKeyValue[] {
    return filtersOptionsList.map((filterOptionData) => ({
      key: filterOptionData,
      value: this.localizationProvider.getByKey(this.viewsFiltersNamespace + filterOptionData)
    }));
  }
}

const DEFAULT_PAGING = {
  page: 0,
  itemsOnPage: 20
} as PagingParams;

const DEFAULT_SORT = [{
  field: 'VIEW_DATE',
  order: 'DESC'
}] as SortParam[];

export interface IViewerFilters {
  filterTypes: VIEWER_FILTERS[];
}
