<nav class="navbar navbar-admin navbar-expand-lg navbar-dark bg-primary-dark">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <ng-container *ngIf="!(authService.isAuthorized$ | async)">
    <!--<h3 class="admin-title">Streamdust Admin</h3>-->
    <img class="logo" src="/assets/img/logo-inverse.png" alt="logo"/>
  </ng-container>
  <div class="collapse navbar-collapse" id="navbarNav">
    <ng-container *ngIf="authService.isAuthorized$ | async">
      <ul class="navbar-nav navbar-between">
        <li class="nav-item" routerLinkActive="active" *ngFor="let navItem of config">
          <a class="nav-link" [routerLink]="navItem.link"><i class="icon icon-{{ navItem.icon }}"></i> {{navItem.title}}</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Admin
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" (click)="logout()">Logout</a>
          </div>
        </li>
      </ul>
    </ng-container>
  </div>
</nav>
