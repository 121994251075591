import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject, fromEvent, Observable} from 'rxjs';
import {pluck, take, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {IResponse} from 'ui-elements';
import {API_URLS_ADMIN, UrlGenerator} from '../../const/api.urls';
import {ApiService} from 'ui-elements';
import {getQueryParamsFromUrl} from '../../utils/query-params.util';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public static keepLoggedProperty = 'keepLogged';
    public static expirationDateProperty = 'expirationDate';
    public authToken: string;
    public openToken: string;
    public isAuthorized: boolean;
    public isUserRegistered: boolean;
    private isAuthorizedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isAuthorized$ = this.isAuthorizedSubject.asObservable();
    public credentialsEmitter = new EventEmitter();
    public tokenEmitter = new EventEmitter();
    public logoutEmitter = new EventEmitter();
    public registrationComplete$: EventEmitter<boolean> = new EventEmitter<boolean>();
    public tempToken: string;

    constructor(
        private apiService: ApiService,
        private router: Router
    ) {
        this.handleAutoLoginTokenUrlParam();
        this.storeToken(AuthService.getTokenFromStorage());
    }

    private static getTokenFromStorage(): string {
        return localStorage.getItem('authToken');
    }

    private static setTokenToStorage(token: string): void {
        localStorage.setItem('authToken', token);
    }

    public static getOpenTokenFromStorage(): string {
        return localStorage.getItem('openToken') || '';
    }

    public static setOpenTokenToStorage(token: string): void {
        localStorage.setItem('openToken', token);
    }

    private getMediaAccessTokens(): string {
        return JSON.parse(localStorage.getItem('viewerAccessToken'));
    }

    public getMediaAccessToken(mediaId: string): string {
        if (this.authToken) {
            return this.authToken;
        }

        return this.getMediaAccessTokens();
    }

    public getAuthToken(): string {
        return this.authToken;
    }

    public login(credentials: ICredentials): Observable<IResponse<IAuthorizationResult>> {
        return this.apiService.post<IResponse<IAuthorizationResult>>(API_URLS_ADMIN.LOGIN, credentials)
            .pipe(
                tap((res) => {
                    this.saveCredentialsOnDevice(credentials);
                    this.handleAuthorizationResult(res);
                })
            );
    }

    private saveCredentialsOnDevice(credentials: ICredentials): void {
        this.credentialsEmitter.emit(credentials);
    }

    public loginFromDevice(authToken: string): void {
        this.storeToken(authToken);
        ApiService.setLanguageToStorage(null);
    }

    public logout(sendRequest: boolean = true): void {
        if (!sendRequest) {
            this.clearAuthInfo();
            return;
        }
        // TODO uncomment when endpoint will work
        this.apiService.post(API_URLS_ADMIN.LOGOUT, {}).subscribe(() => {
            this.clearAuthInfo();
        });
    }

    private clearAuthInfo(): void {
        this.logoutEmitter.emit(true);
        this.clearAuthorizationData();
        localStorage.removeItem(AuthService.expirationDateProperty);
        this.router.navigate(['login']);
    }

    public storeToken(token: string): void {
        this.authToken = token || '';
        this.isAuthorized = !!this.authToken;
        this.isAuthorizedSubject.next(this.isAuthorized);
        AuthService.setTokenToStorage(this.authToken);
    }

    public storeTokenForCall(token: string): void {
        this.authToken = token || '';
        AuthService.setTokenToStorage(this.authToken);
    }

    public handleAuthorizationResult({results: {authToken, language, isUserRegistred}}: IResponse<IAuthorizationResult>): void {
        this.tokenEmitter.emit(authToken);
        this.storeToken(authToken);
        ApiService.setLanguageToStorage(language);
    }

    private clearAuthorizationData(): void {
        localStorage.removeItem('themeColor');
        localStorage.removeItem('isLoginAs');
        this.storeToken('');
        this.isAuthorizedSubject.next(false);
    }

    private handleAutoLoginTokenUrlParam(): void {
        fromEvent(window, 'load')
            .subscribe(() => {
                const {'auto-login-token': authToken} = getQueryParamsFromUrl(location.href);

                if (!authToken) {
                    return;
                }

                AuthService.setTokenToStorage(authToken);
                this.storeToken(authToken);
            });
    }

    public storeKeepLogged(keepLogged: boolean): void {
        localStorage.setItem(AuthService.keepLoggedProperty, keepLogged ? KEEP_LOGGED_STATE.KEEP_LOGGED : KEEP_LOGGED_STATE.NOT_KEEP_LOGGED);
    }

    public getKeepLogged(): boolean {
        let res = false;
        const keepLogged = localStorage.getItem(AuthService.keepLoggedProperty);
        if (!keepLogged) {
            return true;
        }
        if (keepLogged === KEEP_LOGGED_STATE.KEEP_LOGGED) {
            res = true;
        }
        return res;
    }

    public setExpirationDate(): void {
        const keepLogged = localStorage.getItem(AuthService.keepLoggedProperty);
        if (!keepLogged) {
            return;
        }

        if (keepLogged === KEEP_LOGGED_STATE.NOT_KEEP_LOGGED) {
            const expirationTime = new Date().getTime() + (30 * 60 * 1000);
            localStorage.setItem(AuthService.expirationDateProperty, expirationTime.toString());
        }
    }

    public isSessionExpired(): boolean {
        let res = false;
        const keepLogged = localStorage.getItem(AuthService.keepLoggedProperty);
        if (!keepLogged || keepLogged === KEEP_LOGGED_STATE.KEEP_LOGGED) {
            return res;
        }

        const expirationTime = localStorage.getItem(AuthService.expirationDateProperty);
        if (!expirationTime) {
            return res;
        }

        if (+expirationTime < new Date().getTime()) {
            res = true;
        }
        return res;
    }

    public getLoginAsToken(accountId: string): Observable<string> {
      return this.apiService.post(UrlGenerator.generate(API_URLS_ADMIN.LOGIN_AS_TOKEN, {accountId}), {})
        .pipe(pluck('results', 'token'));
    }

    public loginToAccount(accountId: string, target: string = 'dashboard'): void {
      this.getLoginAsToken(accountId).pipe(take(1)).subscribe((token) => {
        const resTarget = target ? '&target=' + target : '';
        window.open(`${environment.appHost}/?auto-login-token=${token}${resTarget}`);
      });
    }
}

export interface ICredentials {
    email: string;
    password: string;
}

export interface IViewerCredentials {
    email?: string;
    password?: string;
    passwordConfirmation?: string;
    accessToken?: string;
    firstName?: string;
    lastName?: string;
}

export interface IFinishRegistrationModel {
    firstName: string;
    lastName: string;
    gender: string;
}

export interface IAuthorizationResult {
    authToken: string;
    language: string;
    isUserRegistred: boolean;
    email?: string;
    theme?: string;
}

export interface IPassword {
    password: string;
}

export enum KEEP_LOGGED_STATE {
    KEEP_LOGGED = 'keep',
    NOT_KEEP_LOGGED = 'notKeep'
}

export type TRegistrationResponse = IResponse<{ isUserRegistred: boolean }>;
