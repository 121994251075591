import { Injectable } from '@angular/core';
import {ApiService, IListFilterItem, IPagedResponse, IResponse, PagingParams, SortParam} from 'ui-elements';
import {Observable} from 'rxjs';
import {IPublisher} from '../../models/publisher.model';
import {PublisherService} from '../publisher/publisher.service';
import {ViewerService} from '../viewer/viewer.service';
import {API_URLS_ADMIN, UrlGenerator} from '../../const/api.urls';
import {pluck} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private publisherService: PublisherService,
    private viewerService: ViewerService,
    private apiService: ApiService
              ) { }

  public getUsersByType(
    userType: ACCOUNT_PAGE_TYPE,
    searchTerm: string,
    sort: SortParam[],
    paging: PagingParams,
    filters: IListFilterItem[]
  ): Observable<IPagedResponse<IPublisher>> {
    switch (userType) {
      case ACCOUNT_PAGE_TYPE.PUBLISHER:
      case ACCOUNT_PAGE_TYPE.SUBSCRIPTIONS:
      case ACCOUNT_PAGE_TYPE.VIEWS:
        return this.publisherService.getPublishers(searchTerm, sort, paging, filters);
      case ACCOUNT_PAGE_TYPE.VIEWER:
        return this.viewerService.getViewers(searchTerm, sort, paging);
    }
  }

  public toggleUserState(userType: ACCOUNT_PAGE_TYPE, id: string, state: boolean): Observable<IResponse> {
    switch (userType) {
      case ACCOUNT_PAGE_TYPE.PUBLISHER:
      case ACCOUNT_PAGE_TYPE.SUBSCRIPTIONS:
      case ACCOUNT_PAGE_TYPE.VIEWS:
        return this.publisherService.togglePublisherState(id, state);
      case ACCOUNT_PAGE_TYPE.VIEWER:
        return this.viewerService.toggleViewerState(id, state);
    }
  }

  public getUser(userType: ACCOUNT_PAGE_TYPE, id: string): Observable<any> {
    switch (userType) {
      case ACCOUNT_PAGE_TYPE.PUBLISHER:
      case ACCOUNT_PAGE_TYPE.SUBSCRIPTIONS:
      case ACCOUNT_PAGE_TYPE.VIEWS:
        return this.publisherService.getPublisher(id);
      case ACCOUNT_PAGE_TYPE.VIEWER:
        return this.viewerService.getViewer(id);
    }
  }

  public changePassword(email: string, password: string): Observable<any> {
    return this.apiService.post(API_URLS_ADMIN.CHANGE_PASSWORD, {email, password})
      .pipe(pluck('results', 'data'));
  }
}

export enum ACCOUNT_PAGE_TYPE {
  PUBLISHER = 'publisher',
  VIEWER = 'viewer',
  SUBSCRIPTIONS = 'subscriptions',
  VIEWS = 'views'
}
