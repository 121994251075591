<div class="panel configs-panel"
     *ngIf="[ACCOUNT_PAGE_TYPE.SUBSCRIPTIONS, ACCOUNT_PAGE_TYPE.PUBLISHER].includes(userType)">
  <div class="panel-body bg-white padding-top-20 padding-bottom-20">
    <form [formGroup]="bandwidthLimitForm" class="flex align-items-baseline bandwidth-limit-form">
      <h4><b>Current subscriptions</b></h4>
      <ano-button *ngIf="subscriptionsTariffs && !subscriptionData.length"
        (click)="openModal()"
        class="col-auto"
        [config]="{
            type: 'button',
            classList: 'cursor-pointer',
            sizeType: 'sm',
            styleType: 'primary'}">
        <i class="icon icon-plus-circle text-white cursor-pointer"></i> {{'subscription-manage.subscription.create' | localize}}
      </ano-button>
      <ano-button *ngIf="subscriptionsTariffs && subscriptionData.length && showChangeButton"
                  (click)="openModal()"
                  class="col-auto"
                  [config]="{
            type: 'button',
            classList: 'cursor-pointer',
            sizeType: 'sm',
            styleType: 'primary'}">
        {{'subscription-manage.subscription.update' | localize}}
      </ano-button>

      <ano-button *ngIf="bandwidthTariffs && !bandwidthData.length"
                  (click)="openBandwidthModal()"
                  class="col-auto"
                  [config]="{
            type: 'button',
            classList: 'cursor-pointer',
            sizeType: 'sm',
            styleType: 'primary'}">
        <i class="icon icon-plus-circle text-white cursor-pointer"></i> {{'subscription-manage.bandwidth.create' | localize}}
      </ano-button>
      <ano-button *ngIf="bandwidthTariffs && bandwidthData.length && showBandwidthChangeButton"
                  (click)="openBandwidthModal()"
                  class="col-auto"
                  [config]="{
            type: 'button',
            classList: 'cursor-pointer',
            sizeType: 'sm',
            styleType: 'primary'}">
        {{'subscription-manage.bandwidth.update' | localize}}
      </ano-button>

      <div class="col-auto">
        <div class="row row-padding-10">
          <ng-container>
            <div class="col-auto">
              <span class="text-active">{{'subscription-manage.bandwidth.limitOff' | localize}}</span>
            </div>
            <div class="col-auto">
              <mat-slide-toggle [formControlName]="'limitOff'" class="video-activation-toggle"></mat-slide-toggle>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="col-auto">
        <div class="row row-padding-10">
          <ng-container>
            <div class="col-auto">
              <span class="text-active">{{'subscription-manage.bandwidth.hideLimit' | localize}}</span>
            </div>
            <div class="col-auto">
              <mat-slide-toggle [formControlName]="'hiddenDataLimits'" class="video-activation-toggle"></mat-slide-toggle>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
    <app-current-subscription
      #subscriptionComponent
      [id]="accountId"
      [userType]="userType"
      (subscriptionEmitter)="setSubscriptionData($event)"
    ></app-current-subscription>
  </div>
</div>
<div class="panel configs-panel" *ngFor="let configItem of billsTableConfigs">
  <div class="panel-header pb-0" *ngIf="configItem.groupTitle">
    <h2><b>{{configItem.groupTitle | localize}}</b></h2>
  </div>
  <div class="panel-body bg-white padding-top-20 padding-bottom-20">
    <ano-bills-table [config]="configItem" [timeRangeConfig]="timeRangeConfig"></ano-bills-table>
  </div>
</div>

<ng-template #dateTmpl let-data="rowData">
  <div class="long-text">
    <span>
      {{data.date | dateFormat: 'DD MMM YYYY'}}
    </span>
  </div>
</ng-template>

<ng-template #amountTmpl let-data="rowData">
  <div class="long-text">
    <span>
      {{data.amount| currency}}
    </span>
  </div>
</ng-template>

<ng-template #dataSizeTmpl let-data="rowData">
  <div class="long-text">
    <span>
      {{data.dataSize?.value}} {{data.dataSize?.unit}}
    </span>
  </div>
</ng-template>

<ng-template #statusTmpl let-data="rowData">
  <div class="long-text">
    <span *ngIf="!data?.chargeBack">
      {{ data?.prolongationActive || !data.hasOwnProperty('prolongationActive') ? ((data.status === BILL_STATUS.PAID ? 'bills.status.paid' : 'bills.status.unpaid') | localize) : 'Canceled'}}
    </span>
    <span *ngIf="data?.chargeBack">
      Charged back
    </span>
  </div>
</ng-template>

<ng-template #dateRangeTmpl let-data="rowData">
  <div class="range-col flex flex-column" *ngIf="data.validity">
    <span>
      {{data.validity.startDate | dateFormat: 'DD MMM YYYY'}} - {{data.validity.endDate | dateFormat: 'DD MMM YYYY'}}
    </span>
  </div>
  <div class="range-col flex flex-column center" *ngIf="!data.validity">
    -
  </div>
</ng-template>

<ng-template #actionsTmpl let-data="rowData">
  <span (click)="goToBillDetails(data)"><i class="icon icon-login text-primary cursor-pointer"></i></span>
</ng-template>

<ng-template #mediaIdTmpl let-data="rowData">
  <div class="long-text">
        <span>
            {{data.mediaId | stringCut}}
        </span>
  </div>
</ng-template>

<ng-template #actionDownloadTmpl let-data="rowData">
  <span (click)="goToMultibitrateDetails(data.purchaseId)"><i class="icon icon-login text-primary cursor-pointer"></i></span>
</ng-template>

<ng-template #subscriptionTmpl let-dialogRef="dialogRef">
  <ano-modal [dialogRef]="dialogRef"
             [form]="subscriptionForm"
             [title]="'Add Subscription'"
             [isConfirmation]="false"
             (validate)="validateSubscription()"
  >
    <form class="row row-padding-10" [formGroup]="subscriptionForm">
      <div class="col-lg-6">
        <h4><b>{{'subscription-manage.table.tariff' | localize}}</b></h4>
        <ano-input [formControlName]="'tariffId'"
                   [isEditMode]="true"
                   [noBorder]="false"
                   [invalid]="subscriptionForm.get('tariffId').invalid"
                   [isTouched]="subscriptionForm.get('tariffId').touched"
                   [errors]="subscriptionForm.get('tariffId').errors"
                   [config]="{
            inputType: INPUT_TYPES.SELECT,
            placeholder: 'subscription-manage.table.tariff' | localize,
            selectOptions: subscriptionsTariffs,
            isAutocomplete: false,
            isRequired: true
       }"
        ></ano-input>
      </div>
      <div class="col-lg-6">
        <h4><b>{{'subscription-manage.table.period' | localize}}</b></h4>
        <ano-input [formControlName]="'period'"
                   [isEditMode]="true"
                   [noBorder]="false"
                   [invalid]="subscriptionForm.get('period').invalid"
                   [isTouched]="subscriptionForm.get('period').touched"
                   [errors]="subscriptionForm.get('period').errors"
                   [config]="{
            inputType: INPUT_TYPES.SELECT,
            placeholder: 'subscription-manage.table.period' | localize,
            selectOptions: subscriptionsPeriods,
            isAutocomplete: false,
            isRequired: true
       }"
        ></ano-input>
      </div>
    </form>
  </ano-modal>
</ng-template>

<ng-template #bandwidthSubscriptionTmpl let-dialogRef="dialogRef">
  <ano-modal [dialogRef]="dialogRef"
             [form]="subscriptionForm"
             [title]="'Add Subscription'"
             [isConfirmation]="false"
             (validate)="validateSubscription()"
  >
    <form class="row row-padding-10" [formGroup]="subscriptionForm">
      <div class="col-lg-6">
        <h4><b>{{'subscription-manage.table.tariff' | localize}}</b></h4>
        <ano-input [formControlName]="'tariffId'"
                   [isEditMode]="true"
                   [noBorder]="false"
                   [invalid]="subscriptionForm.get('tariffId').invalid"
                   [isTouched]="subscriptionForm.get('tariffId').touched"
                   [errors]="subscriptionForm.get('tariffId').errors"
                   [config]="{
            inputType: INPUT_TYPES.SELECT,
            placeholder: 'subscription-manage.table.tariff' | localize,
            selectOptions: bandwidthTariffs,
            isAutocomplete: false,
            isRequired: true
       }"
        ></ano-input>
      </div>
      <div class="col-lg-6">
        <h4><b>{{'subscription-manage.table.period' | localize}}</b></h4>
        <ano-input [formControlName]="'period'"
                   [isEditMode]="true"
                   [noBorder]="false"
                   [invalid]="subscriptionForm.get('period').invalid"
                   [isTouched]="subscriptionForm.get('period').touched"
                   [errors]="subscriptionForm.get('period').errors"
                   [config]="{
            inputType: INPUT_TYPES.SELECT,
            placeholder: 'subscription-manage.table.period' | localize,
            selectOptions: subscriptionsPeriods,
            isAutocomplete: false,
            isRequired: true
       }"
        ></ano-input>
      </div>
    </form>
  </ano-modal>
</ng-template>
