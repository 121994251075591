import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ACCOUNT_PAGE_TYPE} from 'src/app/services/user/user.service';
import {
  AutoDestroyService,
  BILL_STATUS,
  getFileNameFromResponseContentDisposition,
  IBill,
  IBillsTableConfig,
  INPUT_TYPES,
  ITableColumn,
  ITimeRangeConfig,
  LocalizationProvider,
  TEMPLATE_ID
} from 'ui-elements';
import {Router} from '@angular/router';
import {take, takeUntil} from 'rxjs/operators';
import {PublisherService} from '../../services/publisher/publisher.service';
import {IBillConfig} from '../../models/types';
import {saveAs} from 'file-saver/FileSaver';
import {MatDialog} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubscriptionManageService} from '../../services/subscription-manage/subscription-manage.service';
import {CurrentSubscriptionComponent} from './current-subscription/current-subscription.component';
import {SUBSCRIPTION_TYPE} from '../../models/publisher.model';

@Component({
  selector: 'app-user-billing',
  templateUrl: './user-billing.component.html',
  styleUrls: ['./user-billing.component.sass'],
  providers: [AutoDestroyService]
})
export class UserBillingComponent implements OnInit {
  public BILL_STATUS = BILL_STATUS;
  ACCOUNT_PAGE_TYPE = ACCOUNT_PAGE_TYPE;

  @ViewChild('subscriptionTmpl', {static: false}) subscriptionTmpl: TemplateRef<any>;
  @ViewChild('bandwidthSubscriptionTmpl', {static: false}) bandwidthSubscriptionTmpl: TemplateRef<any>;
  @ViewChild('dateTmpl', {static: true}) public dateTmpl: TemplateRef<any>;
  @ViewChild('amountTmpl', {static: true}) public amountTmpl: TemplateRef<any>;
  @ViewChild('dataSizeTmpl', {static: true}) public dataSizeTmpl: TemplateRef<any>;
  @ViewChild('dateRangeTmpl', {static: true}) public dateRangeTmpl: TemplateRef<any>;
  @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
  @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
  @ViewChild('mediaIdTmpl', {static: true}) public mediaIdTmpl: TemplateRef<any>;
  @ViewChild('actionDownloadTmpl', {static: true}) public actionDownloadTmpl: TemplateRef<any>;
  @ViewChild('subscriptionComponent', {static: false}) public subscriptionComponent: CurrentSubscriptionComponent;
  public billsTableConfigs: IBillsTableConfig<IBill>[];
  public timeRangeConfig: ITimeRangeConfig;
  @Input() public accountId: string;
  @Input() public userType: ACCOUNT_PAGE_TYPE;
  @Input() public config: IBillConfig;
  @Input() public limitOff = false;
  @Input() public hideBandwidthLimit = false;
  isPdfLoading = false;
  private pdfDownloadCurrentId: number;
  public subscriptionForm: FormGroup = this.fb.group({
    period: ['', [Validators.required]],
    tariffId: ['', [Validators.required]]});
  INPUT_TYPES = INPUT_TYPES;
  public subscriptionsPeriods: [];
  public subscriptionsTariffs;
  public bandwidthTariffs;
  public subscriptionData;
  public bandwidthData;
  public bandwidthLimitForm: FormGroup;

  constructor(private router: Router,
              private publisherService: PublisherService,
              private subscriptionManageService: SubscriptionManageService,
              private localizationProvider: LocalizationProvider,
              private destroy$: AutoDestroyService,
              public dialog: MatDialog,
              private fb: FormBuilder
  ) { }

  get showChangeButton(): boolean {
    return this.subscriptionData.find((subscription) => {
      return subscription.type === SUBSCRIPTION_TYPE.SUBSCRIPTION && subscription.manual;
    });
  }

  get showBandwidthChangeButton(): boolean {
    return this.bandwidthData.find((subscription) => {
      return subscription.type === SUBSCRIPTION_TYPE.BANDWIDTH && subscription.manual;
    });
  }

  ngOnInit(): void {
    this.timeRangeConfig = {
      fetchMethod: (filters) => this.config.getTimeRanges(),
      filters: null,
      withoutFilters: true
    };
    this.resolveBillsTableConfigs();
    this.getPeriods();
    this.getTariffs();

    this.bandwidthLimitForm = this.fb.group({
      limitOff: [this.limitOff],
      hiddenDataLimits: [this.hideBandwidthLimit],
    });
    this.listenBandwidthLimitChange();
  }

  private listenBandwidthLimitChange(): void {
    this.bandwidthLimitForm.get('limitOff').valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((limitOff) => {
        this.publisherService.togglePublisherBandwidthLimit(this.accountId, limitOff)
          .pipe(takeUntil(this.destroy$))
          .subscribe((response) => {
            if (!response?.success) {
              return;
            }
          });
      });

    this.bandwidthLimitForm.get('hiddenDataLimits').valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((hideLimit) => {
        this.publisherService.togglePublisherHideBandwidthLimit(this.accountId, hideLimit)
          .pipe(takeUntil(this.destroy$))
          .subscribe((response) => {
            if (!response?.success) {
              return;
            }
          });
      });
  }

  public goToBillDetails(bill: IBill): void {
    this.router.navigate([this.userType + '/' + this.accountId + '/details/' + bill.productId]);
  }

  public goToMultibitrateDetails( id: string): void {
    this.router.navigate([this.userType  + '/' + this.accountId + '/multibitrate/details/' + id]);
  }

  private resolveBillsTableConfigs(): void {
    this.config.getConfig(this.accountId, this.userType).pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.billsTableConfigs = res;
        this.addTemplatesToConfigs();
      });
  }

  public downloadPdf(bill: IBill): void {
    if (this.isPdfLoading) {
      return;
    }
    this.pdfDownloadCurrentId = bill.purchaseId;

    this.isPdfLoading = true;

    this.publisherService.downloadPdf(bill.purchaseId.toString())
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.isPdfLoading = false;
        saveAs(
          res.body,
          getFileNameFromResponseContentDisposition(res)
        );
      }, () => this.isPdfLoading = false);
  }

  private addTemplatesToConfigs(): void {
    this.billsTableConfigs.forEach((config: IBillsTableConfig<IBill>) => {
      config.tableConfig.columns.forEach((column: ITableColumn) => {
        if (column?.tmplId === TEMPLATE_ID.DATE) {
          column.tmpl = this.dateTmpl;
        }

        if (column?.tmplId === TEMPLATE_ID.PRICE) {
          column.tmpl = this.amountTmpl;
        }

        if (column?.tmplId === TEMPLATE_ID.VALUE_UNIT) {
          column.tmpl = this.dataSizeTmpl;
        }

        if (column?.tmplId === TEMPLATE_ID.DATE_RANGE) {
          column.tmpl = this.dateRangeTmpl;
        }

        if (column?.tmplId === TEMPLATE_ID.ACTIONS) {
          column.tmpl = this.actionsTmpl;
        }

        if (column?.tmplId === TEMPLATE_ID.STATUS) {
          column.tmpl = this.statusTmpl;
        }

        if (column?.tmplId === TEMPLATE_ID.MEDIA_ID) {
          column.tmpl = this.mediaIdTmpl;
        }
        if (column?.tmplId === TEMPLATE_ID.ACTION_DOWNLOAD) {
          column.tmpl = this.actionDownloadTmpl;
        }
      });
    });
  }

  public getPeriods(): void {
    this.subscriptionManageService.getSubscriptionsPeriods()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.subscriptionsPeriods = res.results.data.map(period => ({
          key: period,
          value: this.localize(this.subscriptionManageService.getSubscriptionPeriodTranslate(period)),
        }));
      });
  }

  public getTariffs(): void {
    this.subscriptionManageService.getSubscriptionsTariffs(this.accountId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.results.data) {
          this.subscriptionsTariffs = Object.entries(res.results.data).map(tariff => ({
            key: tariff[0],
            value: this.localize(this.subscriptionManageService.getSubscriptionPlanTypeTranslate(tariff[1].toString().toUpperCase())),
          }));
        }
      });

    this.subscriptionManageService.getBandwidthTariffs(this.accountId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.results.data) {
          this.bandwidthTariffs = Object.entries(res.results.data).map(tariff => ({
            key: tariff[0],
            value: this.localize(tariff[1].toString()),
          }));
        }
      });
  }

  localize(key: string): string {
    if (!key) {
      return '';
    }
    return this.localizationProvider.getByKey(key);
  }

  public setSubscriptionData(data: any): void {
    this.subscriptionData = data.filter((item) => item.type === SUBSCRIPTION_TYPE.SUBSCRIPTION);
    this.bandwidthData = data.filter((item) => item.type === SUBSCRIPTION_TYPE.BANDWIDTH);
  }

  public createSubscription(): void {
    const body = {
      accountId: this.accountId,
      subscriptionPeriod: this.subscriptionForm.value.period,
      subscriptionTariffId: this.subscriptionForm.value.tariffId,
      type: 'SUBSCRIPTION',
    };
    this.subscriptionManageService.postCreatedSubscription(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.subscriptionComponent.refreshSubscriptions();
        // this.subscriptionsTariffs = null;
      });
  }

  public updateSubscription(): void {
    const body = {
      accountId: this.accountId,
      subscriptionPeriod: this.subscriptionForm.value.period,
      subscriptionTariffId: this.subscriptionForm.value.tariffId,
      type: 'SUBSCRIPTION',
    };
    this.subscriptionManageService.postUpdateSubscription(this.subscriptionData[0].id, body)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.subscriptionComponent.refreshSubscriptions();
        // this.subscriptionsTariffs = null;
      });
  }

  public validateSubscription(): void {
    this.subscriptionForm.markAllAsTouched();
  }

  public createBandwidthSubscription(): void {
    const body = {
      accountId: this.accountId,
      subscriptionPeriod: this.subscriptionForm.value.period,
      subscriptionTariffId: this.subscriptionForm.value.tariffId,
      type: 'BANDWIDTH',
    };
    this.subscriptionManageService.postCreatedSubscription(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.subscriptionComponent.refreshSubscriptions();
        // this.subscriptionsTariffs = null;
      });
  }

  public updateBandwidthSubscription(): void {
    const body = {
      accountId: this.accountId,
      subscriptionPeriod: this.subscriptionForm.value.period,
      subscriptionTariffId: this.subscriptionForm.value.tariffId,
      type: 'BANDWIDTH',
    };
    this.subscriptionManageService.postUpdateSubscription(this.bandwidthData[0].id, body)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.subscriptionComponent.refreshSubscriptions();
        // this.subscriptionsTariffs = null;
      });
  }

  public openModal(): void {
    const dialogRef = this.dialog.open(this.subscriptionTmpl);
    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe((confirm: boolean) => {
        if (confirm) {
          if (!this.subscriptionData.length) {
            this.createSubscription();
          } else {
            this.updateSubscription();
          }
        }
      });
  }

  public openBandwidthModal(): void {
    const dialogRef = this.dialog.open(this.bandwidthSubscriptionTmpl);
    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe((confirm: boolean) => {
        if (confirm) {
          if (!this.bandwidthData.length) {
            this.createBandwidthSubscription();
          } else {
            this.updateBandwidthSubscription();
          }
        }
      });
  }

}
