import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import {SharedModule} from '../../modules/shared.module';
import { ChartAndSummaryComponent } from './components/chart-and-summary/chart-and-summary.component';

@NgModule({
  declarations: [DashboardComponent, ChartAndSummaryComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class DashboardModule { }
