import {Injectable, Input} from '@angular/core';
import {ApiService, IPagedResponse, ITimerange, PagingParams, SortParam} from 'ui-elements';
import {Observable} from 'rxjs';
import {API_URLS_ADMIN, UrlGenerator} from '../../const/api.urls';
import {ISubscriptionTariffPlan, TARIFF_PLAN_PERIOD, TARIFF_PLAN_TYPES} from '../../models/types';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionManageService {


    constructor(
        private apiService: ApiService
    ) {
    }

    public getCurrentSubscription(id: string, searchTerm = null, paging: PagingParams = DEFAULT_TABLE_PAGING, sort: SortParam[] = [], timeRange?: ITimerange): Observable<IPagedResponse<any>> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_ADMIN.PUBLISHER_CURRENT_SUBSCRIPTION, {id}), {
            paging,
            sort,
            searchTerm
        });
    }

  public getSubscriptionsPeriods(): Observable<any> {
      return this.apiService.get(API_URLS_ADMIN.SUBSCRIPTIONS_PERIODS);
  }

  public getSubscriptionsTariffs(id): Observable<any> {
    return this.apiService.get(UrlGenerator.generate(API_URLS_ADMIN.SUBSCRIPTIONS_TARIFFS, {id}));
  }

  public getBandwidthTariffs(id): Observable<any> {
    return this.apiService.get(UrlGenerator.generate(API_URLS_ADMIN.BANDWIDTH_TARIFFS, {id}));
  }

  public postCreatedSubscription(body): Observable<any> {
      return this.apiService.post(API_URLS_ADMIN.SUBSCRIPTION_CREATE, body);
  }

  public postUpdateSubscription(id, body): Observable<any> {
    return this.apiService.put(UrlGenerator.generate(API_URLS_ADMIN.SUBSCRIPTION_UPDATE, {id}), body);
  }

    public getSubscriptionPlanTypeTranslate(subscriptionType): string {
        switch (subscriptionType) {
            case SUBSCRIPTION_PLAN_TYPES.FREE: {
                return 'subscription-manage.table.subscription-plan-type.free';
            }
            case SUBSCRIPTION_PLAN_TYPES.BEGINNER: {
                return 'subscription-manage.table.subscription-plan-type.beginner';
            }
            case SUBSCRIPTION_PLAN_TYPES.ADVANCED: {
                return 'subscription-manage.table.subscription-plan-type.advanced';
            }
            case SUBSCRIPTION_PLAN_TYPES.PROFESSIONAL: {
                return 'subscription-manage.table.subscription-plan-type.professional';
            }
        }
    }

    public getSubscriptionTypeTranslate(subscriptionType: TARIFF_PLAN_TYPES): string {
        switch (subscriptionType) {
            case TARIFF_PLAN_TYPES.SUBSCRIPTION: {
                return 'subscription-manage.table.subscription-type.subscription';
            }
            case TARIFF_PLAN_TYPES.BANDWIDTH: {
                return 'subscription-manage.table.subscription-type.bandwidth';
            }
            case TARIFF_PLAN_TYPES.STORAGE_SPACE: {
                return 'subscription-manage.table.subscription-type.storage-space';
            }
        }
    }

    public getSubscriptionPeriodTranslate(subscriptionPeriod: TARIFF_PLAN_PERIOD): string {
        switch (subscriptionPeriod) {
            case TARIFF_PLAN_PERIOD.WEEK : {
                return 'subscription-manage.table.subscription-period.week';
            }
            case TARIFF_PLAN_PERIOD.TWO_WEEKS : {
              return 'subscription-manage.table.subscription-period.two-weeks';
            }
            case TARIFF_PLAN_PERIOD.MONTH : {
                return 'subscription-manage.table.subscription-type.month';
            }
            case TARIFF_PLAN_PERIOD.TWO_MONTH : {
              return 'subscription-manage.table.subscription-type.two-months';
            }
            case TARIFF_PLAN_PERIOD.YEAR : {
                return 'subscription-manage.table.subscription-type.year';
            }
        }
    }

}

export enum SUBSCRIPTION_PLAN_TYPES {
    FREE = 'FREE',
    BEGINNER = 'BEGINNER',
    ADVANCED = 'ADVANCED',
    PROFESSIONAL = 'PROFESSIONAL'
}

export enum PAYMENT_STATUS {
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    SUCCESS = 'SUCCESS'
}

export const DEFAULT_TABLE_PAGING = {
    page: 0,
    itemsOnPage: 10
} as PagingParams;

// export interface IPaymentResult {
//     externalCardId?: string;
//     message: string;
//     paymentStatus: PAYMENT_STATUS
//     redirectUrl: string;
// }
