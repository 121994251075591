<div class="row justify-content-between mb-20">
  <div class="col"></div>
  <div class="col-auto alternate-theme">
    <ano-datepicker [showAllOption]="false" (changeDate)="timeRangeFilter($event)"></ano-datepicker>
  </div>
</div>

<div class="row">
  <div *ngFor="let config of widgetsConfigs" class="col">
    <div class="widget-spinner-container">
      <ano-loader *ngIf="loading" [isOpen]="loading"></ano-loader>
    </div>
    <ano-widget [config]="config" class="dashboard-widget"></ano-widget>
  </div>
</div>
<div class="chart-container card">
  <ano-loader *ngIf="!data" [isOpen]="!data"></ano-loader>
  <app-chart-and-summary *ngIf="data"
                         #publishersChart
                         [title]="'Anzahl der Publisher'"
                         [data]="data.publishers"
                         [legend]="data.publisherLegend"
                         [summary]="data.publisherSummary"
                         [id]="1"
                         [colorScheme]="colorScheme"
                         [tableHeaderItems]="['', 'Anzahl']"
  ></app-chart-and-summary>
</div>

<div class="card bg-white">
  <div class="card-header bg-white">
    <h2 class="title-level-3">Verbauch Von Bandbreite Und Speicherplatz </h2>
  </div>
  <div class="card-body">
    <div class="row row-padding-10">
      <div class="col-lg-3">
        <ano-speedometer *ngIf="data" [data]="data.dataSpaceWidget.bandwidthTotal" [text]="{firstLine: 'Aktueller gekaufter Bandbreite aller Nutzer', secondLine: 'Prozentuale Verteilung'}" [topText]="'Aktueller Bandbreitennutzung aller Nutzer'"></ano-speedometer>
      </div>
      <div class="col-lg-3">
        <ano-speedometer *ngIf="data" [data]="data.dataSpaceWidget.bandwidthPerUser" [text]="{firstLine: 'Aktueller durchschnittlicher Nutzung der gekauften Bandbreite Nutzung', secondLine: 'Prozentuale Verteilung'}" [topText]="'Aktuelle durchschnittliche Bandbreitennutzung aller Nutzer'"></ano-speedometer>
      </div>
      <div class="col-lg-3">
        <ano-pie-chart-used *ngIf="data" [data]="data.dataSpaceWidget.storageSpaceTotal" [id]="'pie-chart-used-1'" [text]="{firstLine: 'Aktueller gekaufter Speicherplatz aller Nutzer', secondLine: 'Prozentuale Verteilung'}" [topText]="'Aktueller Speicherplatznutzung aller Nutzer'"></ano-pie-chart-used>
      </div>
      <div class="col-lg-3">
        <ano-pie-chart-used *ngIf="data" [data]="data.dataSpaceWidget.storageSpacePerUser" [id]="'pie-chart-used-2'" [text]="{firstLine: 'Aktueller durchschnittlicher Nutzung der gekauften Speicherplatz Nutzung', secondLine: 'Prozentuale Verteilung'}" [topText]="'Aktuelle durchschnittliche Speicherplatznutzung aller Nutzer'"></ano-pie-chart-used>
      </div>
    </div>
  </div>
</div>

<div class="chart-container card">
  <ano-loader *ngIf="!data" [isOpen]="!data"></ano-loader>
  <app-chart-and-summary *ngIf="data"
                         #viewersChart
                         [title]="'Anzahl der Zuschauer'"
                         [data]="data.viewAccess"
                         [legend]="data.viewAccessLegend"
                         [summary]="data.viewAccessSummary"
                         [id]="2"
                         [colorScheme]="viewerColorScheme"
                         [tableHeaderItems]="['', 'Anzahl']"
  ></app-chart-and-summary>
</div>
