import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AutoDestroyService, CURRENCY_SYMBOLS, cutString, DateHelper, getFileNameFromResponseContentDisposition} from 'ui-elements';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PayoutsService} from '../../../../services/payouts/payouts.service';
import {ACCOUNT_STATUS, IPublisher, ISubscription} from '../../../../models/publisher.model';
import {IFormFieldsConfig, INPUT_TYPES} from 'ui-elements';
import {saveAs} from 'file-saver/FileSaver';
import {takeUntil} from 'rxjs/operators';
import {PublisherService} from '../../../../services/publisher/publisher.service';
import {IPayout, IPayoutAggregation} from '../../../../models/payouts.model';
import {AuthService} from '../../../../services/auth/auth.service';

@Component({
  selector: 'app-payout-details',
  templateUrl: './payout-details.component.html',
  styleUrls: ['./payout-details.component.sass'],
  providers: [AutoDestroyService, PayoutsService]
})
export class PayoutDetailsComponent implements OnInit {
  ACCOUNT_STATUS = ACCOUNT_STATUS;
  private backUrl: string;
  public viewLoading: boolean;
  public viewDone: boolean;
  public download: boolean;
  public downloadDone: boolean;
  public sendLoading: boolean;
  public sendDone: boolean;
  private payoutId: string;
  public subscription: ISubscription;
  public subscriptionCanceling: boolean;
  public chargeBackLoading: boolean;
  public publisher: IPublisher;
  public form: FormGroup = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    accountId: ['', [Validators.required]],
    billNumber: ['', [Validators.required]],
    date: ['', [Validators.required]],
    amount: ['', [Validators.required]],
    sentDate: ['', [Validators.required]],
  });
  public activateForm = this.fb.group({
    active: [false]
  });

  public leftConfig: IFormFieldsConfig[] = [
    {
      name: 'firstName',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Name',
        placeholder: '',
      }
    },
    {
      name: 'lastName',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Vorname',
        placeholder: '',
      }
    },
    {
      name: 'accountId',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Kunden ID',
        placeholder: '',
      }
    },
    {
      name: 'billNumber',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Rechnungsnummer',
        placeholder: '',
      }
    },
    {
      name: 'date',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Datum',
        placeholder: '',
      }
    },
    {
      name: 'amount',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Summe',
        placeholder: '',
      }
    },
  ];

  public rightConfig: IFormFieldsConfig[] = [
    {
      name: 'sentDate',
      config: {
        inputType: INPUT_TYPES.INPUT,
        label: 'Gutschrift wurde versendet am',
      }
    }
  ];

  public payout: IPayoutAggregation;

  constructor(
    private activatedRoute: ActivatedRoute,
    private destroy$: AutoDestroyService,
    private fb: FormBuilder,
    private router: Router,
    private payoutsService: PayoutsService,
    private publisherService: PublisherService,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.payoutId = params.payoutId;
        this.payoutsService.getPayout(this.payoutId).pipe(takeUntil(this.destroy$))
          .subscribe((res) => {
            this.payout = res;
            this.form.get('accountId').setValue(cutString(res.publisherId, 0, 8));
            this.form.get('date').setValue(DateHelper.formatDate(res.payoutDate, 'DD MMM YYYY'));
            this.form.get('amount').setValue(CURRENCY_SYMBOLS[res.currency] + res.payoutLeft);
            this.form.get('billNumber').setValue(res.creditNotId);
            this.activateForm.get('active').setValue(res.payoutDone);
            this.publisherService.getPublisher(res.publisherId).pipe(takeUntil(this.destroy$))
              .subscribe((publisher) => {
                this.publisher = publisher;
                this.form.get('firstName').setValue(publisher.profile.firstName);
                this.form.get('lastName').setValue(publisher.profile.lastName);
              });

            this.activateForm.get('active').valueChanges.pipe(takeUntil(this.destroy$))
              .subscribe((doPayout: boolean) => {
                this.payoutsService.sendPayout(res.id, doPayout).subscribe(() => {
                  // this.payoutsService.getPayout(this.payoutId).pipe(takeUntil(this.destroy$))
                  //   .subscribe((payout) => {
                  //     this.payout = payout;
                  //     this.form.get('accountId').setValue(cutString(payout.publisherId, 0, 8));
                  //     this.form.get('date').setValue(DateHelper.formatDate(payout.payoutDate, 'DD MMM YYYY'));
                  //     this.form.get('amount').setValue(CURRENCY_SYMBOLS[payout.currency] + payout.payoutLeft);
                  //     this.form.get('billNumber').setValue(payout.creditNotId);
                  //     this.activateForm.get('active').setValue(payout.payoutDone);
                  //   });
                });
              });
          });
      });
  }

  public back(): void {
    this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe(({id}) => {
      this.router.navigate([`payouts/${id}`]);
    });
  }

  public downloadPdf(): void {
    if (this.download) {
      return;
    }
    this.download = true;

    this.payoutsService.downloadPayoutPDF(this.payout.id.toString())
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.download = false;
        this.downloadDone = true;
        setTimeout(() => this.downloadDone = false, 5000);
        saveAs(
          res.body,
          getFileNameFromResponseContentDisposition(res)
        );
      }, () => this.download = false);
  }

  public viewPdf(): void {
    this.viewLoading = true;
    this.payoutsService.downloadPayoutPDF(this.payout.id.toString())
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.viewLoading = false;
        this.viewDone = true;
        setTimeout(() => this.viewDone = false, 5000);
        const fileURL = URL.createObjectURL(new Blob([res.body], {type: 'application/pdf'}));
        window.open(fileURL);
      }, () => this.viewLoading = false);
  }

  public sendPdf(): void {
    this.sendLoading = true;
    this.payoutsService.sendPdf(this.payout.id.toString())
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.sendLoading = false;
        this.sendDone = true;
        setTimeout(() => this.sendDone = false, 5000);
      }, () => this.sendLoading = false);
  }

  public loginAs(): void {
    this.authService.loginToAccount(this.publisher.profile.ownerId);
  }
}
